import { makeStyles } from '@alpha/ui-lib/theme';

export const useStyles = makeStyles(() => ({
  loadingBackdropLoader: {
    display: 'block',
  },
  loadingMainContent: {
    display: 'none',
    minHeight: 'calc(100% - 55px)',
    flexGrow: 1,
  },
  header: {
    letterSpacing: -0.5,
    lineHeight: '26px',
    marginBottom: '112px',
  },
  buttonProgress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    cursor: 'pointer'
  },
  hideSwapBtn: {
    color: 'transparent',
    backgroundColor: 'transparent',
    '&:disabled': {
      color: 'transparent',
    },
  },
  buttonSuccess: {
    backgroundColor: '#1E8777',
    '&:hover': {
      backgroundColor: '#1E8777',
    },
  },
  alert: {
    padding: '20px',
    color: '#3E8CC1',
    '& .text': {
      color: '#3E8CC1 !important',
      fontWeight: 400,
      lineHeight: '16.34px !important',
    },
    '& .subText': {
      color: '#3E8CC1 !important',
    },
    marginBottom: 'unset',
  },
  warningAlert: {
    padding: '20px',
    color: '#CF752F',
    '& .text': {
      color: '#CF752F !important',
      fontWeight: 400,
    },
    '& .subText': {
      color: '#CF752F !important',
    },
    marginBottom: 'unset',
  },
  form: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
  },
  minHeight: {
    minHeight: 'calc(100% - 55px)',
    flexGrow: 1,
  },
  verticalDivider: {
    margin: '21px 0px',
    backgroundColor: '#EAEAEA80',
  },
  checkbox: {
    display: 'flex',
    padding: '12px 0px 0px 0px',
    flexDirection: 'column',
    '& .MuiTypography-root': {
      marginTop: '18px',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '0px',
      '& .PrivateSwitchBase-root': {
        padding: '3px 9px 0px 0px',
      },
    },
  },
  settledPhysicallyCheckbox: {
    display: 'flex',
    padding: '12px 0px 0px 0px',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px',
      display: 'unset',
      '& .PrivateSwitchBase-root': {
        padding: '3px 9px 0px 0px',
      },
    },
  },
  card: {
    border: '1px solid #eee',
    borderRadius: '10px',
  },
  radioButtons: {

  },
  // Amount and balance
  root: {
    '& .MuiFormLabel-root, .MuiTypography-subtitle2': {
      fontSize: '14px',
      color: '#333333',
      marginBottom: '8px',
    },
    '& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': { '-moz-appearance': 'textfield' },
    padding: '20px 30px',
  },
  selectAccount: {
    marginBottom: '40px',
  },
  dropdown: {
    borderRadius: '4px',
    background: '#F7F7F7',
    opacity: '100%',
    width: '258px',
    height: '46px',
    color: '#343434',
    fontSize: '14px',
    '& .MuiSelect-select.MuiSelect-select': {
      background: '#F7F7F7',
      padding: '12px 20px 15px 20px',
    },
  },
  placeholder: {
    color: '#34343473',
    fontWeight: 400,
  },
  forwardReasoningDropdown: {
    borderRadius: '4px',
    background: '#F7F7F7',
    opacity: '100%',
    height: '46px',
    color: '#343434',
    fontSize: '14px',
    '& .MuiSelect-select.MuiSelect-select': {
      background: '#F7F7F7',
      padding: '12px 20px 15px 20px',
    },
    '& .MuiSvgIcon-root': {
      right: '7px',
    },
  },
  inputAmount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputLabel: {
    padding: '4px 30px',
    '& label': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
  rateContainer: {
    marginTop: '20px',
    marginBottom: '40px',
    width: '100%',
    '& > div': {
      transform: 'translateY(-67%)',
      marginLeft: '30px',
    },
  },
  swapButton: {
    padding: '14px',
    minHeight: '22px',
    background: 'rgb(30, 135, 119)',
    color: '#FFFFFF',
    borderRadius: '10px',
    '&:hover': {
      background: '#c4c4c4',
    },
    '&:disabled': {
      background: '#e7e7e7',
      color: 'white',
    },
  },
  insufficientFundsTable: {
    '& tr:last-child td:last-child': {
      color: '#b95656 !important',
    },
  },
  sufficientFundsTable: {
    '&  td:last-child': {
      fontSize: '13px',
      fontWeight: 300,
    },
  },
  willDebit: {
    width: '100%',
    '& .MuiInputBase-input': {
      width: '80%',
      maxWidth: '250px',
    },
    '& .MuiInput-root': {
      fontSize: '40px',
      fontWeight: 300,
      color: '#343434',
    },
  },
  liveRateLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageHolder: {
    display: 'flex',
    marginLeft: '5px',
    marginBottom: '5px',
  },
  balanceAfter: {
    marginTop: '20px',
    width: '200px',
    maxWidth: '250px',
    overflow: 'hidden',
    '& span': {
      marginLeft: '4px',
    },
    '& .MuiTypography-subtitle2': {
      textAlign: 'left',
    },
  },
  fixedIndicatorActive: {
    backgroundColor: 'rgba(8, 17, 31, 0.53)',
    color: '#FFFFFF',
  },
  fixedIndicatorInactive: {
    backgroundColor: 'rgba(8, 17, 31, 0.106)',
    color: '#FFFFFF',
  },
  fixedIndicatorHidden: {
    display: 'none',
    backgroundColor: 'rgba(8, 17, 31, 0.106)',
    color: '#FFFFFF',
  },
  amountInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& div > input.MuiInputBase-input': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  valueDateContainer: {
    padding: '10px 30px',
    margin: '0px 0 0px 0',
    '& .datePicker': {
      marginTop: '10px',
    },
  },
  valueDateTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    marginBottom: '8px',
    lineHeight: '24px',
  },
  accountInfoContainer: {
    borderRadius: '10px',
    padding: '20px',
    background: '#f7f7f7',
    marginTop: '26px',
  },
  progressBarNoAnimation: {
    transition: 'none',
  },
  input: {
    '&::placeholder': {
      color: 'red',
    },
  },
  black: {
    color: '#343434 !important',
  },
  grey: {
    color: 'rgb(170, 170, 170) !important',
  },
  red: {
    color: '#b95656 !important',
    fontSize: '22px',
    fontWeight: 600,
    textAlign: 'right',
    '& .MuiInputBase-input.Mui-disabled': {
      textAlign: 'right',
    },
  },
  green: {
    color: '#1E8777 !important',
    fontSize: '22px',
    fontWeight: 600,
    '& .MuiInputBase-input.Mui-disabled': {
      textAlign: 'right',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  error: {
    fontSize: '16px !important',
  },
  icon: {
    padding: '0px 2px',
    color: '#2A7BB7',
  },
}), { name: 'InputSpotTrade' });

export default useStyles;
