import React, { useState } from 'react';
import clsx from 'clsx';
import Alert from 'components/Alert';
import { useLocation } from 'react-router';
import t from 'utils/translationHelper';

import useStyles from './index.styles';

interface IProps {
    children?: JSX.Element | JSX.Element[];
  className?: string;
}

const Container: React.FC<IProps> = ({ children, className }: IProps) => {
  const path = window.location.pathname;
  const isWelcomeScreen = path.includes('welcome');
  const classes = useStyles({ isWelcomeScreen });

  return (
    <div className={clsx([classes.container, className])}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

const Header: React.FC<IProps> = ({ children, className }: IProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.header, className)}>
      <div className="left">
        {children}
      </div>
    </div>
  );
};

interface IBodyContent extends IProps {
    width?: number
}

const LeftContent: React.FC<IBodyContent> = ({ children, className, width }: IBodyContent) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.leftContent, className)}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

const RightContent: React.FC<IBodyContent> = ({
  children,
  className,
  width,
}: IBodyContent) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.rightContent, className)} style={{ width: `${width}%` }}>
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
};

const AuthLayout = {
  Container,
  Header,
  LeftContent,
  RightContent,
};

export default AuthLayout;
