import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontSize: '22px',
    fontWeight: 100,
  },
  icon: {
    marginTop: '-21px',
  },
  inputFields: (props: any) => ({
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.3)',
      fontStyle: '13px',
      fontWeight: 400,
    },
    '& input[data-com-onepassword-filled="dark"]': {
      backgroundColor: '#000000 !important',
    },
    '& input[data-com-onepassword-filled="dark"]:focus': {
      backgroundColor: '#000000 !important',
    },
    '& .MuiOutlinedInput-input': {
      border: '1px solid #FFFFFF40',
      color: '#FFFFFF',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '24px',
      borderRadius: '5px',
    },
  }),
  passwordIcon: {
    cursor: 'pointer',
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  eyeIcon: (props: any) => ({
    color: props.isFocused ? 'rgba(0, 184, 163, 1)' : 'rgba(255, 255, 255, 0.5)',
  }),
  passwordInput: (props: any) => ({
    borderRadius: '5px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: ' rgba(255, 255, 255, 0.25)',
    },
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.3)',
      fontStyle: '13px',
      fontWeight: 400,
    },
    '& input[data-com-onepassword-filled="dark"]': {
      backgroundColor: '#000000 !important',
    },
    '& input[data-com-onepassword-filled="dark"]:focus': {
      backgroundColor: '#000000 !important',
    },
    '& .MuiOutlinedInput-input': {
      border: '1px',
      color: '#FFFFFF',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '24px',
      borderRight: '1px solid rgba(255, 255, 255, 0.25)',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      '&:focus': {
        borderRight: '1px rgba(0, 184, 163, 1) solid ',
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
  }),
  eyeIconStyles: {
    fontSize: '18px',
    transform: 'translate(-50px, 14px)',
    color: 'white',
    position: 'absolute',
    opacity: '50%',
    backgroundColor: 'transparent',
    border: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapperDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subtitle: {
    color: 'white',
    fontSize: '14px',
    opacity: '70%',
  },
  label: {
    color: '#FFFFFF80',
    fontSize: '13px',
    fontWeight: 400,
    opacity: 50,
    lineHeight: '24px',
  },
  input: {
    marginBottom: '32px',
  },
  section: {
    marginBottom: '16px',
  },
  white: {
    color: 'white',
  },
  formBacklink: {
    display: 'inherit',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '24px',
    margin: '16px 0px 30px 0px',
  },
  oldPortalLink: {
    marginTop: '100px',
  },
}), { name: 'FormStyles' });

export default useStyles;
