import { makeStyles } from '@alpha/ui-lib/theme';

import Tick from '../../../assets/tick.svg';

const useStyles = makeStyles(({ typography, palette }) => ({
  list: {
    fontFamily: typography.fontFamily,
    padding: 'unset',
    fontSize: 13,
    fontWeight: 400,
    color: 'rgba(255, 255, 255, 1)',
    paddingLeft: '2px',
    opacity: '50%',
    marginTop: 16,
    marginBottom: 16,
    '& li:not(:last-child)': {
      marginBottom: 5,
    },
    '& li': {
      marginLeft: '16px',
      lineHeight: '24px',
    },
    '& li::marker': {

    },
    listStyleType: 'circle',
  },
  contains: {
    position: 'relative',
    color: 'white',
    '&::marker': {
      color: palette.primary.main,
    },
    '&::before': {
      position: 'absolute',
      content: `url(${Tick})`,
      left: '-20px',
      top: '2px',
    },
  },

}), { name: 'newPassword' });

export default useStyles;
