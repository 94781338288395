import { all } from 'redux-saga/effects';

import authySaga from './authy/saga';
import { beneficariesSaga } from './beneficiaries/beneficiaries.saga';
import { watchGetCreditFacilitySummarySaga } from './creditFacility/saga';
import { watchGetFeatureFlagsSaga } from './featureFlags/saga';
import { generatePdfSaga } from './generateReport/sagas';
import { watchGetOptionTradeSaga } from './optionTrades/saga';
import { paymentsSaga } from './payments/payments.saga';

export default function* rootSaga() {
  yield all([
    paymentsSaga(),
    authySaga(),
    generatePdfSaga(),
    watchGetFeatureFlagsSaga(),
    watchGetCreditFacilitySummarySaga(),
    watchGetOptionTradeSaga(),
    beneficariesSaga(),
  ]);
}
