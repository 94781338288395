import React, { Dispatch, SetStateAction } from 'react';

import { TText } from '@alpha/ui-lib/interfaces';

import TableFilter from './TableFilter';

interface IFilterProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  setOpen: Dispatch<SetStateAction<HTMLElement | null>>;
  disableBackdropClick: boolean;
  header: JSX.Element | JSX.Element[];
  buttonTitle: TText;
  menuClasses: any;
  filterMenuItems: () => any[];
  className?: string;
}

const ForwardTradeFilter: React.FC<IFilterProps> = ({
  open,
  anchorEl,
  setOpen,
  disableBackdropClick,
  header,
  buttonTitle,
  menuClasses,
  filterMenuItems,
  className,
}) => (
  <TableFilter
    open={open}
    anchorEl={anchorEl}
    setOpen={setOpen}
    disableBackdropClick={disableBackdropClick}
    header={header}
    buttonTitle={buttonTitle}
    menuClasses={menuClasses}
    filterMenuItems={filterMenuItems}
  />
);

export default ForwardTradeFilter;
