import React from 'react';
import clsx from 'clsx';
import HeaderButtons from 'domain/Transactions/PaymentInfoDrawer/Header/HeaderButtons/HeaderButtons';
import formatIsoDate from 'utils/formatIsoDate';
import mapPaymentStatusDisplay from 'utils/payments/mapPaymentStatusDisplay';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box, Typography } from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table';

import useStyles from '../IATDrawerForm/IATDrawerForm.styles';

interface IIATInfoDrawer {
  handleDrawerClose?: () => void;
  open?: boolean;
  selectedTransfer?: CurrencyAccountTransferDto;
}

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition
  ? wrapper(children) : children);

export const IATInfoDrawer: React.FC<IIATInfoDrawer> = (props: IIATInfoDrawer) => {
  const {
    open, selectedTransfer, handleDrawerClose,
  } = props;

  const classes = useStyles();
  const debitingAmount = selectedTransfer?.debitingAmount || selectedTransfer?.trade?.buyAmount;
  const fundingAmount = selectedTransfer?.fundingAmount || selectedTransfer?.trade?.soldAmount;

  // swapping Debiting and Crediting Accounts data as BE handles the definitions the opposite way
  const IatTransferDrawerData = [
    { key: t('transfer_id'), value: selectedTransfer?.contractNumber || '-' },
    {
      key: t('transfer_status'),
      value: selectedTransfer && t(mapPaymentStatusDisplay(selectedTransfer.transferStatus).text),
      statusValue: true,
      statusVariant: selectedTransfer
        && mapPaymentStatusDisplay(selectedTransfer?.transferStatus).variant,
    },
    { key: t('debiting_account'), value: selectedTransfer && selectedTransfer.fundingCurrencyAccountName },
    {
      key: t('debiting_amount'),
      value: selectedTransfer && (fundingAmount ?? t('na')),
      boldValue: true,
      currency: selectedTransfer && selectedTransfer.fundingCurrencyCode,
    },
    { key: t('rate'), value: selectedTransfer?.trade?.rate ? selectedTransfer?.trade?.rate?.toFixed(4) : t('na') },
    { key: t('credit_account'), value: selectedTransfer && selectedTransfer?.debitingCurrencyAccountName },
    {
      key: t('crediting_amount'), value: (debitingAmount ?? t('na')), boldValue: true, currency: selectedTransfer && selectedTransfer.debitingCurrencyCode,
    },
    { key: t('transfer_date'), value: selectedTransfer && formatIsoDate(selectedTransfer.valueDate) },
    { key: t('transfer_reference'), value: selectedTransfer?.reference || '-', boldValue: true },
  ];

  return (
    <ConditionalWrapper condition={open} wrapper={(children: React.ReactNode) => <>{children}</>}>
      <BaseDrawer.Drawer
        open={Boolean(open)}
        onClose={handleDrawerClose}
        anchor="right"
        closeIdentifier='iat-info-drawer'
      >
        <div className={classes.header}>
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Typography className={clsx(classes.title, classes.lightWeight)}>
                {selectedTransfer?.contractNumber ? selectedTransfer?.contractNumber : 'Transfer'}
              </Typography>
            </Box>
          </Box>
          <HeaderButtons
            recordId={selectedTransfer?.id}
            showDownloadButton // Always show as a Transfer does not have draft status
            isTransfer
          />
        </div>
        <div style={{ paddingBottom: '58px' }}>
          <BaseDrawer.LineBreak />
        </div>
        {selectedTransfer
          && (
            <BaseDrawer.Body>
              <>
                <StyledKeyValueTable
                  testId="IAT-info-drawer"
                  data={IatTransferDrawerData}
                  title={t('transfer_details')}
                />
              </>
            </BaseDrawer.Body>
          )}
      </BaseDrawer.Drawer>
    </ConditionalWrapper>
  );
};

export default IATInfoDrawer;
