/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Search, { TFilterChipData } from 'components/Organisms/Search';
import SearchTable from 'components/Organisms/SearchTable';
import { startCase } from 'lodash';
import { OptionTradeDto } from 'models/optionTrades';
import browserHistory from 'services/history/browserHistory';
import formatIsoDate from 'utils/formatIsoDate';
import handleCharLimit from 'utils/handleCharLimit';
import mapOptionTradeStatusDisplay from 'utils/mapOptionTradeStatusToDisplay';
import t from 'utils/translationHelper';

import { Button } from '@alpha/ui-lib/ui/external';
import { Status } from '@alpha/ui-lib/ui/Status';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';

import OptionTradeActionDropDown from '../FxTradeTable/DisplayTable/ActionDropDown/OptionTradeActionDropdown';
import { TFilter } from '../FxTradeTable/FxTradeTable';
import useStyles from '../TransferTable/TransferTable.styles';

import useOptionTradesTable, { TOptionTradesSearchFilterTypes } from './useOptionTradesTable';

interface IOptionTradesTable {
  emptyTitle?: string,
  emptySubtitle?: string,
  testId?: string,
  filters?: TFilter[],
  filtersByField?: TOptionTradesSearchFilterTypes,
  handleRemoveFilterItem?(item: TFilter): void;
}

const OptionTradesTable: React.FC<IOptionTradesTable> = ({
  emptyTitle, emptySubtitle, testId,
  filters,
  filtersByField,
  handleRemoveFilterItem,
}: IOptionTradesTable) => {
  const classes = useStyles();

  const {
    table,
    handleInputChange,
    handleTableSortClick,
    columns,
    searchParams,
  } = useOptionTradesTable(filtersByField);

  const [filterChips, setFilterChips] = useState<TFilterChipData[]>([]);
  // const [openExportMenu, setOpenExportMenu] = useState<null | HTMLElement>(null);
  const [selectedOptionTrade, setSelectedOptionTrade] = useState<OptionTradeDto>();

  const handleTradeLinkClick = (tradeId: string) => {
    browserHistory.push(`/app/transactions/option-trades/${tradeId}`);
  };

  const handleRemoveFilter = (item: TFilterChipData): void => {
    const { field, value } = item;
    if (handleRemoveFilterItem) { handleRemoveFilterItem({ field, value }); }
  };

  const mapFiltersToDisplay = (filtersToMap:
    TFilter[]): TFilterChipData[] => filtersToMap.map((filter) => {
    let filterValue = filter.value as string;
    switch (filter.field) {
      case 'startDate':
      case 'endDate':
        filterValue = formatIsoDate(filter.value);
        break;
      default:
        filterValue = filter.value;
        break;
    }

    return {
      label: (
        <span>
          <b>
            {t(startCase(filter.displayValue ?? filter.field))}
            :
          </b>
          {' '}
          {t(filterValue)}
        </span>),
      value: filter.value,
      field: filter.field,
    };
  });

  useEffect(() => {
    setFilterChips(mapFiltersToDisplay(filters || []));
  }, [filters]);

  const generateTableData = () => (table?.items?.items?.records as OptionTradeDto[])?.map((trade) => ({
    ...trade,
    tradeDetails: trade.productName?.length > 24 ? (
      <>
        <APTooltip
          placement="bottom"
          arrow
          title={
            (
              <div style={{ width: '125px', whiteSpace: 'initial' }}>
                {trade.productName}
              </div>
            )
          }
        >
          <span>
            <span style={{ fontWeight: 600, display: 'block', fontSize: '14px' }}>
              {handleCharLimit(trade.productName, 24)}
            </span>
            <Button
              className={classes.transferTableButton}
              disableRipple
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleTradeLinkClick(trade.id);
              }}
            >
              {trade?.originalTradeTicketId || trade?.name || '-'}
            </Button>
          </span>
        </APTooltip>
      </>
    ) : (
      <>
        <span style={{ fontWeight: 600, display: 'block' }}>{trade.productName}</span>
        <Button
          className={classes.transferTableButton}
          disableRipple
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            handleTradeLinkClick(trade.id);
          }}
        >
          {trade?.originalTradeTicketId || trade?.name || '-'}
        </Button>
      </>
    ),
    tradeDate: formatIsoDate(trade.tradeDate),
    currencyPair: (trade.currencyPair ? trade.currencyPair : '-'),
    direction: (trade.direction ? trade.direction : '-'),
    stripFrequency: (trade.stripFrequency ? trade.stripFrequency : '-'),
    stripsRemaining: (trade.stripsRemaining ? trade.stripsRemaining : '-'),
    targetRemaining: (trade.type === 'TARF' ? trade.targetRemaining : '-'),
    nextExpiry: (trade.nextExpiry ? formatIsoDate(trade.nextExpiry) : '-'),
    status: (
      <Status
        className={classes.status}
        variant={mapOptionTradeStatusDisplay(trade.status).variant}
      >
        {t(mapOptionTradeStatusDisplay(trade.status).text) || '-'}
      </Status>),
    actions: (
      <div className={classes.action}>
        <OptionTradeActionDropDown
          trade={trade}
          setSelectedOptionTrade={setSelectedOptionTrade}
        />
      </div>
    ),
  }));

  // const { executeReportGeneration } = useReportsPolling(PageType.OptionTrades);

  // const handleReportGeneration = async (fileType: FileType) => {
  //   await executeReportGeneration(
  //     fileType,
  //     '',
  //     undefined,
  //     undefined,
  //     DownloadType.Summary,
  //     fileType,
  //     undefined,
  //     { // @TO-DO
  //     },
  //   );
  // };

  // const menuItems = [
  //   {
  //     content: t('export_pdf'),
  //     icon: faFilePdf,
  //     onClick: () => handleReportGeneration(FileType.PDF),
  //     underline: false,
  //   },
  // ];

  return (
    <div data-testid="trades-loader-component" className={classes.tableWrapper}>
      <div style={{ display: 'flex', flexGrow: '1' }}>
        <Search
          testId="search-option-trades"
          value={table.searchText}
          totalItems={table.items?.items.total || 0}
          placeholder={t('search_by_option_id_or_reference')}
          onChange={handleInputChange}
          filters={filterChips}
          loading={table.loading}
          handleRemoveFilter={handleRemoveFilter}
          className={classes.transferSearch}
        />
        {/* <div className={classes.exportMenu}>
          <APMenu
            icon={faDownload}
            open={Boolean(openExportMenu)}
            setOpen={setOpenExportMenu}
            anchorEl={openExportMenu}
            buttonTitle={t('export')}
          >
            {menuItems.map(
              (item) => <MenuDropdownItem setOpen={setOpenExportMenu}>{item}</MenuDropdownItem>,
            )}
          </APMenu>
        </div> */}
      </div>
      <SearchTable
        table={{
          columns,
          data: generateTableData(),
          sortOrder: table.sortOrder,
          dataTestId: testId || '',
          keyColumn: 'id',
          totalRowNumber: table.items?.total || 0,
          handleTableSortClick,
          skip: table.skip,
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: Boolean(table.items?.hasNext),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
        emptyTable={{
          title: emptyTitle || t('no_option_trades'),
          subtitle: emptySubtitle || t('you_currently_do_not_have_any_matching_option_trades'),
        }}
      />
    </div>
  );
};

export default OptionTradesTable;
