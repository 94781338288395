/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import i18n from 'i18n/config';
import { t } from 'i18next';

import { TradeStatus, TradeType } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { DateRangePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Box } from '@alpha/ui-lib/ui/external';
import { MultiAutoCompleteSelect } from '@alpha/ui-lib/ui/Select';

import { TFilter } from '../FxTradeTable/FxTradeTable';
import { TSearchFilterTypes } from '../FxTradeTable/useFxTradeTable';
import useMenuStyles from '../MenuCustomStyle.styles';

import useTradeFilter from './useTradeFilter';

const useSpotTradeFilter = (setDisableFilterBackdropClick: (value: boolean) => void,
  setFiltersByField: (filters: TSearchFilterTypes) => void) => {
  const { logEvent } = useLog();
  const menuClasses = useMenuStyles();
  const [buyCurrencies, setBuyCurrencies] = useState<string[]>([]);
  const [sellCurrencies, setSellCurrencies] = useState<string[]>([]);
  const [tradeTypes, setTradeTypes] = useState<TradeType[]>([TradeType.SPOT, TradeType.SPOT_OPTION]);
  const [tradeStatuses, setTradeStatuses] = useQueryString('tradeStatus', []);
  const [tradeStartDate, setTradeStartDate] = useQueryString(
    'tradeDateFrom',
    undefined,
  );
  const [tradeEndDate, setTradeEndDate] = useQueryString(
    'tradeDateTo',
    undefined,
  );
  const [valueStartDate, setValueStartDate] = useQueryString(
    'valueDateFrom',
    undefined,
  );
  const [valueEndDate, setValueEndDate] = useQueryString(
    'valueDateTo',
    undefined,
  );
  const [tradeFilters, setTradeFilters] = useState<TFilter[]>([]);

  const spotTradeFilterMenuItems = () => [
    // Define the menu items for the spot trade filter
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapCurrencyFilterOptions(
            tradeFilter.buyCurrenciesFilterOptions,
          )}
          label={t('buy_currencies')}
          noOptionsText={t('no_options')}
          placeholder={t('select_buy_currency')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={tradeFilter.buyCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilter.setBuyCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'buy-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapCurrencyFilterOptions(
            tradeFilter.soldCurrenciesFilterOptions,
          )}
          label={t('sell_currencies')}
          placeholder={t('select_sell_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilter.sellCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilter.setSellCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'sell-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilter.mapTradeStatusFilterOptions(
            tradeFilter.statusesFilterOptions,
          )}
          label={t('trade_status')}
          placeholder={t('select_trade_status_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilter.tradeStatusFilter || []}
          onChange={(_, value) => {
            tradeFilter.setTradeStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'trade-status-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('trade_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="trade-date-range"
          format="DD/MM/YYYY"
          onChange={tradeFilter.tradeDateChanged}
          className={menuClasses.alphaInput}
          value={
            tradeFilter.tradeDateFilter
              ? [...tradeFilter.tradeDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'trade-date-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('value_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="value-date-range"
          format="DD/MM/YYYY"
          onChange={tradeFilter.valueDateChanged}
          className={menuClasses.alphaInput}
          value={
            tradeFilter.valueDateFilter
              ? [...tradeFilter.valueDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'value-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              tradeFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Trade Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              tradeFilter.applyChanges();
              tradeFilter.setOpen(null);
              logEvent({ action: 'Click Apply Trade Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },

  ];

  const tradeFilter = useTradeFilter({
    buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeStartDate,
    tradeEndDate,
    valueStartDate,
    valueEndDate,
    setBuyCurrencies,
    setSellCurrencies,
    setTradeTypes,
    setTradeStatuses,
    setTradeStartDate,
    setTradeEndDate,
    setValueStartDate,
    setValueEndDate,
    setDisableFilterBackdropClick,
  }, 'spot');

  useEffect(() => {
    const tradeStatusesFiltered = tradeStatuses.filter((item: TradeStatus) => Object.values(TradeStatus).includes(item));

    setTradeFilters(
      parseSpotFilters(
        buyCurrencies,
        sellCurrencies,
        tradeTypes,
        tradeStatusesFiltered,
        tradeStartDate,
        tradeEndDate,
        valueStartDate,
        valueEndDate,
      ),
    );
  }, [
    buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeStartDate,
    tradeEndDate,
    valueStartDate,
    valueEndDate,
  ]);

  const parseSpotFilters = (
    buyCurrenciesSelected: string[],
    sellCurrenciesSelected: string[],
    tradeTypesSelected: TradeType[],
    tradeStatusesSelected: TradeStatus[],
    tradeStartDateSelected: string | undefined,
    tradeEndDateSelected: string | undefined,
    valueStartDateSelected: string | undefined,
    valueEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];

    result.push(
      ...buyCurrenciesSelected.map((buyCurrency) => ({
        field: 'buyCurrency',
        value: buyCurrency as string,
      })),
    );
    result.push(
      ...sellCurrenciesSelected.map((buyCurrency) => ({
        field: 'sellCurrency',
        value: buyCurrency as string,
      })),
    );
    result.push(
      ...tradeStatusesSelected.map((tradeStatus) => ({
        field: 'tradeStatus',
        value: tradeStatus as string,
      })),
    );
    result.push(
      ...tradeTypesSelected.map((tradeType) => ({
        field: 'tradeType',
        value: tradeType as string,
      })),
    );
    if (tradeStartDateSelected) {
      result.push({ field: 'tradeStartDate', value: tradeStartDateSelected });
    }
    if (tradeEndDateSelected) {
      result.push({ field: 'tradeEndDate', value: tradeEndDateSelected });
    }
    if (valueStartDateSelected) {
      result.push({ field: 'valueStartDate', value: valueStartDateSelected });
    }
    if (valueEndDateSelected) {
      result.push({ field: 'valueEndDate', value: valueEndDateSelected });
    }

    return result;
  };

  useEffect(() => {
    const tradeStatusesFiltered = tradeStatuses.filter((item: TradeStatus) => Object.values(TradeStatus).includes(item));

    // For display
    setTradeFilters(
      parseSpotFilters(
        buyCurrencies,
        sellCurrencies,
        tradeTypes,
        tradeStatusesFiltered,
        tradeStartDate,
        tradeEndDate,
        valueStartDate,
        valueEndDate,
      ),
    );

    // For searching
    setFiltersByField({
      buyCurrencies,
      sellCurrencies,
      // Have default trade types if no trade types are selected
      tradeTypes: tradeTypes?.length > 0 ? tradeTypes : [
        TradeType.SPOT,
        TradeType.SPOT_OPTION,
      ],
      tradeStatuses: tradeStatusesFiltered,
      tradeStartDate,
      tradeEndDate,
      valueStartDate,
      valueEndDate,
    });
  }, [
    buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeStartDate,
    tradeEndDate,
    valueStartDate,
    valueEndDate,
  ]);

  return {
    buyCurrencies,
    setBuyCurrencies,
    sellCurrencies,
    setSellCurrencies,
    tradeTypes,
    setTradeTypes,
    tradeStatuses,
    setTradeStatuses,
    tradeStartDate,
    setTradeStartDate,
    tradeEndDate,
    setTradeEndDate,
    valueStartDate,
    setValueStartDate,
    valueEndDate,
    setValueEndDate,
    tradeFilters,
    tradeFilter,
    spotTradeFilterMenuItems,
  };
};

export default useSpotTradeFilter;
