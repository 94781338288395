import React from 'react';

import { makeStyles } from '@alpha/ui-lib/theme';
import { Input, TInputProps } from '@alpha/ui-lib/ui/Input';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    position: 'relative',
    '& .searchIcon': {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-34px, -50%)',
      color: '#1E8777',
      fontSize: '18px',
    },
    '& .loadingIcon': {
      position: 'absolute',
      top: '50%',
      transform: 'translate(-34px, -50%)',
      color: '#1E8777',
      display: 'inline-flex',
    },
    display: 'inline-block',
    '& > h6': {
      fontWeight: 'bold',
    },
  },
}), { name: 'Search' });

type TProps = TInputProps & {
  loading?: boolean;
};

const SearchInput: React.FC<TProps> = ({ loading, ...props }: TProps) => {
  const styles = useStyles();
  return (
    <div className={styles.inputWrapper}>
      <Input
        fullWidth
        variant="filled"
        {...props}
      />
      {!loading && <FontAwesomeIcon icon={faSearch as IconProp} className="searchIcon" />}
      {loading
      && (
      <Loader
        className="loadingIcon"
        testId="loading-spinner"
        style={{
          display: 'inline-flex',
        }}
        size={14}
      />
      )}

    </div>
  );
};

export default SearchInput;
