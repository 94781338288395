/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { useParams } from 'react-router';
import t from 'utils/translationHelper';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import { ExecuteTradeResultDto, TradeFundingMethod } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Divider, Typography } from '@alpha/ui-lib/ui/external';
import { StyledKeyValueTable, TKeyValue } from '@alpha/ui-lib/ui/table';

import AlphaLogo from '../../../../../assets/alphaGreenLogo.svg';
import { formatCurrency, formatNumber } from '../../../../../utils/currency.helpers';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import Alert from '../../../../Alert';
import { TForwardsFXRequestForm } from '../formData';
import { TForwardsDrawState } from '..';

import useStyles from './index.styles';

interface IProps {
  form: FormikProps<TForwardsFXRequestForm>
  handleClose: () => void
  setDrawerState: React.Dispatch<React.SetStateAction<TForwardsDrawState>>;
  bookedTrade?: ExecuteTradeResultDto
  setTradeBooked?: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
const ConfirmTrade: React.FC<IProps> = (props) => {
  const {
    form,
    handleClose,
    setDrawerState,
    bookedTrade,
    setTradeBooked,
  } = props;

  const styles = useStyles();
  const [accountDetailData, setAccountDetailData] = useState<TKeyValue[]>([]);
  const { tradeId } = useParams<{
    tradeId: string;
  }>();

  const updateAccountDetail = (currencyAccount: CurrencyAccountDto) => {
    const currentAccountDetailData = [];
    if (currencyAccount.accountNumber) { currentAccountDetailData.push({ key: t('account_number'), value: currencyAccount.accountNumber || '-', boldValue: true }); }
    if (currencyAccount.sortCode) { currentAccountDetailData.push({ key: t('national_bank_code'), value: currencyAccount.sortCode || '-', boldValue: true }); }
    if (currencyAccount.iban) { currentAccountDetailData.push({ key: t('iban'), value: currencyAccount.iban || '-', boldValue: true }); }
    if (currencyAccount.swift) { currentAccountDetailData.push({ key: 'SWIFT', value: currencyAccount.swift || '-', boldValue: true }); }
    if (currencyAccount.reference) { currentAccountDetailData.push({ key: t('reference'), value: currencyAccount.reference || '-', boldValue: true }); }
    setAccountDetailData(currentAccountDetailData);
  };

  useEffect(() => {
    if (form.values.defaultSellCurrencyAccount) {
      updateAccountDetail(form.values.defaultSellCurrencyAccount);
    }
  }, []);

  useEffect(() => {
    if (bookedTrade && tradeId && setTradeBooked) {
      setTradeBooked(true);
    }
  }, [bookedTrade]);

  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', padding: 8,
      }}
      >
        <div className={styles.tradeContainer}>
          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('sell_currency')}</Typography>
            <Typography className={styles.textStyle}>
              {`${bookedTrade?.sellCurrency}` || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />
          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('sell_amount')}</Typography>
            <Typography className={styles.textStyle}>
              {formatCurrency(bookedTrade?.sellCurrency, bookedTrade?.sellAmount) || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('rate')}</Typography>
            {bookedTrade?.rate && (
            <Typography className={styles.textStyle}>
              {formatNumber(bookedTrade?.rate || 0, 4) || '-'}
              &#124;
              {formatNumber(1 / bookedTrade?.rate, 4) || '0'}
            </Typography>
            )}
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('buy_currency')}</Typography>
            <Typography className={styles.textStyle}>
              {`${bookedTrade?.buyCurrency}` || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('buy_amount')}</Typography>
            <Typography className={styles.textStyle}>
              {formatCurrency(bookedTrade?.buyCurrency, bookedTrade?.buyAmount) || '-'}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('forward_type')}</Typography>
            <Typography className={styles.textStyle}>
              {t('fixed')}
            </Typography>
          </div>
          <Divider style={{ backgroundColor: '#EAEAEA', margin: '10px 0px 10px 0px' }} />

          <div className={styles.detailContainer}>
            <Typography className={styles.textStyle}>{t('value_date')}</Typography>
            <Typography className={styles.textStyle}>
              {formatIsoDate(bookedTrade?.valueDate) || '-'}
            </Typography>
          </div>
        </div>
        {form.values?.initialMarginPercentage > 0 && form.values.fundingMethod === TradeFundingMethod.CURRENCY_ACCOUNT_BALANCE ? (
          <div style={{ marginBottom: '8px' }}>
            <Alert
              className={styles.alert}
              variant="warning"
              text={`${t('initial_margin_beginning_text')} ${form.values.initialMarginAmount} ${t('initial_margin_middle_text')} ${form?.values.initialMarginDueDate}.`}
              subText={t('initial_margin_booked_trade_subtext')}
            />
          </div>
        ) : null}

        {form.values?.initialMarginPercentage > 0 && form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER ? (
          <div style={{ marginBottom: '8px' }}>
            <Alert
              className={styles.alert}
              variant="warning"
              text={`${t('initial_margin_beginning_text')} ${form.values.initialMarginAmount} ${t('initial_margin_wire_transfer')} ${form?.values.initialMarginDueDate}.`}
              subText={t('initial_margin_booked_trade_subtext')}
            />
          </div>
        ) : null}

        {form.values?.initialMarginPercentage > 0 && form.values.fundingMethod === TradeFundingMethod.WIRE_TRANSFER ? (
          <div className={styles.wireTransferGuide}>
            <Typography style={{ fontWeight: 600, fontSize: '16px', lineHeight: '21px' }}>{t('wire_transfer_details')}</Typography>
            <span>
              {t('please_ensure_funds_are_remitted_to_alpha_bank')}
            </span>

            <Typography className={styles.wireDetailsHeader}>
              <img
                alt="Alpha Account"
                src={AlphaLogo}
                style={{ verticalAlign: 'top', marginRight: '8px' }}
              />
              Alpha Group
              <div className={styles.accountDetailTable}>
                <StyledKeyValueTable
                  testId="account-detail-table"
                  data={accountDetailData}
                />
              </div>
            </Typography>
          </div>
        ) : null}

        <div className={styles.buttonContainer}>
          <ActionButton data-test-id="close-forward-summary" onClick={handleClose} style={{ width: '100%', backgroundColor: '#F7F7F7', color: 'black' }}>{t('close')}</ActionButton>
        </div>
      </div>
    </>
  );
};

export default ConfirmTrade;
