import React, { useEffect, useState } from 'react';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { useLocation } from 'react-router-dom';
import history from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';

import routes from '../../../../routes.path';

interface IProps {
  onTabChange?: (index: number) => void;
}

export const getTabTitles = (eMoneyDisabled: boolean): string[] => {
  const tabTitles: string[] = [t('spot_trades'), t('forward_trades'), t('payments')];
  if (!eMoneyDisabled) {
    tabTitles.splice(2, 0, t('option_trades'));
    tabTitles.push(t('inter_account_transfers'));
  }
  return tabTitles;
};

const Tabs = ({ onTabChange }: IProps) => {
  const { isEMoneyDisabled } = useSwitchAccount();
  const tabTitles: string[] = getTabTitles(false);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();

  const setTabIndexBasedOnLocation = (): void => {
    let routeToTabIndex = [] as {route:string, index: number}[];
    if (!isEMoneyDisabled()) {
      routeToTabIndex = [
        { route: routes.transactions.spotTrades, index: 0 },
        { route: routes.transactions.forwardTrades, index: 1 },
        { route: routes.transactions.optionTrades, index: 2 },
        { route: routes.transactions.transfer, index: 4 },
        { route: routes.transactions.base, index: 3 }, // base has to be last
      ];
    } else if (isEMoneyDisabled()) {
      routeToTabIndex = [
        { route: routes.transactions.spotTrades, index: 0 },
        { route: routes.transactions.forwardTrades, index: 1 },
        { route: routes.transactions.base, index: 2 },
      ];
    }

    const matchedRoute = routeToTabIndex.find(({ route }) => location.pathname.includes(route));

    if (matchedRoute) {
      setTabIndex(matchedRoute.index);
      if (onTabChange) {
        onTabChange(matchedRoute.index);
      }
    } else {
      history.push(routes.transactions.base);
    }
  };

  useEffect(() => setTabIndexBasedOnLocation(), [
    location.pathname,
    tabIndex,
  ]);

  const handleSetSelectedTabIndex = (selectedTabIndex: number): void => {
    if (isEMoneyDisabled()) {
      switch (selectedTabIndex) {
        case 0:
          return history.push(routes.transactions.spotTrades);
        case 1:
          return history.push(routes.transactions.forwardTrades);
        default:
          return history.push(routes.transactions.base);
      }
    } else {
      switch (selectedTabIndex) {
        case 0:
          return history.push(routes.transactions.spotTrades);
        case 1:
          return history.push(routes.transactions.forwardTrades);
        case 2:
          return history.push(routes.transactions.optionTrades);
        case 3:
          return history.push(routes.transactions.base);
        case 4:
          return history.push(routes.transactions.transfer);
        default:
          return history.push(routes.transactions.base);
      }
    }
  };
  return (
    <StyledTabsWrapper
      testId="transactions-tabs"
      tabTitles={tabTitles}
      tabIndex={tabIndex}
      handleChange={(selectedTabIndex: number) => handleSetSelectedTabIndex(selectedTabIndex)}
    />
  );
};

export default Tabs;
