/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';

import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import {
  DrawdownFundingMethod,
  DrawdownSubmissionRequest,
  DrawdownValidationDto,
  TradeDto,
} from '@alpha/fx-dtos';
import {
  ManualPaymentRequest,
  PaymentOrigin,
  PaymentStatus,
} from '@alpha/payments-dtos';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../hooks/useAuthorization';
import useForm from '../../../../hooks/useForm';
import useLog from '../../../../hooks/useLog';
import { UserRole } from '../../../../models/user';
import routes from '../../../../routes.path';
import instance from '../../../../services/Axios/instance';
import CurrencyAccountsService from '../../../../services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import browserHistory from '../../../../services/history/browserHistory';
import { TStore } from '../../../../store';
import { initiateDrawdown } from '../../../../store/authy/actions';
import { TAuthyState } from '../../../../store/authy/reducer';
import { FormikPaymentType } from '../../Payments/CreatePaymentDrawer/formData';

import validation, {
  drawdownSubmissionInitialValues,
  firstPartyDrawdownInitialValues,
  FirstPartyDrawdownType,
  submissionformValidation,
} from './formData';
import { Section } from '.';

type ValidatePaymentParams = {
  data: FormikPaymentType;
  form: FirstPartyDrawdownType;
  buyCurrencyAccount?: CurrencyAccountDto;
  sellCurrencyAccount?: CurrencyAccountDto;
  validatedDrawdown?: DrawdownValidationDto;
};

type ValidatePaymentResponse = {
  errors: string[];
  id: string;
  status: PaymentStatus;
};

export const useQuickDrawdownDrawer = (
  trade: TradeDto,
  open: boolean,
  onClose: () => void
) => {
  const [formValidation, setFormValidation] = useState(validation);
  const [validatedDrawdown, setValidatedDrawdown] =
    useState<DrawdownValidationDto>();
  const [section, setSection] = useState<Section>('Drawdown');
  const [defaultSellCurrencyAccount, setDefaultSellCurrencyAccount] =
    useState<CurrencyAccountDto>();

  const [defaultBuyCurrencyAccount, setDefaultBuyCurrencyAccount] =
    useState<CurrencyAccountDto>();
  const [defaultAccount, setDefaultAccount] = useState<CurrencyAccountDto>();
  const sb = useAlphaSnackbar();
  const { logError, logEvent } = useLog();
  const dispatch = useDispatch();
  const { authorized: authorizedPaymentApproverOwn } = useAuthorization([
    [UserRole.PAYMENTS_APPROVER_OWN],
  ]);

  const requestForm = useForm<FirstPartyDrawdownType>(
    firstPartyDrawdownInitialValues,
    formValidation,
    (validateForm: FirstPartyDrawdownType) => {
      if (!defaultBuyCurrencyAccount) {
        sb.trigger(
          `${t('You_do_not_have_a_default_currency_account1')} ${trade.buyCurrencyCode
          } ${t('You_do_not_have_a_default_currency_account2')}.`,
          'error'
        );
        logError({
          action: 'No currency account',
          detail: { name: trade.buyCurrencyCode },
        });
        return;
      }
      validateDrawdownMutations.mutate({ validateTrade: trade, validateForm });
    },
    true
  );

  const drawdownSubmissionForm = useForm<DrawdownSubmissionRequest>(
    drawdownSubmissionInitialValues,
    submissionformValidation,
    (submissionForm: DrawdownSubmissionRequest) => {
      if (validatedDrawdown) {
        submitDrawdownMutations.mutate({
          submissionForm,
          validatedDrawdownParam: validatedDrawdown,
        });
      }
    },
    true,
    true
  );

  const handleSetSection = (_section: Section) => setSection(_section);

  const validateDrawdown = async ({
    validateTrade,
    validateForm,
  }: {
    validateTrade: TradeDto;
    validateForm: any;
  }) => {
    const validateResult = await FXTradeService.postValidateFirstPartyDrawdown({
      tradeId: validateTrade.id,
      fixCurrencyCode: validateForm.fixCurrencyCode,
      amount: validateForm.amount,
      valueDate: validateForm.valueDate,
      fundingMethod: validateForm.fundingMethod,
      sellCurrencyCode: validateTrade.soldCurrencyCode,
      buyCurrencyCode: validateTrade.buyCurrencyCode,
      rate: validateTrade.rate,
    });
    return validateResult;
  };

  const validatePayment = async ({ data, form }: ValidatePaymentParams) => {
    const response = await instance.post<ManualPaymentRequest, any>(
      '/payments/validate/all-roles',
      {
        beneficiaryId: data.beneficiary!.id,
        emailAddresses: data.emailAddresses,
        amount: data.amount!,
        fundingCurrency: form.sellCurrencyCode,
        debitingCurrency: form.buyCurrencyCode,
        debitingAccountId: defaultBuyCurrencyAccount?.id || '',
        fundingAccountId: defaultSellCurrencyAccount?.id || '',
        paymentDate: moment(data.date!).format('DD/MM/yyyy'),
        reference: data.reference || '',
        purpose:
          typeof data.purpose === 'object' ? data.purpose.code : data.purpose,
        drawdownId: validatedDrawdown?.id,
        tradeId: validatedDrawdown?.tradeId,
        paymentOrigin: PaymentOrigin.DRAWDOWN,
      }
    );
    return response.data;
  };

  const validatePaymentMutations = useMutation(validatePayment, {
    onSuccess: (data: ValidatePaymentResponse) => {
      if (data.status === PaymentStatus.INVALID) {
        sb.trigger(
          data.errors.length
            ? `${t('validation_error')} ${data.errors[0]}`
            : t('there_was_an_error_validating_your_payment')
        );
        logError({
          action: 'Error validating drawdown payments',
          error: data.errors,
        });
      } else if (data.status === PaymentStatus.VALIDATED) {
        const payment = { ...requestForm.values, id: data.id };
        requestForm.setFieldValue('payments', [payment]);
        logEvent({ action: 'Successflully added drawdown payment' });
        handleSetSection('Submit');
      }
    },
    onError: (error: any, data: ValidatePaymentResponse) => {
      sb.trigger(`${t('error_in_validating_payment')}: ${error}`, 'error');
      logError({ action: 'Error Validating Payment', error });
    },
  });

  const validateDrawdownMutations = useMutation(validateDrawdown, {
    onSuccess: (data: DrawdownValidationDto) => {
      setValidatedDrawdown(data);
      if (data.errors && data.errors.length > 0) {
        sb.trigger(
          data.errors.length
            ? `${t('validation_error')} ${data.errors[0]}`
            : t('there_was_an_error_validating_your_payment')
        );
      } else {
        sb.trigger(t('drawdown_validated'), 'success');
        setTimeout(() => {
          validatePaymentMutations.mutate({
            data: {
              amount: data.finalRemittedAmount,
              beneficiary: requestForm.values.beneficiary,
              date: requestForm.values.valueDate,
              emailAddresses: [], // not including notifying email logic
              paymentOrigin: PaymentOrigin.DRAWDOWN,
              reference: '',
              singlePayment: true,
            },
            form: {
              amount: data.finalRemittedAmount!,
              buyCurrencyCode: requestForm.values.buyCurrencyCode,
              fixCurrencyCode: requestForm.values.fixCurrencyCode,
              fundingMethod: DrawdownFundingMethod.WIRE_TRANSFER,
              rate: 0,
              sellCurrencyCode: requestForm.values.sellCurrencyCode,
              tradeId: '',
              valueDate: requestForm.values.valueDate,
            },
            defaultBuyCurrencyAccount,
            defaultSellCurrencyAccount,
            validatedDrawdown,
          });
        }, 1000);
      }
    },
    onError: (error: any) => {
      sb.trigger(`${t('error_in_validating_drawdown')}: ${error}`, 'error');
      logError({ action: 'Error Validating Drawdown', error });
    },
  });

  // Submission
  const submitDrawdown = async ({
    submissionForm,
    validatedDrawdownParam,
  }: {
    submissionForm: DrawdownSubmissionRequest;
    validatedDrawdownParam: DrawdownValidationDto;
  }) => {
    const response = await instance.post<DrawdownSubmissionRequest, any>(
      `/fx/drawdowns/submit/first-party/${validatedDrawdownParam.id}`,
      {
        paymentIds: submissionForm.paymentIds,
        dynamicLinkingId: submissionForm.dynamicLinkingId || 'empty',
        totp: submissionForm.totp || '123456',
        approvalRequestId: submissionForm.approvalRequestId,
        softToken: submissionForm.softToken || true,
      }
    );

    return response.data;
  };

  const onSubmissionSuccess = () => {
    sb.trigger(t('drawdown_successfully_submitted'), 'success');
    logEvent({ action: 'Drawdown Submitted Success' });
    drawdownSubmissionForm.resetForm();
    requestForm.resetForm();
    onClose();

    if (
      matchPath(browserHistory.location.pathname, {
        path: routes.transactions.trades,
      })
    ) {
      const randomStr = `${Math.random()}`.substring(2, 7);
      browserHistory.push(`/app/trades/${trade.id}#${randomStr}`);
    }
  };

  const submitDrawdownMutations = useMutation(submitDrawdown, {
    onSuccess: () => {
      onSubmissionSuccess();
    },
    onError: (error: Error) => {
      sb.trigger(
        error
          ? `${t('error')}!: ${error}`
          : t('there_was_an_error_submitting_this_drawdown')
      );
    },
  });

  useEffect(() => {
    drawdownSubmissionForm.setFieldValue(
      'paymentIds',
      requestForm.values.payments!.map((p: FormikPaymentType) => p.id as string)
    );
    drawdownSubmissionForm.validateForm(drawdownSubmissionForm.values);
  }, [requestForm.values.payments]);

  const accountConfigQuery = useQuery(
    'getAccountConfig',
    () =>
      FXTradeService.getAccountConfigurationWithCcys(
        trade?.soldCurrencyCode || '',
        trade?.buyCurrencyCode || ''
      ),
    {
      enabled: false,
    }
  );

  if (accountConfigQuery.error) {
    sb.trigger(`${t('error_loading_account_configurations')}.`);
    logError({ action: 'Error loading account configurations.' });
  }

  const getDefaultCurrencyAccount = async (currencyCode: string) => {
    try {
      const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
        currencyCode
      );

      const defaultCA = currencyAccounts.currencyAccounts?.find(
        (ca) => ca.default
      );

      return defaultCA;
    } catch (e) {
      return undefined;
    }
  };

  const getDefaultCurrencyAccounts = async (
    soldCurrencyCode: string,
    buyCurrencyCode: string
  ) => {
    const soldCA = await getDefaultCurrencyAccount(soldCurrencyCode);
    const buyCA = await getDefaultCurrencyAccount(buyCurrencyCode);
    setDefaultSellCurrencyAccount(soldCA);
    setDefaultBuyCurrencyAccount(buyCA);
  };

  useEffect(() => {
    if (open && trade) {
      getDefaultCurrencyAccounts(trade.soldCurrencyCode, trade.buyCurrencyCode);
      accountConfigQuery.refetch().catch((error) => {
        sb.trigger(
          error?.message || `${t('error_loading_account_configurations')}.`
        );
        logError({ action: 'Error loading account configurations.', error });
      });

      requestForm.setFieldValue('valueDate', null);
    }
    if (!open) {
      requestForm.resetForm();
      setSection('Drawdown');
    }
  }, [open]);

  const handleAmountChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const amount = parseFloat(e.target.value.replace(/,/g, ''));
    const isValidMaximumAmount = validateMaximumAmount(
      amount,
      requestForm.values.fixCurrencyCode
    );

    const isValidMinimumAmount = validateMinimumAmount(
      amount,
      requestForm.values.fixCurrencyCode
    );

    if (
      e.target.value !== '' &&
      !(isValidMinimumAmount && isValidMaximumAmount)
    ) {
      requestForm.setFieldValue('amount', e.target.value, false);
      requestForm.setFieldError(
        'amount',
        isValidMinimumAmount
          ? t('amount_is_above_the_maximum_drawdown_limit')
          : t('amount_is_below_the_minimum_drawdown_limit')
      );
      await requestForm.setFieldTouched('amount', true, false);
    } else {
      await requestForm.setFieldValue('amount', e.target.value, true);
      await requestForm.setFieldTouched('amount', true, true);
      requestForm.setFieldValue(
        'fundingMethod',
        DrawdownFundingMethod.WIRE_TRANSFER
      ); // default set to wire transfer
    }
  };

  const handleDropdownChange = async (event: any) => {
    if (trade) {
      const selectedCurrency = event.target.value;
      if (selectedCurrency === trade.buyCurrencyCode) {
        setDefaultAccount(defaultBuyCurrencyAccount);
      } else {
        setDefaultAccount(defaultSellCurrencyAccount);
      }

      await requestForm.setFieldValue(
        'fixCurrencyCode',
        selectedCurrency,
        true
      );

      const amount = parseFloat(
        requestForm.values.amount.toString().replace(/,/g, '')
      );
      const isValidMinimum = validateMinimumAmount(amount, selectedCurrency);
      const isValidMaximum = validateMaximumAmount(amount, selectedCurrency);

      if (!isValidMinimum) {
        await requestForm.setFieldError(
          'amount',
          t('amount_is_below_the_minimum_drawdown_limit')
        );
      } else if (!isValidMaximum) {
        await requestForm.setFieldError(
          'amount',
          t('amount_is_above_the_maximum_drawdown_limit')
        );
      } else {
        await requestForm.setFieldError('amount', undefined);
      }
    }
  };

  const validateMinimumAmount = (
    inputValue: number,
    fixCurrencyCode: string = requestForm.values.fixCurrencyCode
  ): boolean => {
    const minFX = accountConfigQuery.data?.minimumFX;

    if (trade && minFX) {
      if (
        (fixCurrencyCode === trade?.buyCurrencyCode &&
          inputValue < minFX?.buyAmount) ||
        (fixCurrencyCode === trade?.soldCurrencyCode &&
          inputValue < minFX?.sellAmount)
      ) {
        return false;
      }
    }
    return true;
  };

  const validateMaximumAmount = (
    inputValue: number,
    fixedCurrency: string = requestForm.values.fixCurrencyCode
  ): boolean => {
    const maxFX = accountConfigQuery.data?.maximumFX;

    const aboveFXLimitBuy =
      (maxFX?.buyAmount && inputValue > maxFX?.buyAmount) ||
      inputValue > trade.buyBalance;

    const aboveFXLimitSell =
      (maxFX?.sellAmount && inputValue > maxFX?.sellAmount) ||
      inputValue > trade.sellBalance;

    if (trade && maxFX) {
      if (
        (fixedCurrency === trade?.buyCurrencyCode && aboveFXLimitBuy) ||
        (fixedCurrency === trade?.soldCurrencyCode && aboveFXLimitSell)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleDrawdownMFA = async () => {
    if (
      requestForm?.values?.payments &&
      requestForm?.values?.payments.length > 0
    ) {
      // needs MFA with dynamic link id
      dispatch(
        initiateDrawdown({
          type: 'DRAWDOWN',
          drawdownId: validatedDrawdown?.id || '',
          paymentIds: requestForm?.values?.payments?.map((sp) => sp.id!) || [],
          approverOwn: true,
          softToken: true,
          firstPartyDrawdown: true,
        })
      );
    } else {
      // No Need MFA, covering the following:
      // No payments with WIRED, ACCOUNT and PAD Authorised Trade
      drawdownSubmissionForm.setSubmitting(true);
      drawdownSubmissionForm.handleSubmit();
    }
  };

  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  useEffect(() => {
    if (
      authyState.type?.type === 'DRAWDOWN' &&
      authyState.status === 'SUCCESS' &&
      !drawdownSubmissionForm.isSubmitting
    ) {
      onSubmissionSuccess();
    }
  }, [authyState.status, authyState.type]);

  return {
    validateDrawdownMutations,
    validatePaymentMutations,
    requestForm,
    drawdownSubmissionForm,
    handleAmountChange,
    handleDropdownChange,
    defaultAccount,
    handleSetSection,
    validatedDrawdown,
    submitDrawdownMutations,
    submitDrawdown,
    section,
    accountConfigQuery,
    defaultSellCurrencyAccount,
    defaultBuyCurrencyAccount,
    handleDrawdownMFA,
    validateMinimumAmount,
    validateMaximumAmount,
  };
};

export default useQuickDrawdownDrawer;
