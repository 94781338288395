import React, { useEffect, useState } from 'react';
import IATDrawerContainer from 'components/Drawer/IATDrawer/IATDrawerContainer';
import ApprovalDrawer from 'components/Molecules/Payments/ApprovalDrawer';
import CreatePaymentDrawer from 'components/Molecules/Payments/CreatePaymentDrawer';
import RejectPaymentModal from 'components/Molecules/Payments/RejectPaymentModal';
import CreateForwardTradeDrawer from 'components/Molecules/Trade/CreateForwardTradeDrawer';
import CreateSpotDrawer from 'components/Molecules/Trade/CreateSpotDrawer/index';
import Authorization from 'hocs/Authorization';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import useReportsPolling, {
  DownloadType,
  FileType,
  PageType,
} from 'hooks/useReportsPolling';
import useSwitchAccount from 'hooks/useSwitchAccount';
import i18n from 'i18n/config';
import { UserRole } from 'models/user';
import { Route, Switch } from 'react-router-dom';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import browserHistory from 'services/history/browserHistory';
import isFxMarketClosed from 'utils/fxTrades/isFxMarketClosed';
import t from 'utils/translationHelper';

import { TradeDto, TradeStatus } from '@alpha/fx-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import { TText } from '@alpha/ui-lib/interfaces';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { DateRangePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Box } from '@alpha/ui-lib/ui/external';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import APFilterMenu from '@alpha/ui-lib/ui/molecules/Menu/FilterMenu';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { MultiAutoCompleteSelect } from '@alpha/ui-lib/ui/Select';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faFileExcel,
  faFilePdf,
  faSlidersH,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUp,
  faList,
  faPlusCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import routes from '../../../routes.path';
import BeneficiaryInfoDrawer from '../BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import PaymentInfoDrawer from '../PaymentInfoDrawer/PaymentInfoDrawer';

import ForwardTradeFilter from './Filter/ForwardTradeFilter';
import SpotTradeFilter from './Filter/SpotTradeFilter';
import useForwardTradeFilter from './Filter/useForwardTradeFilter';
import useOptionTradesFilter from './Filter/useOptionTadesFilter';
import usePaymentFilter from './Filter/usePaymentFilter';
import useSpotTradeFilter from './Filter/useSpotTradeFilter';
import useTransferFilter from './Filter/useTransferFilter';
import ForwardTrade from './ForwardTrade/ForwardTrade';
import { TFilter } from './FxTradeTable/FxTradeTable';
import useFxTradeTable, {
  TSearchFilterTypes,
} from './FxTradeTable/useFxTradeTable';
import OptionTradesTable from './OptionTradesTable/OptionTradesTable';
import { TOptionTradesSearchFilterTypes } from './OptionTradesTable/useOptionTradesTable';
import PaymentTableContainer from './PaymentTableContainer/PaymentTableContainer';
import { TPaymentSearchFilterTypes } from './PaymentTableContainer/usePaymentTable';
import SpotTrade from './SpotTrade/SpotTrade';
import Tabs, { getTabTitles } from './Tabs/Tabs';
import TransferTable from './TransferTable/TransferTable';
import { TTransferSearchFilterTypes } from './TransferTable/useTransferTable';
import useStyles from './Dashboard.styles';
import useMenuStyles from './MenuCustomStyle.styles';

// eslint-disable-next-line max-lines-per-function
const Dashboard: React.FC = () => {
  //  Gerneral
  const menuClasses = useMenuStyles();
  const { homePageUrl, isEMoneyDisabled } = useSwitchAccount();
  const sb = useAlphaSnackbar();
  const fxMarketClosed = isFxMarketClosed();

  const classes = useStyles({ fxMarketClosed });
  const { logEvent, logError } = useLog();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [firstPartyFlow, setFirstPartyFlow] = useState(false);
  const path = window.location.pathname;
  const [disableFilterBackdropClick, setDisableFilterBackdropClick] = useState<boolean>(false);

  // Payments
  const [createPaymentsDrawerOpen, setCreatePaymentsDrawerOpen] = useState(false);
  const [multiSelectedPayments, setMultiSelectedPayments] = useState<
    PaymentDto[]
  >([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [paymentsToReject, setPaymentsToReject] = useState<PaymentDto[]>([]);
  const [paymentsToApprove, setPaymentsToApprove] = useState<PaymentDto[]>([]);
  const [selectedPayment, setSelectedPayment] = useState<PaymentDto>();
  const [selectedPaymentBeneficiaryId, setSelectedPaymentBeneficiaryId] = useState<string | undefined>(undefined);
  const [approvalDrawerOpen, setApprovalDrawerOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [rejectedPaymentIds, setRejectedPaymentIds] = useState<string[]>([]);

  const [paymentCurrencies, setPaymentCurrencies] = useState<string[]>([]);
  const [paymentStatuses, setPaymentStatuses] = useQueryString(
    'paymentStatus',
    [],
  );
  const [paymentValueStartDate, setPaymentValueStartDate] = useState<string>();
  const [paymentValueEndDate, setPaymentValueEndDate] = useState<string>();
  const [paymentFilters, setPaymentFilters] = useState<TFilter[]>([]);
  const [paymentFiltersByField, setPaymentFiltersByField] = useState<TPaymentSearchFilterTypes>();
  const { authorized: isPaymentFileInputter } = useAuthorization([
    [UserRole.PAYMENT_FILE_INPUTTER],
  ]);
  const { authorized: isForwardInputter } = useAuthorization([
    [UserRole.FORWARD_INPUTTER],
  ]);

  const paymentFilter = usePaymentFilter({
    paymentCurrencies,
    paymentStatuses,
    paymentValueStartDate,
    paymentValueEndDate,
    setPaymentCurrencies,
    setPaymentStatuses,
    setPaymentValueStartDate,
    setPaymentValueEndDate,
    setDisableFilterBackdropClick,
  });

  // Spot Trades
  const [openExportMenu, setOpenExportMenu] = useState<null | HTMLElement>(
    null,
  );
  const [createSpotDrawerOpen, setCreateSpotDrawerOpen] = useState(false);
  const [createForwardTradeDrawerOpen, setCreateForwardTradeDrawerOpen] = useState(false);
  const [tradeDraft, setTradeDraft] = useState<TradeDto>();

  const [openPaymentMenu, setOpenPaymentMenu] = useState<null | HTMLElement>(
    null,
  );

  const [filtersByField, setFiltersByField] = useState<TSearchFilterTypes>();
  const [filtersByFieldForward, setFiltersByFieldForward] = useState<TSearchFilterTypes>();
  const [bookTradeAction, setBookTradeAction] = useQueryString(
    'booktrade',
    undefined,
  );
  const [fxLastUpdatedTime, setFxLastUpdatedTime] = useState<string>();
  const [fxAllowed, setFxAllowed] = useState<boolean>();
  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setFxAllowed(result.fxAllowed);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error: e });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  const { searchParams } = useFxTradeTable((path.includes('spot')) ? filtersByField : filtersByFieldForward);

  useEffect(() => {
    if (path.includes('spot')) {
      searchParams.queryParams.type = 'SPOT,SPOT_OPTION';
    }
    if (path.includes('forward')) {
      searchParams.queryParams.type = 'FORWARD_FIXED,FORWARD_VARIABLE,FORWARD_OPEN,FORWARD_WINDOW';
    }
  }, [searchParams, path]);

  const { executeReportGeneration } = useReportsPolling(PageType.FX);
  const handleReportGeneration = async (fileType: FileType) => {
    await executeReportGeneration(
      fileType,
      '',
      undefined,
      undefined,
      DownloadType.Summary,
      fileType,
      undefined,
      {
        type: fileType,
        buycurrency: searchParams.queryParams.buycurrency,
        soldcurrency: searchParams.queryParams.soldcurrency,
        tradetype: searchParams.queryParams.type,
        status: searchParams.queryParams.status,
        searchtext: searchParams.queryParams.searchtext,
        tradedatefrom: searchParams.queryParams.tradedatefrom,
        tradedateto: searchParams.queryParams.tradedateto,
        valuedatefrom: searchParams.queryParams.valuedatefrom,
        valuedateto: searchParams.queryParams.valuedateto,
        accessdatefrom: searchParams.queryParams.accessdatefrom,
        accessdateto: searchParams.queryParams.accessdateto,
      },
    );
  };

  const spotMenuItems = [
    {
      content: t('spot_trade_summary_pdf'),
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      content: t('spot_trade_summary_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
      underline: true,
    },
    {
      content: t('trade_summary_pdf'),
      icon: faFilePdf,
      onClick: () => {
        searchParams.queryParams.type = '';
        handleReportGeneration(FileType.PDF);
      },
      underline: true,
    },
    {
      content: t('trade_summary_excel'),
      icon: faFileExcel,
      onClick: () => {
        searchParams.queryParams.type = '';
        handleReportGeneration(FileType.EXCEL);
      },
    },
  ];

  const forwardMenuItems = [
    {
      content: t('forward_trade_summary_pdf'),
      icon: faFilePdf,
      onClick: () => handleReportGeneration(FileType.PDF),
      underline: true,
    },
    {
      content: t('forward_trade_summary_excel'),
      icon: faFileExcel,
      onClick: () => handleReportGeneration(FileType.EXCEL),
      underline: true,
    },
    {
      content: t('trade_summary_pdf'),
      icon: faFilePdf,
      onClick: () => {
        searchParams.queryParams.type = '';
        handleReportGeneration(FileType.PDF);
      },
      underline: true,
    },
    {
      content: t('trade_summary_excel'),
      icon: faFileExcel,
      onClick: () => {
        searchParams.queryParams.type = '';
        handleReportGeneration(FileType.EXCEL);
      },
    },
  ];

  const {
    buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeFilter,
    setBuyCurrencies,
    setSellCurrencies,
    setTradeTypes,
    setTradeStatuses,
    setTradeStartDate,
    setTradeEndDate,
    setValueStartDate,
    setValueEndDate,
    spotTradeFilterMenuItems,
    tradeFilters,
  } = useSpotTradeFilter(setDisableFilterBackdropClick, setFiltersByField);

  // Forward Trades
  const {
    forwardBuyCurrencies,
    forwardSellCurrencies,
    forwardTradeTypes,
    forwardTradeStatuses,
    setForwardBuyCurrencies,
    setForwardSellCurrencies,
    setForwardTradeTypes,
    setForwardTradeStatuses,
    setForwardTradeStartDate,
    setForwardTradeEndDate,
    setForwardValueStartDate,
    setForwardValueEndDate,
    setForwardAccessDateStartDate,
    setForwardAccessDateEndDate,
    tradeFiltersForward,
    forwardTradeFilterMenuItems,
    tradeFilterForward,
  } = useForwardTradeFilter(setDisableFilterBackdropClick,
    setFiltersByFieldForward);

  // Transfer
  const [openIATDrawer, setOpenIATDrawer] = useState(false);

  const [transferDebitingCurrencies, setTransferDebitingCurrencies] = useState<
    string[]
  >([]);
  const [transferFundingCurrencies, setTransferFundingCurrencies] = useState<
    string[]
  >([]);
  const [transferStatuses, setTransferStatuses] = useQueryString(
    'transferStatus',
    [],
  );
  const [transferDateStart, setTransferDateStart] = useState<string>();
  const [transferDateEnd, setTransferDateEnd] = useState<string>();

  const transferFilter = useTransferFilter({
    debitingCurrencies: transferDebitingCurrencies,
    fundingCurrencies: transferFundingCurrencies,
    transferStatuses,
    transferDateStart,
    transferDateEnd,
    setDebitingCurrencies: setTransferDebitingCurrencies,
    setFundingCurrencies: setTransferFundingCurrencies,
    setTransferStatuses,
    setTransferDateStart,
    setTransferDateEnd,
    setDisableFilterBackdropClick,
  });

  const [transferFilters, setTransferFilters] = useState<TFilter[]>([]);
  const [transferFiltersByField, setTransferFiltersByField] = useState<TTransferSearchFilterTypes>();

  // Option Trades
  const [optionTradeStartDate, setOptionTradeStartDate] = useState<string>();
  const [optionTradeEndDate, setOptionTradeEndDate] = useState<string>();

  const optionTradesFilter = useOptionTradesFilter({
    optionTradeStartDate,
    optionTradeEndDate,
    setOptionTradeStartDate,
    setOptionTradeEndDate,
    setDisableFilterBackdropClick,
  });

  const [optionsFilters, setOptionsFilters] = useState<TFilter[]>([]);
  const [optionsFiltersByField, setOptionsFiltersByField] = useState<TOptionTradesSearchFilterTypes>();

  const header = (
    <p style={{ padding: '0px 20px', margin: '8px auto' }}>{t('filters')}</p>
  );

  // Option Trades
  const optionTradesFilterMenuItems = () => [
    {
      content: (
        <DateRangePicker
          label={t('trade_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="option-trade-date-range"
          format="DD/MM/YYYY"
          onChange={optionTradesFilter.optionTradeDateChanged}
          className={menuClasses.alphaInput}
          value={
            optionTradesFilter.optionTradeDateFilter
              ? [...optionTradesFilter.optionTradeDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'option-trade-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              optionTradesFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Option Trade Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              optionTradesFilter.applyChanges();
              optionTradesFilter.setOpen(null);
              logEvent({ action: 'Click Apply Option Trade Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  // Payments
  const paymentFilterMenuItems = [
    {
      content: (
        <MultiAutoCompleteSelect
          options={paymentFilter.mapCurrencyFilterOptions(
            paymentFilter.paymentCurrenciesFilterOptions,
          )}
          label={t('payment_currencies')}
          placeholder={t('select_payment_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={paymentFilter.paymentCurrenciesFilter || []}
          onChange={(_, value) => {
            paymentFilter.setPaymentCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'buy-currency-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('value_date')}
          labelTo={t('to^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          labelFrom={t('from^')}
          testId="value-date-range"
          format="DD/MM/YYYY"
          onChange={paymentFilter.paymentValueDateChanged}
          className={menuClasses.alphaInput}
          value={paymentFilter.paymentValueDateFilter || [null, null]}
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'payment-value-date-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={paymentFilter.mapPaymentStatusFilterOptions(
            paymentFilter.paymentStatusesFilterOptions,
          )}
          label={t('payment_status')}
          placeholder={t('select_payment_status_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={paymentFilter.paymentStatusFilter || []}
          onChange={(_, value) => {
            paymentFilter.setPaymentStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'payment-status-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              paymentFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Payment Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              paymentFilter.applyChanges();
              paymentFilter.setPaymentFilterOpen(null);
              logEvent({ action: 'Click Apply Payment Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  // Transfers
  const transferFilterMenuItems = () => [
    {
      content: (
        <MultiAutoCompleteSelect
          options={paymentFilter.mapCurrencyFilterOptions(
            transferFilter.fundingCurrenciesFilterOptions,
          )}
          label={t('from_currencies')}
          placeholder={t('select_from_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={transferFilter.fundingCurrenciesFilter || []}
          onChange={(_, value) => {
            transferFilter.setFundingCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-from-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={transferFilter.mapCurrencyFilterOptions(
            transferFilter.debitingCurrenciesFilterOptions,
          )}
          label={t('to_currencies')}
          placeholder={t('select_to_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={transferFilter.debitingCurrenciesFilter || []}
          onChange={(_, value) => {
            transferFilter.setDebitingCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-to-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={transferFilter.mapTransferStatusFilterOptions(
            transferFilter.transferStatusFilterOptions,
          )}
          label={t('transfer_status')}
          placeholder={t('select_transfer_status_to_fiter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={transferFilter.transferStatusFilter || []}
          onChange={(_, value) => {
            transferFilter.setTransferStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'transfer-status-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('transfer_date')}
          labelTo={t('to^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          labelFrom={t('from^')}
          testId="transfer-date-range"
          format="DD/MM/YYYY"
          onChange={transferFilter.valueDateChanged}
          className={menuClasses.alphaInput}
          value={
            transferFilter.valueDateFilter
              ? [...transferFilter.valueDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'trade-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              transferFilter.clearAllFilters();
              logEvent({ action: 'Click Clear Transfer Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              transferFilter.applyChanges();
              transferFilter.setOpen(null);
              logEvent({ action: 'Click Apply Transfer Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      underline: false,
      key: 'action-buttons',
    },
  ];

  // Mixed
  const filterRemoveHandlers = {
    buyCurrency: (value: string) => {
      setBuyCurrencies(buyCurrencies.filter((item) => item !== value));
    },
    sellCurrency: (value: string) => {
      setSellCurrencies(sellCurrencies.filter((item) => item !== value));
    },
    tradeType: (value: string) => {
      setTradeTypes(tradeTypes.filter((item) => item !== value));
    },
    tradeStatus: (value: string) => {
      setTradeStatuses(
        tradeStatuses.filter((item: TradeStatus) => item !== value),
      );
    },
    tradeStartDate: () => {
      setTradeStartDate('');
    },
    tradeEndDate: () => {
      setTradeEndDate('');
    },
    valueStartDate: () => {
      setValueStartDate('');
    },
    valueEndDate: () => {
      setValueEndDate('');
    },

    paymentCurrency: (value: string) => {
      setPaymentCurrencies(paymentCurrencies.filter((item) => item !== value));
    },
    paymentStatus: (value: string) => {
      setPaymentStatuses(
        paymentStatuses.filter((item: string) => item !== value),
      );
    },
    paymentValueStartDate: () => {
      setPaymentValueStartDate('');
    },
    paymentValueEndDate: () => {
      setPaymentValueEndDate('');
    },
    transferDebitingCurrency: (value: string) => {
      setTransferDebitingCurrencies(
        transferDebitingCurrencies.filter((item) => item !== value),
      );
    },
    transferFundingCurrency: (value: string) => {
      setTransferFundingCurrencies(
        transferFundingCurrencies.filter((item) => item !== value),
      );
    },
    transferStatus: (value: string) => {
      setTransferStatuses(
        transferStatuses.filter((item: string) => item !== value),
      );
    },
    transferValueStartDate: () => {
      setTransferDateStart('');
    },
    transferValueEndDate: () => {
      setTransferDateEnd('');
    },
    optionTradeStartDate: () => {
      setOptionTradeStartDate('');
    },
    optionTradeEndDate: () => {
      setOptionTradeEndDate('');
    },
    forwardBuyCurrency: (value: string) => {
      setForwardBuyCurrencies(
        forwardBuyCurrencies.filter((item) => item !== value),
      );
    },
    forwardSellCurrency: (value: string) => {
      setForwardSellCurrencies(
        forwardSellCurrencies.filter((item) => item !== value),
      );
    },
    forwardTradeType: (value: string) => {
      setForwardTradeTypes(
        Array.from(new Set(forwardTradeTypes.filter((item) => item !== value))),
      );
    },
    forwardTradeStatus: (value: string) => {
      setForwardTradeStatuses(
        Array.from(new Set(forwardTradeStatuses.filter((item: TradeStatus) => item !== value))),
      );
    },
    forwardTradeStartDate: () => {
      setForwardTradeStartDate('');
    },
    forwardTradeEndDate: () => {
      setForwardTradeEndDate('');
    },
    forwardValueStartDate: () => {
      setForwardValueStartDate('');
    },
    forwardValueEndDate: () => {
      setForwardValueEndDate('');
    },
    forwardAccessDateStartDate: () => {
      setForwardAccessDateStartDate('');
    },
    forwardAccessDateEndDate: () => {
      setForwardAccessDateEndDate('');
    },
  };

  const handleRemoveFilterItem = (filterItem: TFilter): void => {
    const { field, value } = filterItem;
    type TField = keyof typeof filterRemoveHandlers;
    const handler = filterRemoveHandlers[field as TField];
    if (handler) {
      handler(value);
    }
  };

  const forwardTradeFilterTitle = (): TText => {
    const filterCount = tradeFiltersForward.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const spotTradeFilterTitle = (): TText => {
    const filterCount = tradeFilters.filter((filter) => filter.field !== 'tradeType').length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const paymentFilterTitle = (): TText => {
    const filterCount = paymentFilters.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const transferFilterTitle = (): TText => {
    const filterCount = transferFilters.length;
    return filterCount > 0 ? `${t('filters')} (${filterCount})` : t('filters');
  };

  const paymentFileMenuOptions = [
    {
      content: t('create_manual_payments'),
      icon: faList,
      onClick: () => {
        setCreatePaymentsDrawerOpen(true);
        setOpenPaymentMenu(null);
        logEvent({ action: 'Click create manual payment' });
      },
      disableHoverEffect: true,
      disableClick: true,
      underline: true,
    },
    {
      content: t('payment_upload'),
      icon: faArrowUp,
      onClick: () => {
        logEvent({ action: 'Click Payment Upload' });
        browserHistory.push(routes.transactions.paymentUpload);
      },
    },
  ];

  // eslint-disable-next-line max-lines-per-function
  const rightAlignedCta = () => {
    const tabTitles = getTabTitles(isEMoneyDisabled());
    const tabTitle = tabTitles[tabIndex];
    if (tabTitle === t('spot_trades')) {
      return (
        <Box display="flex" flexDirection="row" gridGap={10} className={menuClasses.rightCTA}>
          <APMenu
            icon={faDownload as IconDefinition}
            open={Boolean(openExportMenu)}
            setOpen={setOpenExportMenu}
            anchorEl={openExportMenu}
            buttonTitle={t('export')}
          >
            {spotMenuItems.map((item) => (
              <MenuDropdownItem
                setOpen={setOpenExportMenu}
                className="spot-exports"
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APMenu>

          {!isEMoneyDisabled() && fxAllowed && (
            <Box className={classes.spotButtonWrapper}>
              <Authorization
                requiredRoles={[[UserRole.SPOT, UserRole.SPOT_INPUTTER]]}
                key="auth-button"
              >
                <ActionButton
                  disabled={isFxMarketClosed()}
                  onClick={() => {
                    setCreateSpotDrawerOpen(true);
                  }}
                  size="medium"
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle as IconProp}
                    style={{ marginRight: '8px' }}
                  />
                  {t('new_spot_trade')}
                </ActionButton>
              </Authorization>
            </Box>
          )}

          {isEMoneyDisabled() && fxAllowed && (
            <Authorization requiredRoles={[[UserRole.SPOT]]} key="auth-button">
              <APTooltip
                placement="bottom-end"
                arrow
                title={
                  isFxMarketClosed()
                    ? t('fx_execution_is_not_available_at_this_time1')
                    : ''
                }
              >
                <div>
                  <ActionButton
                    size="medium"
                    testId="create-trade"
                    disabled={isFxMarketClosed()}
                    onClick={() => {
                      setFirstPartyFlow(true);
                      setCreateSpotDrawerOpen(true);
                    }}
                  >
                    {t('new_first_party_spot')}
                  </ActionButton>
                </div>
              </APTooltip>
            </Authorization>
          )}
        </Box>
      );
    }

    if (tabTitle === t('forward_trades')) {
      // Forward Trades
      return (
        <Box
          display="flex"
          flexDirection="row"
          gridGap={10}
          className={menuClasses.rightCTA}
        >
          <APMenu
            icon={faDownload as IconDefinition}
            open={Boolean(openExportMenu)}
            setOpen={setOpenExportMenu}
            anchorEl={openExportMenu}
            buttonTitle={t('export')}
          >
            {forwardMenuItems.map((item) => (
              <MenuDropdownItem
                setOpen={setOpenExportMenu}
                className="fowards-exports"
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APMenu>
          {!isEMoneyDisabled() && fxAllowed && isForwardInputter && (
            <Box className={classes.spotButtonWrapper}>
              <Authorization
                requiredRoles={[[UserRole.FORWARD_INPUTTER]]}
                key="auth-button"
              >
                <ActionButton
                  disabled={isFxMarketClosed()}
                  onClick={() => {
                    setCreateForwardTradeDrawerOpen(true);
                  }}
                  size="medium"
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle as IconProp}
                    style={{ marginRight: '8px' }}
                  />
                  {t('new_forward_trade')}
                </ActionButton>
              </Authorization>
            </Box>
          )}
        </Box>
      );
    }

    if (tabTitle === t('inter_account_transfers')) {
      // Transfer
      return (
        <Box
          display="flex"
          flexDirection="row"
          gridGap={10}
          className={menuClasses.rightCTA}
        >
          <>
            <APFilterMenu
              open={Boolean(transferFilter.open)}
              anchorEl={transferFilter.open}
              setOpen={transferFilter.setOpen}
              disableBackdropClick={disableFilterBackdropClick}
              header={header}
              // @ts-ignore
              icon={faSlidersH}
              buttonTitle={transferFilterTitle()}
              key="transfer-filter-button"
              menuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                className: menuClasses.menuCustom,
              }}
            >
              {transferFilterMenuItems().map((item) => (
                <MenuDropdownItem
                  key={item.key}
                  setOpen={transferFilter.setOpen}
                >
                  {item}
                </MenuDropdownItem>
              ))}
            </APFilterMenu>
            <Authorization requiredRoles={[[UserRole.IAT_INPUTTER]]}>
              <ActionButton
                size="medium"
                testId="create-transfer"
                onClick={() => setOpenIATDrawer(true)}
              >
                {t('inter_account_transfer')}
              </ActionButton>
            </Authorization>
          </>
        </Box>
      );
    }

    if (tabTitle === t('payments')) {
      // Payments
      return (
        <Box
          display="flex"
          flexDirection="row"
          gridGap={10}
          className={menuClasses.rightCTA}
        >
          <APFilterMenu
            open={Boolean(paymentFilter.paymentFilterOpen)}
            anchorEl={paymentFilter.paymentFilterOpen}
            setOpen={paymentFilter.setPaymentFilterOpen}
            disableBackdropClick={disableFilterBackdropClick}
            header={header}
            // @ts-ignore
            icon={faSlidersH}
            buttonTitle={paymentFilterTitle()}
            key="filter-button"
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              className: menuClasses.menuCustom,
            }}
          >
            {paymentFilterMenuItems.map((item) => (
              <MenuDropdownItem
                key={item.key}
                setOpen={paymentFilter.setPaymentFilterOpen}
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APFilterMenu>

          {isPaymentFileInputter ? (
            <Box className={menuClasses.paymentCTA}>
              <APMenu
                open={Boolean(openPaymentMenu)}
                anchorEl={openPaymentMenu}
                setOpen={setOpenPaymentMenu}
                disableBackdropClick={false}
                hideIcon
                buttonTitle={t('new_payment')}
              >
                {paymentFileMenuOptions.map((item) => (
                  <MenuDropdownItem setOpen={setOpenPaymentMenu}>
                    {item}
                  </MenuDropdownItem>
                ))}
              </APMenu>
            </Box>
          ) : (
            <Authorization requiredRoles={[[UserRole.PAYMENTS_INPUTTER]]}>
              <ActionButton
                size="medium"
                testId="create-payment"
                disabled={false}
                onClick={() => setCreatePaymentsDrawerOpen(true)}
              >
                {t('new_payment')}
              </ActionButton>
            </Authorization>
          )}
        </Box>
      );
    }

    if (tabTitle === t('option_trades')) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          gridGap={10}
          className={menuClasses.rightCTA}
        >
          <APFilterMenu
            open={Boolean(optionTradesFilter.open)}
            anchorEl={optionTradesFilter.open}
            setOpen={optionTradesFilter.setOpen}
            disableBackdropClick={disableFilterBackdropClick}
            header={header}
            // @ts-ignore
            icon={faSlidersH}
            buttonTitle={paymentFilterTitle()}
            key="filter-button"
            menuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              className: menuClasses.menuCustom,
            }}
          >
            {optionTradesFilterMenuItems().map((item) => (
              <MenuDropdownItem
                key={item.key}
                setOpen={optionTradesFilter.setOpen}
              >
                {item}
              </MenuDropdownItem>
            ))}
          </APFilterMenu>
        </Box>
      );
    }
  };

  const onTabChange = (index: number) => {
    setTabIndex(index);

    if (index === 0) {
      tradeFilter.clearAllFilters();
      tradeFilter.applyChanges();
    }
    if (index === 1) {
      tradeFilterForward.clearAllFilters();
      tradeFilterForward.applyChanges();
      setForwardTradeStatuses([TradeStatus.ACTIVE]);
    }
  };

  const parsePaymentFilters = (
    paymentCurrenciesSelected: string[],
    paymentStatusesSelected: PaymentStatus[],
    paymentValueStartDateSelected: string | undefined,
    paymentValueEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];

    result.push(
      ...paymentCurrenciesSelected.map((paymentCurrency) => ({
        field: 'paymentCurrency',
        value: paymentCurrency as string,
      })),
    );
    result.push(
      ...paymentStatusesSelected.map((paymentStatus) => ({
        field: 'paymentStatus',
        value: paymentStatus as string,
      })),
    );
    if (paymentValueStartDateSelected) {
      result.push({
        field: 'paymentValueStartDate',
        value: paymentValueStartDateSelected,
      });
    }
    if (paymentValueEndDateSelected) {
      result.push({
        field: 'paymentValueEndDate',
        value: paymentValueEndDateSelected,
      });
    }

    return result;
  };

  // Payments
  const handleApprovePayments = (payments: PaymentDto[]): void => {
    setPaymentsToApprove(payments);
    setApprovalDrawerOpen(true);
  };
  // Payments
  const handleRejectPayments = (payments: PaymentDto[]): void => {
    setPaymentsToReject(payments);
    setRejectModalOpen(true);
  };

  // Transfers
  const parseTransferFilters = (
    transferDebitingCurrenciesSelected: string[],
    transferFundingCurrenciesSelected: string[],
    transferStatusesSelected: PaymentStatus[],
    transferValueStartDateSelected: string | undefined,
    transferValueEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];

    result.push(
      ...transferDebitingCurrenciesSelected.map((debitingCurrency) => ({
        field: 'transferDebitingCurrency',
        value: debitingCurrency as string,
      })),
    );
    result.push(
      ...transferFundingCurrenciesSelected.map((fundingCurrency) => ({
        field: 'transferFundingCurrency',
        value: fundingCurrency as string,
      })),
    );
    result.push(
      ...transferStatusesSelected.map((transferStatus) => ({
        field: 'transferStatus',
        value: transferStatus as string,
      })),
    );
    if (transferValueStartDateSelected) {
      result.push({
        field: 'transferValueStartDate',
        value: transferValueStartDateSelected,
      });
    }
    if (transferValueEndDateSelected) {
      result.push({
        field: 'transferValueEndDate',
        value: transferValueEndDateSelected,
      });
    }

    return result;
  };

  // Options Trades
  const parseOptionTradesFilters = (
    optionTradeStartDateSelected: string | undefined,
    optionTradeEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];
    if (optionTradeStartDateSelected) {
      result.push({
        field: 'optionTradeStartDate',
        value: optionTradeStartDateSelected,
      });
    }
    if (optionTradeEndDateSelected) {
      result.push({
        field: 'optionTradeEndDate',
        value: optionTradeEndDateSelected,
      });
    }
    return result;
  };

  // Payments
  useEffect(() => {
    setPaymentFilters(
      parsePaymentFilters(
        paymentCurrencies,
        paymentStatuses,
        paymentValueStartDate,
        paymentValueEndDate,
      ),
    );

    setPaymentFiltersByField({
      paymentCurrencies,
      paymentStatuses,
      paymentValueStartDate,
      paymentValueEndDate,
    });
  }, [
    paymentCurrencies,
    paymentStatuses,
    paymentValueStartDate,
    paymentValueEndDate,
  ]);

  // Transfers
  useEffect(() => {
    setTransferFilters(
      parseTransferFilters(
        transferDebitingCurrencies,
        transferFundingCurrencies,
        transferStatuses,
        transferDateStart,
        transferDateEnd,
      ),
    );
    setTransferFiltersByField({
      debitingCurrencies: transferDebitingCurrencies,
      fundingCurrencies: transferFundingCurrencies,
      statuses: transferStatuses,
      valueDateStart: transferDateStart,
      valueDateEnd: transferDateEnd,
    });
  }, [
    transferDateStart,
    transferDateEnd,
    transferDebitingCurrencies,
    transferFundingCurrencies,
    transferStatuses,
  ]);

  // Option Trades
  useEffect(() => {
    setOptionsFilters(
      parseOptionTradesFilters(optionTradeStartDate, optionTradeEndDate),
    );
    setOptionsFiltersByField({
      startDate: optionTradeStartDate,
      endDate: optionTradeEndDate,
    });
  }, [optionTradeStartDate, optionTradeEndDate]);

  // Payments
  useEffect(() => {
    setPaymentsToReject(multiSelectedPayments);
    setPaymentsToApprove(multiSelectedPayments);
  }, [multiSelectedPayments]);

  // Book Spot Trades
  useEffect(() => {
    const openSpotTradeDrawer = async (tradeId: string) => {
      try {
        const submittedTrade = await FXTradeService.getTradeData(tradeId);
        if (submittedTrade && submittedTrade.status === TradeStatus.SUBMITTED) {
          setTradeDraft(submittedTrade);
          setCreateSpotDrawerOpen(true);
        }
      } catch (error) {
        sb.trigger(
          error?.message || t('there_is_an_error_loading_trade_information'),
          'error',
        );
        logError({ action: 'Error loading trade information', error });
      }
    };

    if (bookTradeAction) {
      openSpotTradeDrawer(bookTradeAction);
    }
  }, [bookTradeAction]);

  const reloadData = () => {
    // trigger reload
    setTransferFiltersByField({
      ...transferFiltersByField,
    } as TTransferSearchFilterTypes);
  };

  return (
    <APMainLayout
      height="165px"
      title={t('transactions')}
      breadCrumbs={[{ text: t('home'), url: homePageUrl }]}
      sticky
      pageTabs={<Tabs onTabChange={onTabChange} />}
      rightAlignedCta={rightAlignedCta()}
    >
      <div
        style={{
          paddingTop: '12px',
          backgroundColor: '#fafafa',
          minHeight: 'calc(100vh - 310px)',
          paddingBottom: '30px',
        }}
      >
        <Switch>
          <Route path={routes.transactions.spotTrades}>
            <SpotTrade
              tradeFilters={tradeFilters}
              filtersByField={filtersByField}
              handleRemoveFilterItem={handleRemoveFilterItem}
              fxLastUpdatedTime={fxLastUpdatedTime}
              filterButton={(
                <SpotTradeFilter
                  open={Boolean(tradeFilter.open)}
                  anchorEl={tradeFilter.open}
                  setOpen={tradeFilter.setOpen}
                  disableBackdropClick={disableFilterBackdropClick}
                  header={header}
                  buttonTitle={spotTradeFilterTitle()}
                  menuClasses={menuClasses}
                  filterMenuItems={spotTradeFilterMenuItems}
                />
              )}
            />
          </Route>
          <Route path={routes.transactions.forwardTrades}>
            <ForwardTrade
              tradeFilters={tradeFiltersForward}
              filtersByField={filtersByFieldForward}
              handleRemoveFilterItem={handleRemoveFilterItem}
              fxLastUpdatedTime={fxLastUpdatedTime}
              filterButton={(
                <ForwardTradeFilter
                  open={Boolean(tradeFilterForward.open)}
                  anchorEl={tradeFilterForward.open}
                  setOpen={tradeFilterForward.setOpen}
                  disableBackdropClick={disableFilterBackdropClick}
                  header={header}
                  buttonTitle={forwardTradeFilterTitle()}
                  menuClasses={menuClasses}
                  className="Forward Trades Filters"
                  filterMenuItems={forwardTradeFilterMenuItems}
                />
              )}
            />
          </Route>
          <Route path={routes.transactions.transfer}>
            <Card>
              <TransferTable
                testId="transfer-table"
                emptyTitle={t('no_internal_account_transfers')}
                emptySubtitle={t(
                  'you_currently_do_not_have_any_matching_transfers',
                )}
                filters={transferFilters}
                filtersByField={transferFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
              />
              <IATDrawerContainer
                open={openIATDrawer}
                onClose={() => setOpenIATDrawer(false)}
                onSubmittedTransfer={() => {
                  setTimeout(() => {
                    reloadData();
                  }, 1000);
                }}
              />
            </Card>
          </Route>
          <Route path={routes.transactions.optionTrades}>
            <Card>
              <OptionTradesTable
                testId="option-trades-table"
                filters={optionsFilters}
                filtersByField={optionsFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
                emptyTitle={t('no_option_trades')}
                emptySubtitle={t(
                  'you_currently_do_not_have_any_matching_option_trades',
                )}
              />
            </Card>
          </Route>
          <Route path={routes.transactions.base}>
            <Card>
              <PaymentTableContainer
                createPaymentOpen={createPaymentsDrawerOpen}
                setCreatePaymentOpen={setCreatePaymentsDrawerOpen}
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                multiSelectedPayments={multiSelectedPayments}
                setMultiSelectedPayments={setMultiSelectedPayments}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                selectedPaymentBeneficiary={selectedPaymentBeneficiaryId}
                setSelectedPaymentBeneficiary={setSelectedPaymentBeneficiaryId}
                handleOpenApprovalDrawer={() => setApprovalDrawerOpen(true)}
                handleRejectModalOpen={() => setRejectModalOpen(true)}
                handleApprovePayments={handleApprovePayments}
                handleRejectPayments={handleRejectPayments}
                rejectedPaymentIds={rejectedPaymentIds}
                filters={paymentFilters}
                filtersByField={paymentFiltersByField}
                handleRemoveFilterItem={handleRemoveFilterItem}
              />
            </Card>
          </Route>
        </Switch>
        <CreatePaymentDrawer
          open={createPaymentsDrawerOpen}
          onClose={() => {
            setCreatePaymentsDrawerOpen(false);
          }}
          triggerSpotOpen={{
            setOpen: setCreateSpotDrawerOpen,
            setTradeDraft,
          }}
        />
        <CreateSpotDrawer
          firstPartyFlow={firstPartyFlow}
          open={createSpotDrawerOpen}
          onClose={() => {
            setCreateSpotDrawerOpen(false);
          }}
          tradeDraft={tradeDraft}
          setTradeDraft={setTradeDraft}
          onTradeBooked={() => {
            setFxLastUpdatedTime(Date.now().toString());
          }}
          heading={firstPartyFlow ? t('first_party_spot') : t('new_spot_trade')}
        />
        <CreateForwardTradeDrawer
          open={createForwardTradeDrawerOpen}
          onClose={() => {
            setCreateForwardTradeDrawerOpen(false);
          }}
          onTradeBooked={() => {
            setFxLastUpdatedTime(Date.now().toString());
          }}
          heading={t('new_forward_trade')}
        />
        <PaymentInfoDrawer
          paymentDrawerOpen={Boolean(selectedPayment)}
          setPaymentDrawerOpen={setSelectedPayment}
          selectedPayment={selectedPayment}
          sharedStyles={classes}
          testIds={{
            paymentDrawerBeneficiaryTable: 'payment-info-drawer-bene',
            paymentDrawerPaymentTable: 'payment-info-drawer-payment',
          }}
        />
        <BeneficiaryInfoDrawer
          paymentSummaryBeneficiaryId={selectedPaymentBeneficiaryId}
          setPaymentSummaryBeneficiaryId={setSelectedPaymentBeneficiaryId}
          onClose={() => {
            setSelectedPaymentBeneficiaryId(undefined);
          }}
          open={Boolean(selectedPaymentBeneficiaryId)}
        />
        {paymentsToApprove.length > 0
          && paymentsToApprove[0].status === PaymentStatus.SUBMITTED
          && (approvalDrawerOpen ? (
            <ApprovalDrawer
              open={approvalDrawerOpen}
              onClose={() => {
                setApprovalDrawerOpen(false);
                setSelectedIds([]);
              }}
              selectedPayments={paymentsToApprove}
            />
          ) : null)}
        {paymentsToReject.length > 0
          && paymentsToReject[0].status === PaymentStatus.SUBMITTED && (
            <RejectPaymentModal
              open={rejectModalOpen}
              handleClose={() => {
                setRejectModalOpen(false);
                setSelectedIds([]);
              }}
              payments={paymentsToReject}
              handleRejected={(rejectedIds) => {
                setRejectedPaymentIds(rejectedIds);
              }}
            />
        )}
      </div>
    </APMainLayout>
  );
};

export default Dashboard;
