import React, { useEffect, useState } from 'react';
import useSwitchAccount from 'hooks/useSwitchAccount';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';

import { IOption } from '../../../../../../../components/AutocompleteDropDown/AutocompleteDropDown';
import useAlphaSnackbar from '../../../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../../../hooks/useLog';
import BeneficiariesService from '../../../../../../../services/Beneficiaries/beneficiaries.service';

import BeneficiaryDropdown from './BeneficiaryDropdown';

export type Props = {
  currencyCode: string,
  beneficiaryId?: string,
  beneficiary?: BeneficiaryDto,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  dropdownHeight?: string,
  setIsLoading?: Function
  quickDrawdown?: boolean,
  refetchBene?: number
}

const BeneficiaryDropdownContainer: React.FC<Props> = ({
  currencyCode,
  setFieldValue,
  beneficiary,
  beneficiaryId,
  dropdownHeight,
  setIsLoading,
  quickDrawdown,
  refetchBene,
}) => {
  const sb = useAlphaSnackbar();

  const { logError } = useLog();
  const [currentOption, setCurrentOption] = useState<IOption>({
    name: '',
    code: '',
    countryCode: '',
    id: '',
  });
  const [textFieldValue, setTextFieldValue] = useState(beneficiary?.name || '');
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryDto[]>([]);

  const getAllBeneficiaries = async (searchParams?: string): Promise<void> => {
    try {
      setIsLoading ? setIsLoading(true) : null;
      const paymentBeneficiaries = await BeneficiariesService.getAllManualPaymentBeneficiaries(
        searchParams,
        currencyCode,
      );
      setBeneficiaries(paymentBeneficiaries);
      setIsLoading ? setIsLoading(false) : null;
    } catch (error) {
      sb.trigger(error?.message || t('there_was_an_error_retrieving_your_beneficiaries'));
      logError({ action: 'Error loading beneficiaries', error });
    }
  };

  useEffect(() => {
    if (refetchBene) {
      getAllBeneficiaries();
    }
  }, [refetchBene]);

  useEffect(() => {
    getAllBeneficiaries();
  }, []);

  const { isEMoneyDisabled } = useSwitchAccount();

  useEffect(() => {
    getAllBeneficiaries();
  }, [currencyCode]);

  useEffect(() => {
    if (beneficiaryId) {
      const findBeneficiaryById = beneficiaries.find((bene) => bene && bene.id === beneficiaryId);
      if (findBeneficiaryById) {
        setFieldValue('beneficiary', findBeneficiaryById);
      }
    }
  }, [beneficiaries, beneficiaryId]);

  useEffect(() => {
    try {
      setCurrentOption({
        name: beneficiary?.friendlyName || beneficiary?.name || '',
        code: beneficiary?.currencyCode || '',
        countryCode: beneficiary?.currencyCode || '',
        id: beneficiary?.id || '',
      });
    } catch { }
  }, [beneficiary]);

  useEffect(() => {
    if (currentOption?.name && textFieldValue !== currentOption?.name) {
      setCurrentOption({
        name: '',
        code: '',
        countryCode: '',
        id: '',
      });
      setFieldValue('beneficiary', null);
    }
  }, [textFieldValue]);

  const mapBeneficiaryToDropdownOptions = (newBeneficiaries: BeneficiaryDto[]): IOption[] => {
    let filterBeneficiaries = newBeneficiaries;
    if (isEMoneyDisabled() || quickDrawdown) {
      filterBeneficiaries = newBeneficiaries.filter((bene) => bene.party === 'First');
    }
    const beneficiariesToMap = filterBeneficiaries.map((bene: BeneficiaryDto) => ({
      name: bene.friendlyName || bene.name,
      alternativeName: bene.name,
      disabled:
        !['CNH', 'CNY'].includes(currencyCode?.toUpperCase())
          ? bene.currencyCode !== currencyCode
          : !['CNH', 'CNY'].includes(bene.currencyCode?.toUpperCase()),
      code: bene.currencyCode,
      id: bene.id,
      paymentInfo: bene.iban || bene.accountNumber,
      countryCode: bene.bankCountryCode,
      party: bene.party,
    }));
    return beneficiariesToMap;
  };

  const handleChange = (name: string, valueCode: string, option: IOption) => {
    try {
      const findBeneficiaryById = beneficiaries.find((bene) => bene && bene.id === option.id);
      if (findBeneficiaryById) {
        setFieldValue('beneficiary', findBeneficiaryById);
      }
    } catch { }
  };

  const handleKeyUp = async (textfieldValue: string) => {
    await getAllBeneficiaries(textfieldValue);
  };

  return (
    <BeneficiaryDropdown
      handleChange={handleChange}
      dropdownOptions={mapBeneficiaryToDropdownOptions(beneficiaries)}
      handleKeyUp={handleKeyUp}
      currentOption={currentOption}
      setCurrentOption={setCurrentOption}
      textFieldValue={textFieldValue}
      setTextFieldValue={setTextFieldValue}
      disabled={!currencyCode}
      dropdownHeight={dropdownHeight}
    />
  );
};

export default BeneficiaryDropdownContainer;
