import React from 'react';

import { BeneficiaryDto } from '@alpha/bene-dtos';

import BackdropLoader from '../../Molecules/Loaders/BackdropLoader/BackdropLoader';
import Drawer from '../Drawer';
import DrawerHeaderFlag from '../DrawerHeaderFlag/DrawerHeaderFlag';

import Body from './Body/Body';

export type TBeneficiaryDrawerBeneficiary = Omit<BeneficiaryDto, 'id' >;
interface IProps {
  beneficiary: TBeneficiaryDrawerBeneficiary | undefined,
  open: boolean;
  loading?: boolean;
  handleClose: () => void;
  disableBackdropClick?: boolean;
}

const BeneficiaryDrawer: React.FC<IProps> = ({
  beneficiary,
  open,
  handleClose,
  loading,
  disableBackdropClick = true,
}: IProps) => (
  <Drawer
    anchor="right"
    open={open}
    onClose={handleClose}
    disableBackdropClick={disableBackdropClick}
    headerTitle={(
      <b>
        {beneficiary?.friendlyName || beneficiary?.name || ''}
      </b>
      )}
    headerSubtitle={beneficiary?.friendlyName || beneficiary?.name || ''}
    headerStats={{
      country: {
        value:
            (beneficiary && (
              <DrawerHeaderFlag
                countryCode={beneficiary.bankCountryCode}
              />
            ))
            || '-',
      },
      currency: {
        value:
            (beneficiary && (
              <DrawerHeaderFlag countryCode={beneficiary.currencyCode} />
            ))
            || '-',
      },
    }}
  >
    {
        loading
          ? <BackdropLoader testId="beneficiary-info-loader" />
          : <Body beneficiary={beneficiary} />
      }
  </Drawer>
);

export default BeneficiaryDrawer;
