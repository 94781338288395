import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  container: (props: any) => ({
    marginBottom: '60px',
    position: 'relative',
    '& > .wrapper': {
      position: 'relative',
      marginTop: props.marginTop ? props.marginTop : '0px',
      '& > .title': {
        display: 'inline-block',
        marginBottom: '12px',
        '& span': {
          fontWeight: '600',
          fontSize: '44px',
        },
      },
      '& .green': {
        color: primary.main,
        marginRight: '8px',
      },
      '& .white': {
        color: 'white',
        // width: '548px',
      },
      '& .description h6': {
        lineHeight: '24px',
        opacity: '50%',
        fontWeight: 400,
        fontSize: '16px',
        color: '#FFFFFF',
      },
    },
  }),
}), { name: 'FormTitle' });

export default useStyles;
