import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import { useHistory } from 'react-router-dom';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useUpdatePassword from '../../../hooks/useUpdatePassword';
import routes from '../../../routes.path';
import hasCorrectChars from '../../../utils/hasCorrectChars';
import hasLowerCase from '../../../utils/hasLowerCase';
import hasNumber from '../../../utils/hasNumber';
import hasSpecialChars from '../../../utils/hasSpecialChars';
import hasUpperCase from '../../../utils/hasUpperCase';
import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

import useStyles from './index.styles';

interface IProps extends IOnboarding, IReturning {
  onboarded?: boolean
}

const NewPassword: React.FC<IProps> = ({ onboarding, userObject, onboarded }: IProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const classes = useStyles();

  const formClasses = useFormStyles({ isFocused });

  const { formik, loading } = useUpdatePassword(userObject);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { handleSetLoaderPercentage } = useOnboardingContext();
  const history = useHistory();
  const snackbar = useAlphaSnackbar();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      if (onboarding && onboarded) {
        handleSetLoaderPercentage(75);
        snackbar.trigger(t('please_complete_the_onboarding_process'), 'warning');
        history.push(routes.auth.onboarding.setupMfa);
      }
      handleSetLoaderPercentage(50);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      const { length } = inputRef.current.value;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [showPassword]);

  const handleButtonDisabled = (): boolean => {
    if (!(
      hasLowerCase(formik.values.password)
      && hasUpperCase(formik.values.password)
      && hasNumber(formik.values.password)
      && hasCorrectChars(formik.values.password, 8)
      && hasSpecialChars(formik.values.password))
    ) return true;

    return false;
  };

  return (
    <div className={formClasses.wrapperDiv}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <FormTitle
          title={t('set_your_new_password')}
          description={t('onboarding_new_pwd')}
          marginTop={onboarding ? '0px' : '100px'}
        />
        <form onSubmit={formik.handleSubmit}>
          <Typography className={formClasses.label}>{t('new_password^')}</Typography>
          <APInput
            autoComplete="off"
            className={formClasses.passwordInput}
            InputProps={{
              onFocus: (() => setIsFocused(true)),
              onBlur: (() => setIsFocused(false)),
              endAdornment: (
                <span
                  onClick={handleClickShowPassword}
                  className={formClasses.passwordIcon}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className={formClasses.eyeIcon} />
                </span>

              ),
            }}
            testid="input-password"
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder={t('enter_a_new_password')}
            variant="outlined"
            fullWidth
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputRef={inputRef}
            data-dd-privacy="mask"
          />
          <ul className={classes.list}>
            <li
              className={clsx(hasLowerCase(formik.values.password) && classes.contains)}
            >
              {t('one_lowercase_character')}

            </li>
            <li
              className={clsx(hasUpperCase(formik.values.password) && classes.contains)}
            >
              {t('one_uppercase_character')}
            </li>
            <li
              className={clsx(hasNumber(formik.values.password) && classes.contains)}
            >
              {t('one_number')}
            </li>
            <li
              className={clsx(hasCorrectChars(formik.values.password, 8) && classes.contains)}
            >
              {t('8_characters_minimum')}
            </li>
            <li
              className={clsx(hasSpecialChars(formik.values.password) && classes.contains)}
            >
              {t('one_special_character')}
            </li>
          </ul>
          <ActionButton
            type="submit"
            testId="submit-new-password"
            fullWidth
            loading={loading}
            disabled={loading || handleButtonDisabled()}
            size="large"
            style={{ marginTop: '24px' }}
          >
            {t('continue')}
          </ActionButton>
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
