import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import {
  TradeDto,
} from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';

import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useAuthorization from 'hooks/useAuthorization';
import useLog from 'hooks/useLog';
import { UserRole } from 'models/user';
import PaymentsService from 'services/Payments/payments.service';
import { TStore } from '../../../../store';
import { TAuthyState } from '../../../../store/authy/reducer';
import DrawerBackdropLoader from '../../Loaders/DrawerBackdropLoader/DrawerBackdropLoader';

import Drawdown from './Drawdown';
import DrawdownConfirmation from './DrawdownConfirmation';
import useStyles from './index.styles';
import Settlement from './Settlement';
import useCreateDrawdownDrawer from './useCreateDrawdownDrawer';

export type Section = 'Drawdown' | 'Settlement' | 'Submit';

type Props = {
  open: boolean,
  onClose: () => void,
  trade: TradeDto,
}

const mapSectionToPercentage = (section: Section): '5%' | '50%' | '100%' => {
  switch (section) {
    case 'Settlement':
      return '50%';
    case 'Submit':
      return '100%';
    default:
    case 'Drawdown':
      return '5%';
  }
};

// eslint-disable-next-line max-lines-per-function
const CreateDrawdownDrawer: React.FC<Props> = ({ open, onClose, trade }) => {
  const styles = useStyles();

  const [openPaymentClearWarning,
    setOpenPaymentClearWarning] = useState<boolean>(false);
  const [warningModalConfirmationAction, setWarningModalConfirmationAction] = useState<'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER'>('CLOSE_DRAWER');
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(true);
  const { authorized: paymentApproverOwn } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  const { authorized: padApprover } = useAuthorization([[UserRole.PAD_APPROVER]]);
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const {
    validateDrawdownMutations,
    requestForm,
    paymentsForm,
    drawdownSubmissionForm,
    handleAmountChange,
    handleDropdownChange,
    handleSetSection,
    validatedDrawdown,
    submitDrawdownMutations,
    section,
    accountConfigQuery,
    defaultSellCurrencyAccount,
    defaultBuyCurrencyAccount,
    handleDrawdownMFA,
  } = useCreateDrawdownDrawer(trade, open, onClose);

  useEffect(() => {
    setSendEmailNotification(!paymentApproverOwn);
  }, [paymentApproverOwn]);

  useEffect(() => {
    if (
      authyState.type?.type === 'DRAWDOWN'
      && authyState.status === 'SUCCESS'
      && !paymentsForm.isSubmitting
      && paymentsForm.values.payments?.length
      && sendEmailNotification
    ) {
      onSubmissionSuccess();
    }
  }, [authyState.status, authyState.type]);

  const onSubmissionSuccess = async () => {
    if (paymentsForm.values.payments?.length) {
      try {
        const approvers: Approver[] = await PaymentsService.getApprovers(
          paymentsForm.values.payments[0].id || '',
        );
        const approverIds: string[] = approvers.flatMap((approver: Approver) => (
          approver.eligible ? approver.userId : []));
        PaymentsService.postGenericPaymentApproverEmail({ approverIds });
      } catch (error) {
        sb.trigger(t('there_was_an_error_sending_your_email_notification'), 'error');
        logError({ action: 'Error sending email notification' });
      }
    }
  };

  const onPaymentClearWarningConfirmation = (action: 'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER') => {
    if (action === 'BACK_TO_DRAWDOWN') {
      handleSetSection('Drawdown');
    }
    if (action === 'CLOSE_DRAWER') {
      onClose();
    }
    paymentsForm.setFieldValue('payments', []);
  };

  const onDrawerClose = (): void => {
    const shouldShowWarning = Boolean(paymentsForm.values.payments
      && (paymentsForm.values.payments?.length > 0));

    if (shouldShowWarning) {
      setWarningModalConfirmationAction('CLOSE_DRAWER');
      setOpenPaymentClearWarning(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onDrawerClose}
        anchor="right"
        className={styles.container}
        closeIdentifier='create-drawdown-drawer'
      >
        <BaseDrawer.Header headerTitle={t('new_drawdown')} headerSubtitle="">
          <div style={{ margin: '30px 0' }}>
            <div style={{ position: 'relative', marginBottom: '30px' }} className="stepper">
              <div style={{
                marginLeft: mapSectionToPercentage(section), width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#0C8375', position: 'absolute', zIndex: '1', top: '-5px', left: '-2px',
              }}
              />
              <div style={{
                backgroundColor: '#EFEFEF', height: '5px', borderRadius: '5px', width: '100%', position: 'absolute',
              }}
              />
              <div style={{
                backgroundColor: '#0C8375', height: '5px', borderRadius: '5px', width: mapSectionToPercentage(section), position: 'absolute',
              }}
              />
              <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '16px',
              }}
              >
                <Typography variant="subtitle1" className={clsx(section === 'Drawdown' && 'active')}>{t('drawdown')}</Typography>
                <Typography variant="subtitle1" className={clsx(section === 'Settlement' && 'active')}>{t('settlement')}</Typography>
                <Typography variant="subtitle1" className={clsx(section === 'Submit' && 'active')}>{t('submit')}</Typography>
              </div>
            </div>
            <BaseDrawer.LineBreak />
          </div>
        </BaseDrawer.Header>
        <BaseDrawer.Body>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 'calc(100vh - 293px)',
          }}
          >
            {
              section === 'Drawdown' && (
                <>
                  <Drawdown
                    trade={trade}
                    drawdownForm={requestForm}
                    defaultSellCurrencyAccount={defaultSellCurrencyAccount!}
                    padAllowed={(accountConfigQuery.data?.padAllowed
                      && accountConfigQuery.data?.padEligible && padApprover)
                      || (trade && trade.padEligible && trade.padApproved)}
                    handleAmountChange={handleAmountChange}
                    handleDropdownChange={handleDropdownChange}
                  />

                  <DrawerBackdropLoader display={validateDrawdownMutations.isLoading} text={t('validating_your_drawdown')} />
                </>
              )
            }
            {
              section === 'Settlement' && (
                <Settlement
                  handleSetSection={handleSetSection}
                  trade={trade}
                  form={requestForm}
                  paymentsForm={paymentsForm}
                  buyCurrencyAccount={defaultBuyCurrencyAccount}
                  sellCurrencyAccount={defaultSellCurrencyAccount}
                  validatedDrawdown={validatedDrawdown}
                  setOpenPaymentClearWarning={setOpenPaymentClearWarning}
                  setWarningModalConfirmationAction={setWarningModalConfirmationAction}
                />
              )
            }
            {
              section === 'Submit' && (
                <>
                  <DrawdownConfirmation
                    trade={trade}
                    handleSetSection={handleSetSection}
                    drawdownRequestForm={requestForm.values}
                    validatedDrawdown={validatedDrawdown}
                    submissionForm={drawdownSubmissionForm}
                    paymentValues={paymentsForm.values}
                    handleFormSubmit={handleDrawdownMFA}
                    sellCurrencyAccount={defaultSellCurrencyAccount!}
                    buyCurrencyAccount={defaultBuyCurrencyAccount!}
                    sendEmailNotification={sendEmailNotification}
                    setSendEmailNotification={setSendEmailNotification}
                  />
                  <DrawerBackdropLoader
                    display={drawdownSubmissionForm.isSubmitting || submitDrawdownMutations.isLoading}
                    text={t('submitting_your_drawdown')}
                  />
                </>
              )
            }
          </div>

        </BaseDrawer.Body>
      </BaseDrawer.Drawer>
      <ConfirmationModal
        variant="negative"
        title={t('are_you_sure')}
        open={openPaymentClearWarning}
        onExit={() => setOpenPaymentClearWarning(false)}
        onConfirm={() => {
          if (onPaymentClearWarningConfirmation) {
            onPaymentClearWarningConfirmation(warningModalConfirmationAction);
          }
          setOpenPaymentClearWarning(false);
        }}
        confirmButtonText={t('exit')}
        exitButtonText={t('cancel')}
        content={(
          <p>
            {t('you_are_about_to_abort_the_current_drawdown_flow')}
            <br />
            {t('the_drawdown_will_not_be_saved')}
            <br />
            <br />
            <br />
          </p>
        )}
      />
    </>
  );
};

export default CreateDrawdownDrawer;
