import React from 'react';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Box } from '@alpha/ui-lib/ui/external';

import DrawerBackdropLoader from '../../../components/Molecules/Loaders/DrawerBackdropLoader/DrawerBackdropLoader';
import { TStatementData } from '../../../models/statements';

import Body from './Body/Body';
import Header from './Header/Header';
import useStyles from './StatementsInfoDrawer.styles';

interface IStatementInfoDrawer {
  statementData?: TStatementData;
  loading?: boolean;
  displayFinishButton?: boolean;
  onClose: (event: any) => void;
}

const StatementInfoDrawer: React.FC<IStatementInfoDrawer> = ({
  onClose, statementData, displayFinishButton, loading,
}: IStatementInfoDrawer) => {
  const classes = useStyles();
  return (
    <BaseDrawer.Drawer
      className={classes.drawer}
      open={loading || Boolean(statementData)}
      onClose={onClose}
      anchor="right"
      closeIdentifier='statement-info-drawer'
    >
      {
        statementData && (
          <>
            <Header statement={statementData} />
            <Body statement={statementData} />
          </>
        )
      }
      {displayFinishButton
        && (
          <Box className={classes.finishButton}>
            <ActionButton
              style={{ background: '#F7F7F7', color: '#212529' }}
              fullWidth
              onClick={onClose}
              testId="cancelButton"
              size="medium"
            >
              Finish
            </ActionButton>
          </Box>
        )}
      <DrawerBackdropLoader display={Boolean(loading)} text="Retrieving your statement data" />
    </BaseDrawer.Drawer>
  );
};

export default StatementInfoDrawer;
