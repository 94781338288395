import React, { useEffect, useState } from 'react';
import useTransferApproval from 'hooks/useTransferApproval';
import t from 'utils/translationHelper';

import { CurrencyAccountTransferDto } from '@alpha/currency-accounts-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button/ActionButton';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs/StyledTabsWrapper';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import InvalidApprovalModal from '../InvalidApprovalModal';

import ApprovalSummary from './ApprovalSummary';
import useStyles from './index.styles';

type Props = {
  open: boolean,
  selectedTransfer: CurrencyAccountTransferDto,
  onClose: () => void,
}

const ApprovalDrawer: React.FC<Props> = ({ open, selectedTransfer, onClose }) => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const {
    nonFinalApprovers,
    finalApprovers,
    loading,
    failedTransfer,
    setFailedTransfer,
    setLoading,
    handleApprovals,
    onDrawerClose,
  } = useTransferApproval(selectedTransfer, onClose);

  const tabTitles: string[] = [`${t('further_approval_required')} (${nonFinalApprovers - finalApprovers})`, `${t('fully_approved')} (${finalApprovers})`];

  useEffect(() => {
    if (!nonFinalApprovers) {
      setTabIndex(1);
    }
  }, [selectedTransfer]);

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        className={styles.container}
        closeIdentifier='transfer-approval-drawer'
      >
        <BaseDrawer.Header
          headerTitle={t('transfer_approval')}
          headerSubtitle="1 Transfer"
        >
          <div className={styles.lineBreak}>
            <BaseDrawer.LineBreak />
          </div>
        </BaseDrawer.Header>
        <BaseDrawer.Body>
          <div className={styles.drawerBody}>
            <div>
              {selectedTransfer && (
                <div className={styles.mb16}>
                  <Typography>
                    <span className={styles.note}>
                      {t('note')}
                      {' '}
                      -
                      {' '}
                    </span>
                    {t('the_transfer_you_are_about_to_approve_will_have_a_different_status_afterwards')}
                  </Typography>
                </div>
              )}
              <StyledTabsWrapper
                testId="transfer-tabs-wrapper"
                tabTitles={tabTitles}
                tabIndex={tabIndex}
                handleChange={(selectedTabIndex: number) => setTabIndex(selectedTabIndex)}
              />
              <div className={styles.mt24}>
                {tabIndex === 0 && (<ApprovalSummary values={selectedTransfer} />)}
                {tabIndex === 1 && (<ApprovalSummary values={selectedTransfer} />)}
              </div>
            </div>
            <div className={styles.approvalRow}>
              <div className={styles.rowAlignment}>
                <BaseDrawer.LineBreak />
              </div>
              <ActionButton style={{ background: '#F7F7F7', color: '#212529', marginRight: '8px' }} onClick={onDrawerClose}>
                {t('cancel')}
              </ActionButton>
              <ActionButton size="medium" onClick={handleApprovals} disabled={loading} loading={loading}>
                {t('confirm_approval')}
              </ActionButton>
            </div>
          </div>
        </BaseDrawer.Body>
      </BaseDrawer.Drawer>

      {failedTransfer
        ? (
          <InvalidApprovalModal
            handleClose={() => {
              setFailedTransfer(undefined);
              setLoading(false);
            }}
            handleAcknowledge={() => {
              setFailedTransfer(undefined);
              setLoading(false);
              onClose();
            }}
            open={Boolean(failedTransfer)}
            transfer={failedTransfer}
          />
        )
        : ''}

    </>
  );
};

export default ApprovalDrawer;
