/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import useLog from 'hooks/useLog';
import useQueryString from 'hooks/useQueryString';
import i18n from 'i18n/config';
import { t } from 'i18next';

import { TradeStatus, TradeType } from '@alpha/fx-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { DateRangePicker } from '@alpha/ui-lib/ui/DatePicker';
import { Box } from '@alpha/ui-lib/ui/external';
import { MultiAutoCompleteSelect } from '@alpha/ui-lib/ui/Select';

import { TFilter } from '../FxTradeTable/FxTradeTable';
import { TSearchFilterTypes } from '../FxTradeTable/useFxTradeTable';
import useMenuStyles from '../MenuCustomStyle.styles';

import useTradeFilter from './useTradeFilter';

const useForwardTradeFilter = (setDisableFilterBackdropClick: React.Dispatch<React.SetStateAction<boolean>>,
  setFiltersByFieldForward: (filters: TSearchFilterTypes) => void,
  onChange? : ()=>void) => {
  const { logEvent, logError } = useLog();
  const menuClasses = useMenuStyles();
  const [forwardBuyCurrencies, setForwardBuyCurrencies] = useState<string[]>(
    [],
  );
  const [forwardSellCurrencies, setForwardSellCurrencies] = useState<string[]>(
    [],
  );
  const [forwardTradeTypes, setForwardTradeTypes] = useState<TradeType[]>([]);
  const [forwardTradeStatuses, setForwardTradeStatuses] = useState<TradeStatus[]>([]);
  const [forwardTradeStartDate, setForwardTradeStartDate] = useQueryString(
    'forwardTradeDateFrom',
    undefined,
  );
  const [forwardTradeEndDate, setForwardTradeEndDate] = useQueryString(
    'forwardTradeDateTo',
    undefined,
  );
  const [forwardValueStartDate, setForwardValueStartDate] = useQueryString(
    'forwardValueDateFrom',
    undefined,
  );
  const [forwardValueEndDate, setForwardValueEndDate] = useQueryString(
    'forwardValueDateTo',
    undefined,
  );
  const [forwardAccessDateStartDate, setForwardAccessDateStartDate] = useQueryString('forwardAccessDateFrom', undefined);
  const [forwardAccessDateEndDate, setForwardAccessDateEndDate] = useQueryString('forwardAccessDateTo', undefined);

  const [tradeFiltersForward, setTradeFiltersForward] = useState<TFilter[]>([]);

  useEffect(() => {
    setForwardTradeStatuses([TradeStatus.ACTIVE]);
  }, []);

  const tradeFilterForward = useTradeFilter({
    buyCurrencies: forwardBuyCurrencies,
    sellCurrencies: forwardSellCurrencies,
    tradeTypes: forwardTradeTypes,
    tradeStatuses: forwardTradeStatuses,
    tradeStartDate: forwardTradeStartDate,
    tradeEndDate: forwardTradeEndDate,
    valueStartDate: forwardValueStartDate,
    valueEndDate: forwardValueEndDate,
    accessDateStartDate: forwardAccessDateStartDate,
    accessDateEndDate: forwardAccessDateEndDate,
    setBuyCurrencies: setForwardBuyCurrencies,
    setSellCurrencies: setForwardSellCurrencies,
    setTradeTypes: setForwardTradeTypes,
    setTradeStatuses: setForwardTradeStatuses,
    setTradeStartDate: setForwardTradeStartDate,
    setTradeEndDate: setForwardTradeEndDate,
    setValueStartDate: setForwardValueStartDate,
    setValueEndDate: setForwardValueEndDate,
    setAccessDateStartDate: setForwardAccessDateStartDate,
    setAccessDateEndDate: setForwardAccessDateEndDate,
    setDisableFilterBackdropClick,
  }, 'forward');
  useEffect(() => {
    setTradeFiltersForward(
      parseForwardFilters(
        forwardBuyCurrencies,
        forwardSellCurrencies,
        forwardTradeTypes,
        forwardTradeStatuses,
        forwardTradeStartDate,
        forwardTradeEndDate,
        forwardValueStartDate,
        forwardValueEndDate,
        forwardAccessDateStartDate,
        forwardAccessDateEndDate,
      ),
    );
  }, [
    forwardBuyCurrencies,
    forwardSellCurrencies,
    forwardTradeTypes,
    forwardTradeStatuses,
    forwardTradeStartDate,
    forwardTradeEndDate,
    forwardValueStartDate,
    forwardValueEndDate,
    forwardAccessDateStartDate,
    forwardAccessDateEndDate,
  ]);

  const parseForwardFilters = (
    forwardBuyCurrenciesSelected: string[],
    forwardSellCurrenciesSelected: string[],
    forwardTradeTypesSelected: TradeType[],
    forwardTradeStatusesSelected: TradeStatus[],
    forwardTradeStartDateSelected: string | undefined,
    forwardTradeEndDateSelected: string | undefined,
    forwardValueStartDateSelected: string | undefined,
    forwardValueEndDateSelected: string | undefined,
    forwardAccessDateStartDateSelected: string | undefined,
    forwardAccessDateEndDateSelected: string | undefined,
  ) => {
    const result: TFilter[] = [];

    result.push(
      ...forwardBuyCurrenciesSelected.map((forwardBuyCurrency) => ({
        field: 'forwardBuyCurrency',
        value: forwardBuyCurrency as string,
        displayValue: 'Buy Currency',
      })),
    );
    result.push(
      ...forwardSellCurrenciesSelected.map((forwardSellCurrency) => ({
        field: 'forwardSellCurrency',
        value: forwardSellCurrency as string,
        displayValue: 'Sell Currency',
      })),
    );
    result.push(
      ...forwardTradeStatusesSelected.map((forwardTradeStatus) => ({
        field: 'forwardTradeStatus',
        value: forwardTradeStatus as string,
        displayValue: 'Trade Status',
      })),
    );
    result.push(
      ...forwardTradeTypesSelected.map((forwardTradeType) => ({
        field: 'forwardTradeType',
        value: forwardTradeType as string,
        displayValue: 'Trade Type',
      })),
    );
    if (forwardTradeStartDateSelected) {
      result.push({
        field: 'forwardTradeStartDate',
        value: forwardTradeStartDateSelected,
        displayValue: 'Trade Start Date',
      });
    }
    if (forwardTradeEndDateSelected) {
      result.push({
        field: 'forwardTradeEndDate',
        value: forwardTradeEndDateSelected,
        displayValue: 'Trade End Date',
      });
    }
    if (forwardValueStartDateSelected) {
      result.push({
        field: 'forwardValueStartDate',
        value: forwardValueStartDateSelected,
        displayValue: 'Value Start Date',
      });
    }
    if (forwardValueEndDateSelected) {
      result.push({
        field: 'forwardValueEndDate',
        value: forwardValueEndDateSelected,
        displayValue: 'Value End Date',
      });
    }
    if (forwardAccessDateStartDateSelected) {
      result.push({
        field: 'forwardAccessDateStartDate',
        value: forwardAccessDateStartDateSelected,
        displayValue: 'Access Start Date',
      });
    }
    if (forwardAccessDateEndDateSelected) {
      result.push({
        field: 'forwardAccessDateEndDate',
        value: forwardAccessDateEndDateSelected,
        displayValue: 'Access End Date',
      });
    }
    return result;
  };

  // Forward Trades
  const forwardTradeFilterMenuItems = () => [
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilterForward.mapCurrencyFilterOptions(
            tradeFilterForward.buyCurrenciesFilterOptions,
          )}
          label={t('buy_currencies')}
          noOptionsText={t('no_options')}
          placeholder={t('select_buy_currency')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{
            width: '100%',
          }}
          className={menuClasses.alphaInput}
          value={tradeFilterForward.buyCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilterForward.setBuyCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'buy-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilterForward.mapCurrencyFilterOptions(
            tradeFilterForward.soldCurrenciesFilterOptions,
          )}
          label={t('sell_currencies')}
          placeholder={t('select_sell_currencies')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilterForward.sellCurrenciesFilter || []}
          onChange={(_, value) => {
            tradeFilterForward.setSellCurrenciesFilter(value);
          }}
        />
      ),
      arrow: false,
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'sell-currency-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilterForward.mapTradeTypeFilterOptions(
            tradeFilterForward.typesFilterOptions,
            [
              TradeType.FORWARD_FIXED,
              TradeType.FORWARD_OPEN,
              TradeType.FORWARD_VARIABLE,
              TradeType.FORWARD_WINDOW,
            ],
          )}
          label={t('trade_type')}
          placeholder={t('select_trade_type_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilterForward.tradeTypeFilter || []}
          onChange={(_, value) => {
            tradeFilterForward.setTradeTypeFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'trade-type-options',
    },
    {
      content: (
        <MultiAutoCompleteSelect
          options={tradeFilterForward.mapTradeStatusFilterOptions(
            tradeFilterForward.statusesFilterOptions,
          )}
          label={t('trade_status')}
          placeholder={t('select_trade_status_to_filter')}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          style={{ width: '100%' }}
          className={menuClasses.alphaInput}
          value={tradeFilterForward.tradeStatusFilter || []}
          onChange={(_, value) => {
            tradeFilterForward.setTradeStatusFilter(value);
          }}
        />
      ),
      disableHoverEffect: true,
      disableClick: true,
      underline: false,
      key: 'trade-status-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('trade_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="trade-date-range"
          format="DD/MM/YYYY"
          onChange={tradeFilterForward.tradeDateChanged}
          className={menuClasses.alphaInput}
          value={
            tradeFilterForward.tradeDateFilter
              ? [...tradeFilterForward.tradeDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'trade-date-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('value_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="value-date-range"
          format="DD/MM/YYYY"
          onChange={tradeFilterForward.valueDateChanged}
          className={menuClasses.alphaInput}
          value={
            tradeFilterForward.valueDateFilter
              ? [...tradeFilterForward.valueDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'value-date-options',
    },
    {
      content: (
        <DateRangePicker
          label={t('access_date')}
          labelTo={t('to^')}
          labelFrom={t('from^')}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
          language={i18n.language}
          testId="access-date-date-range"
          format="DD/MM/YYYY"
          onChange={tradeFilterForward.accessDateChanged}
          className={menuClasses.alphaInput}
          value={
            tradeFilterForward.accessDateFilter
              ? [...tradeFilterForward.accessDateFilter]
              : [null, null]
          }
          staticRangeLabels={[
            t('today'),
            t('yesterday'),
            t('this_week'),
            t('last_week'),
            t('this_month'),
            t('last_month'),
          ]}
        />
      ),
      disableClick: true,
      disableHoverEffect: true,
      key: 'access-date-options',
    },
    {
      content: (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="flex-end"
          gridGap={10}
        >
          <ActionButton
            style={{ background: '#F7F7F7', color: '#212529' }}
            onClick={() => {
              tradeFilterForward.clearAllFilters();
              logEvent({ action: 'Click Clear Forward Trade Filters' });
            }}
          >
            {t('clear')}
          </ActionButton>
          <ActionButton
            onClick={() => {
              onChange?.();
              tradeFilterForward.applyChanges();
              tradeFilterForward.setOpen(null);
              logEvent({ action: 'Click Apply Forward Trade Filters' });
            }}
          >
            {t('apply')}
          </ActionButton>
        </Box>
      ),
      disableHoverEffect: true,
      disableClick: true,
      arrow: false,
      key: 'action-buttons',
    },
  ];

  // Forward Trades
  useEffect(() => {
    setTradeFiltersForward(
      parseForwardFilters(
        forwardBuyCurrencies,
        forwardSellCurrencies,
        forwardTradeTypes,
        forwardTradeStatuses,
        forwardTradeStartDate,
        forwardTradeEndDate,
        forwardValueStartDate,
        forwardValueEndDate,
        forwardAccessDateStartDate,
        forwardAccessDateEndDate,
      ),
    );

    setFiltersByFieldForward({
      buyCurrencies: forwardBuyCurrencies,
      sellCurrencies: forwardSellCurrencies,
      // Have default trade types if no trade types are selected
      tradeTypes: forwardTradeTypes?.length > 0 ? forwardTradeTypes : [
        TradeType.FORWARD_FIXED,
        TradeType.FORWARD_VARIABLE,
        TradeType.FORWARD_OPEN,
        TradeType.FORWARD_WINDOW,
      ],
      tradeStatuses: forwardTradeStatuses,
      tradeStartDate: forwardTradeStartDate,
      tradeEndDate: forwardTradeEndDate,
      valueStartDate: forwardValueStartDate,
      valueEndDate: forwardValueEndDate,
      accessDateStartDate: forwardAccessDateStartDate,
      accessDateEndDate: forwardAccessDateEndDate,
    });
  }, [
    forwardBuyCurrencies,
    forwardSellCurrencies,
    forwardTradeTypes,
    forwardTradeStatuses,
    forwardTradeStartDate,
    forwardTradeEndDate,
    forwardValueStartDate,
    forwardValueEndDate,
    forwardAccessDateStartDate,
    forwardAccessDateEndDate,
  ]);

  return {
    parseForwardFilters,
    forwardBuyCurrencies,
    setForwardBuyCurrencies,
    forwardSellCurrencies,
    setForwardSellCurrencies,
    forwardTradeTypes,
    setForwardTradeTypes,
    forwardTradeStatuses,
    setForwardTradeStatuses,
    forwardTradeStartDate,
    setForwardTradeStartDate,
    forwardTradeEndDate,
    setForwardTradeEndDate,
    forwardValueStartDate,
    setForwardValueStartDate,
    forwardValueEndDate,
    setForwardValueEndDate,
    forwardAccessDateStartDate,
    setForwardAccessDateStartDate,
    forwardAccessDateEndDate,
    setForwardAccessDateEndDate,
    tradeFiltersForward,
    setTradeFiltersForward,
    forwardTradeFilterMenuItems,
    tradeFilterForward,

  };
};

export default useForwardTradeFilter;
