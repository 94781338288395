import React from 'react';
import t from 'utils/translationHelper';

import { TradeType } from '@alpha/fx-dtos/lib/enums/TradeType';
import { Card } from '@alpha/ui-lib/ui/layout/Card';

import FxTradeTable, { TFilter } from '../FxTradeTable/FxTradeTable';
import { TSearchFilterTypes } from '../FxTradeTable/useFxTradeTable';

interface IProps {
  tradeFilters: TFilter[];
  filtersByField?: TSearchFilterTypes;
  handleRemoveFilterItem: (filterItem: TFilter) => void;
  fxLastUpdatedTime?: string;
  filterButton?: React.ReactNode,
}

const SpotTrade: React.FC<IProps> = ({
  tradeFilters,
  filtersByField,
  handleRemoveFilterItem,
  fxLastUpdatedTime,
  filterButton,
}: IProps) => (
  <>
    <Card>
      <FxTradeTable
        testId="spot-trade-table"
        filters={tradeFilters.filter((filter) => filter.field !== 'tradeType')}
        filtersByField={filtersByField}
        handleRemoveFilterItem={handleRemoveFilterItem}
        emptyTitle={t('no_spot_trades')}
        filterButton={filterButton}
        emptySubtitle={t('you_currently_do_not_have_any_matching_spot_trades')}
        lastUpdatedTime={fxLastUpdatedTime}
        tradeTypes={[TradeType.SPOT, TradeType.SPOT_OPTION]}
      />
    </Card>
  </>
);

export default SpotTrade;
