import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  () => ({
    container: (props: any) => ({
      position: 'relative',
      marginTop: props.marginTop ? props.marginTop : 0,
      borderRadius: '2px',
      '& > .icon': {
        height: '18px',
        width: '18px',
        background: props.backgroundColor,
        borderRadius: '2px',
        '& > img': {
          width: '18px',
          height: '18px',
          borderRadius: '2px',
        },
      },
    }),
  }),
  { name: 'IconBadge' },
);

export interface IIconBadgeProps {
  backgroundColor?: string;
  element: JSX.Element;
  className?: string;
  width?: string;
  height?: string;
  iconWidth?: string;
  marginTop?: string;
}

export const IconBadge: React.FC<IIconBadgeProps> = ({
  backgroundColor,
  element,
  className,
  width,
  height,
  marginTop,
  iconWidth,
}: IIconBadgeProps) => {
  const styles = useStyles({ backgroundColor, marginTop });
  return (
    <div
      className={clsx(styles.container, className)}
      style={{ backgroundColor, width, height }}
    >
      <div className="icon" style={{ width: iconWidth, height: iconWidth }}>
        {element}
      </div>
    </div>
  );
};

export default IconBadge;
