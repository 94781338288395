import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import moment from 'moment';
import t from 'utils/translationHelper';

import { PaymentStatus } from '@alpha/payments-dtos';
import { TDateRangePickerValue } from '@alpha/ui-lib/ui/DatePicker';
import { IAutocompleteSelectMultiOption } from '@alpha/ui-lib/ui/Select';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import PaymentsService from '../../../../services/Payments/payments.service';
import mapPaymentStatusDisplay from '../../../../utils/payments/mapPaymentStatusDisplay';

export interface IFxTableFilters {
  paymentCurrencies: string[],
  paymentStatuses: PaymentStatus[],
  paymentValueStartDate: string | undefined;
  paymentValueEndDate: string | undefined;
  setPaymentCurrencies: React.Dispatch<string[]>;
  setPaymentStatuses: React.Dispatch<PaymentStatus[]>;
  setPaymentValueStartDate: React.Dispatch<string | undefined>;
  setPaymentValueEndDate: React.Dispatch<string | undefined>;
  setDisableFilterBackdropClick: React.Dispatch<boolean>;
}

const useFilter = (props: IFxTableFilters) => {
  const {
    paymentCurrencies,
    paymentStatuses,
    paymentValueStartDate,
    paymentValueEndDate,
    setPaymentCurrencies,
    setPaymentStatuses,
    setPaymentValueStartDate,
    setPaymentValueEndDate,
    setDisableFilterBackdropClick,
  } = { ...props };
  

  const [paymentFilterOpen, setPaymentFilterOpen] = useState<null | HTMLElement>(null);
  const [paymentCurrenciesFilter,
    setPaymentCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [paymentStatusFilter,
    setPaymentStatusFilter] = useState<IAutocompleteSelectMultiOption[]>();

  const [paymentValueDateFilter, setPaymentValueDateFilter] = useState<TDateRangePickerValue>();

  const [paymentCurrenciesFilterOptions, setPaymentCurrenciesFilterOptions] = useState<string[]>(
    [],
  );
  const [paymentStatusesFilterOptions, setPaymentStatusesFilterOptions] = useState<PaymentStatus[]>(
    [],
  );

  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const paymentValueDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setPaymentValueDateFilter(value);
  };

  const updatePaymentFilterOptions = async () => {
    try {
      const filterOptions = await PaymentsService.getPaymentFilterOptions();
      if (filterOptions?.currencies) {
        setPaymentCurrenciesFilterOptions(filterOptions.currencies);
      }
      if (filterOptions?.statuses) {
        setPaymentStatusesFilterOptions(filterOptions.statuses);
      }
    } catch (e) {
      sb.trigger(e?.message || 'Could not load filter options.');
      logError({ action: 'Error loading filter options', error: e });
    }
  };

  useEffect(() => {
    updatePaymentFilterOptions();
  }, []);

  useEffect(() => {
    const startDate = moment(paymentValueStartDate, 'YYYY-MM-DD');
    const endDate = moment(paymentValueEndDate, 'YYYY-MM-DD');

    setPaymentValueDateFilter([
      startDate.isValid() ? startDate : null,
      endDate.isValid() ? endDate : null]);
  }, [paymentValueStartDate,
    paymentValueEndDate]);

  useEffect(() => {
    setPaymentCurrenciesFilter(mapCurrencyFilterOptions(paymentCurrencies));
  }, [
    paymentCurrencies,
  ]);

  useEffect(() => {
    setPaymentStatusFilter(mapPaymentStatusFilterOptions(paymentStatuses));
  }, [
    paymentStatuses,
  ]);

  const mapPaymentStatusFilterOptions = (options: PaymentStatus[]) => options.map((option) => (
    {
      value: option,
      label: t(startCase(mapPaymentStatusDisplay(option).text.toLowerCase())),
    }
  )).filter((option, index, self) => index === self.findIndex((o) => o.label === option.label));

  const mapCurrencyFilterOptions = (options: string[]) => options.map((option) => (
    { value: option, label: option }
  ));

  const clearAllFilters = () => {
    setPaymentCurrenciesFilter([]);
    setPaymentStatusFilter([]);
    setPaymentValueStartDate('');
    setPaymentValueEndDate('');
    setPaymentValueDateFilter(undefined);
  };

  const applyChanges = () => {
    setPaymentCurrencies(paymentCurrenciesFilter?.map((filter) => filter.value as string) || []);
    setPaymentStatuses(paymentStatusFilter?.map((filter) => filter.value as PaymentStatus) || []);
    if (paymentValueDateFilter) {
      setPaymentValueStartDate(paymentValueDateFilter[0]?.format('YYYY-MM-DD'));
      setPaymentValueEndDate(paymentValueDateFilter[1]?.format('YYYY-MM-DD'));
    }
  };

  return {
    paymentFilterOpen,
    setPaymentFilterOpen,
    clearAllFilters,
    applyChanges,
    paymentValueDateChanged,
    mapPaymentStatusFilterOptions,
    mapCurrencyFilterOptions,
    paymentCurrenciesFilterOptions,
    paymentCurrenciesFilter,
    setPaymentCurrenciesFilter,
    paymentStatusesFilterOptions,
    paymentStatusFilter,
    setPaymentStatusFilter,
    paymentValueDateFilter,
  };
};

export default useFilter;
