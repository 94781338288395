import React from 'react';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input/APInput';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitle from '../../../../components/Molecules/Auth/FormTitle';
import usePasswordReset from '../../../../hooks/usePasswordReset';
import routes from '../../../../routes.path';
import useFormStyles from '../../form.styles';

interface IProps {
  setUsername: React.Dispatch<React.SetStateAction<string | undefined>>
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Request: React.FC<IProps> = ({ setUsername, setEmail }: IProps) => {
  const { sendEmailFormik, loading } = usePasswordReset(undefined, setUsername, setEmail);
  const formClasses = useFormStyles();

  return (
    <div className={formClasses.wrapperDiv}>
      <FormTitle
        title={t(('password_reset'))}
        description={t('to_reset_your_password_please_enter_your_username_below')}
      />
      <form onSubmit={sendEmailFormik.handleSubmit}>
        <div className={formClasses.section}>
          <Typography className={formClasses.label}>{t('username')}</Typography>
          <APInput
            autoComplete="off"
            testid="username"
            id="username"
            name="username"
            placeholder={t('enter_your_username')}
            fullWidth
            variant="outlined"
            type="text"
            className={formClasses.inputFields}
            onChange={sendEmailFormik.handleChange}
            value={sendEmailFormik.values.username}
            error={sendEmailFormik.touched.username && Boolean(sendEmailFormik.errors.username)}
            helperText={sendEmailFormik.touched.username && sendEmailFormik.errors.username}
            autoFocus
          />
        </div>
        <ActionButton
          size="large"
          type="submit"
          testId="send-password-reset-email"
          fullWidth
          loading={loading}
          disabled={loading || !sendEmailFormik.isValid}
          style={{ marginTop: '24px' }}
        >
          {t('send_code')}
        </ActionButton>
        <ActionButton
          style={{
            backgroundColor: '#000000',
            color: '#FFFFFF',
            opacity: '50%',
            marginTop: '32px',
            height: '44px',
            width: '116px',
          }}
          startIcon={<FontAwesomeIcon icon={faChevronLeft as IconProp} style={{ marginRight: '15px' }} />}
          testId="trade-back-btn"
          onClick={() => browserHistory.push(routes.auth.login)}
        >
          {t('go_back')}
        </ActionButton>
      </form>
    </div>
  );
};

export default Request;
