import React, { useEffect, useState } from 'react';
import useQueryString from 'hooks/useQueryString';
import i18n from 'i18n/config';
import { NavLink } from 'react-router-dom';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { OTP } from '@alpha/ui-lib/ui/Input';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useCustomMultifactorAuth from '../../../hooks/useCustomMultifactorAuth';
import routes from '../../../routes.path';
import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

interface IProps extends IOnboarding, IReturning {
  mfaImage?: 'authy' | 'sms',
}

const onboardingDescription = (mfaImage?: 'authy' | 'sms', phoneNumber?: string): string | JSX.Element => {
  const description = t('finally_please_use_your_selected_authentication_method');

  return description;
};

const VerifyMfa: React.FC<IProps> = ({ onboarding, userObject, mfaImage }: IProps) => {
  const formClasses = useFormStyles();
  const [otp, setOtp] = useState('');

  const { formik, loading } = useCustomMultifactorAuth(userObject, onboarding, mfaImage, useQueryString('fromAlphaConnect', '')[0]);
  const { handleSetLoaderPercentage } = useOnboardingContext();

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(100);
    }
  }, []);

  const handleOTPChange = (newOtp) => {
    setOtp(newOtp);
  };

  useEffect(() => {
    if (otp.length === 6) {
      formik.setFieldValue('mfa', otp);
    }
  }, [otp]);

  return (
    <div className={formClasses.wrapperDiv}>
      <FormTitle
        marginTop={onboarding ? '0px' : '0px'}
        title={t('verify_your_mobile_device')}
        description={onboarding && mfaImage
          ? onboardingDescription(mfaImage, userObject.challengeParam?.CODE_DELIVERY_DESTINATION)
          : t('please_verify_your_mobile_device')}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className={formClasses.section} data-dd-privacy="hidden">
          <OTP numberOfDigits={6} onOTPChange={handleOTPChange} />
        </div>
        <ActionButton
          type="submit"
          testId="submit-mfa"
          fullWidth
          loading={loading}
          disabled={loading || otp.length !== 6}
          size="large"
          style={{ marginTop: '40px' }}
        >
          {t('authenticate')}
        </ActionButton>
        <div className={formClasses.formBacklink}>
          <NavLink
            to={onboarding ? routes.auth.onboarding.mfaOptions : routes.auth.mfaOptions}
            className={formClasses.formBacklink}
          >
            {t('havent_received_your_code_yet')}
          </NavLink>
          {
              !onboarding && (
                <ActionButton
                  style={{
                    backgroundColor: '#000000', color: '#FFFFFF', opacity: '50%', height: '44px', width: '116px',
                  }}
                  startIcon={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
                  testId="trade-back-btn"
                  onClick={() => browserHistory.push(routes.auth.login)}
                >
                  {t('go_back')}
                </ActionButton>
              )
            }
        </div>
      </form>
    </div>
  );
};

export default VerifyMfa;
