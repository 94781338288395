import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import t from 'utils/translationHelper';

import { MfaMethod } from '@alpha/profile-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots, faPhone } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthyIcon from '../../../assets/authyIcon.png';
import AuthBackupMethodCard from '../../../components/AuthBackupMethodCard';
import AuthSetupModal from '../../../components/AuthSetupModal';
import FormTitle from '../../../components/Molecules/Auth/FormTitle';
import useAuth from '../../../hooks/useAuth';
import useLog from '../../../hooks/useLog';
import { TSignIn } from '../../../models/auth';
import routes from '../../../routes.path';
import AuthService from '../../../services/Auth/auth.service';
import browserHistory from '../../../services/history/browserHistory';
import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { IOnboarding, useOnboardingContext } from '../Onboarding';
import { IReturning } from '../Returning';

interface IProps extends IOnboarding, IReturning {
  setup?: boolean,
  setMfaImage?: React.Dispatch<React.SetStateAction<'authy' | 'sms' | undefined>>,
}

const SetupMFA: React.FC<IProps> = ({
  onboarding, userObject, setup, setMfaImage,
}: IProps) => {
  const location = useLocation();
  const formClasses = useFormStyles();
  const mfaOptions = location.pathname.includes('mfa-options');

  const [showAuthyModal, setShowAuthyModal] = useState<boolean>(false);
  const [loadingCard, setLoadingCard] = useState<MfaMethod | false>(false);
  const [disableContinueBtn, setDisableContinueBtn] = useState<boolean>(true);
  const { challengeRedirect } = useAuth();
  const { logError } = useLog();
  const { enqueueSnackbar } = originalUseSnackbar();

  const { handleSetLoaderPercentage } = useOnboardingContext();

  const onboardingDescription = t('two_factor_authentication_must_be_enabled');
  const onboardingTitle = t('set_up_2fa');

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(75);
    }
  }, []);

  const handleTriggerMfa = async (methodRequest: MfaMethod): Promise<void> => {
    try {
      setLoadingCard(methodRequest);
      const res = await AuthService.sendCustomChallengeAnswer(userObject, methodRequest);
      challengeRedirect(res as TSignIn, enqueueSnackbar, methodRequest);
      browserHistory.push(onboarding ? routes.auth.onboarding.verifyMfa : routes.auth.totp);
    } catch (e) {
      const snackbarMessage = e.response?.data?.error || e.message === 'Invalid session for the user.'
        ? t('your_session_has_expired')
        : t('there_was_an_error_confirming_your_token');
      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      logError({ action: snackbarMessage, error: e });
    } finally {
      setLoadingCard(false);
    }
  };

  return (
    <div className={formClasses.wrapperDiv}>
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <FormTitle
          marginTop={onboarding ? '0px' : '0px'}
          title={(onboarding && setup) ? onboardingTitle : t('no_code_received')}
          description={(onboarding && setup) ? onboardingDescription : `${t('please_select_which_backup_method_you_would_like')}`}
        />
        <div
          onFocus={() => (setMfaImage && setMfaImage('authy'))}
          onMouseEnter={() => (setMfaImage && setMfaImage('authy'))}
        >
          <AuthBackupMethodCard
            loading={Boolean(loadingCard === MfaMethod.METHOD_AUTHY)}
            disabled={Boolean(loadingCard)}
            icon={{
              marginTop: '4px',
              element: <img src={AuthyIcon} alt="Authy icon" />,
            }}
            content={{
              title: 'Authy',
              body: t('download_the_authy_app'),
            }}
            handleOnClick={() => {
              setDisableContinueBtn(false);
              setShowAuthyModal(true);
            }}
            displaySecureBadge
          />
        </div>
        <div style={{ padding: '16px 0' }} />
        <div
          onFocus={() => (setMfaImage && setMfaImage('sms'))}
          onMouseEnter={() => (setMfaImage && setMfaImage('sms'))}
        >
          <AuthBackupMethodCard
            loading={Boolean(loadingCard === MfaMethod.METHOD_SMS)}
            disabled={Boolean(loadingCard)}
            icon={{
              backgroundColor: 'rgba(30, 135, 119, 1)',
              marginTop: '4px',
              height: '18px',
              element: <FontAwesomeIcon
                style={{
                  color: 'white',
                }}
                icon={faCommentAltDots as IconProp}
              />,
            }}
            content={{
              title: t('resend_sms'),
              body: t('receive_an_sms_to_your_registered_device'),
            }}
            handleOnClick={() => {
              setDisableContinueBtn(false);
              handleTriggerMfa(MfaMethod.METHOD_SMS);
            }}
          />
        </div>
        {
          (!onboarding || !setup)
          && (
            <>
              <div style={{ padding: '16px 0' }} />
              <AuthBackupMethodCard
                loading={Boolean(loadingCard === MfaMethod.METHOD_CALL)}
                disabled={Boolean(loadingCard)}
                icon={{
                  backgroundColor: 'rgba(62, 140, 193, 1)',
                  marginTop: '4px',
                  height: '18px',
                  element: <FontAwesomeIcon
                    icon={faPhone as IconProp}
                    style={{
                      color: 'white',
                    }}
                  />,
                }}
                content={{
                  title: t('call'),
                  body: t('receive_a_phone_call_to_your_registered_device1'),
                }}
                handleOnClick={() => {
                  setDisableContinueBtn(false);
                  handleTriggerMfa(MfaMethod.METHOD_CALL);
                }}
              />
            </>
          )
        }
        {onboarding && !mfaOptions ? (
          <ActionButton
            size="large"
            type="submit"
            testId="submit-login"
            disabled={disableContinueBtn}
            onClick={() => {
              handleSetLoaderPercentage(100);
              browserHistory.push(routes.auth.onboarding.verifyMfa);
            }}
            fullWidth
            style={{ marginTop: '44px' }}
          >
            {t('continue')}
          </ActionButton>
        ) : null}
        <AuthSetupModal
          open={showAuthyModal}
          phoneNumber={userObject.challengeParam?.CODE_DELIVERY_DESTINATION || ''}
          handleModalClose={() => setShowAuthyModal(false)}
          handleSuccessButtonClick={() => handleTriggerMfa(MfaMethod.METHOD_AUTHY)}
        />
        {
          (!onboarding || !setup)
          && (
          <ActionButton
            style={{
              backgroundColor: '#000000',
              color: '#FFFFFF',
              opacity: '50%',
              marginTop: '32px',
              height: '44px',
              width: '116px',
            }}
            startIcon={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
            testId="trade-back-btn"
            onClick={() => {
              onboarding ? browserHistory.push(routes.auth.onboarding.verifyMfa)
                : browserHistory.push(routes.auth.totp);
            }}
          >
            {t('go_back')}
          </ActionButton>
          )
        }
      </div>
    </div>
  );
};

export default SetupMFA;
