/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import CreateQuickDrawdownDrawer from 'components/Molecules/Drawdowns/CreateQuickDrawdownDrawer';
import FirstPartyCreateDrawdownDrawer from 'components/Molecules/Drawdowns/FirstPartyCreateDrawdownDrawer';
import CancelTradeModal from 'components/Molecules/Trade/CancelDraftTradeModal';
import AccountInfoDrawer from 'domain/CurrencyAccounts/AccountInfoDrawer/AccountInfoDrawer';
import useAuthorization from 'hooks/useAuthorization';
import { TCurrencyAccount, TCurrencyAccountDrawer, TCurrencyTab } from 'models/currencyAccounts';
import { UserRole } from 'models/user';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import {
  AccountConfigurationFXDto,
  DrawdownDto,
  TradeDto,
  TradeJournal,
  TradeStatus,
  TradeType,
} from '@alpha/fx-dtos';
import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import { APMainLayout } from '@alpha/ui-lib/ui/MainLayout';
import { StatSummary, TStatSummaryData } from '@alpha/ui-lib/ui/StatSummary';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateDrawdownDrawer from '../../../../../components/Molecules/Drawdowns/CreateDrawdownDrawer';
import RequestDrawdownModal from '../../../../../components/Molecules/Drawdowns/RequestDrawdownModal';
import BackdropLoader from '../../../../../components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import CreateSpotDrawer from '../../../../../components/Molecules/Trade/CreateSpotDrawer/index';
import useAlphaSnackbar from '../../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../../hooks/useLog';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../hooks/useReportsPolling';
import useSwitchAccount from '../../../../../hooks/useSwitchAccount';
import routes from '../../../../../routes.path';
import FXTradeService from '../../../../../services/FXTrade/fxTrade.service';
import { default as browserHistory, default as history } from '../../../../../services/history/browserHistory';
import { TStore } from '../../../../../store';
import { TAuthyState } from '../../../../../store/authy/reducer';
import formatIsoDate from '../../../../../utils/formatIsoDate';
import mapFxTradeStatusDisplay from '../../../../../utils/fxTrades/mapFxTradeStatusDisplay';
import mapTradeTypeToFriendlyName from '../../../../../utils/fxTrades/mapTradeTypeToFriendlyName';
import BeneficiaryInfoDrawer from '../../../BatchSummary/PaymentSummary/BeneficiaryInfoDrawer/BeneficiaryInfoDrawer';
import DrawdownInfoDrawer from '../DrawdownInfoDrawer/DrawdownInfoDrawer';

import CloseOuts from './CloseOuts/CloseOuts';
import Drawdowns from './Drawdowns/Drawdowns';
import PadEligible from './PadEligible/PadEligible';
import PaymentInfoDrawer from './Payments/PaymentInfoDrawer/PaymentInfoDrawer';
import Payments from './Payments/Payments';
import QuickDrawdown from './QuickDrawdown/QuickDrawdown';
import RightAlignedCta from './RightAlignedCta/RightAlignedCta';
import TradePadAuthoriseModal from './TradePadAuthoriseModal/TradePadAuthoriseModal';
import useStyles from './TradeDetail.styles';

// eslint-disable-next-line max-lines-per-function
const TradeDetail: React.FC = () => {
  const { homePageUrl, isEMoneyDisabled } = useSwitchAccount();
  const userId = useSelector<TStore, string | undefined>((store) => store.user.profileDetails?.id);

  const [loading, setLoading] = useState<boolean>(true);
  const [quickDrawdownLoading, setQuickDrawdownLoading] = useState<boolean>(true);
  const [drawdownLoading, setDrawdownLoading] = useState<boolean>(true);
  const [trade, setTrade] = useState<TradeDto>();
  const breadcrumbs = [{ text: t('home'), url: homePageUrl },
    { text: t('transactions'), url: routes.transactions.base },
    (trade?.type === TradeType.SPOT || trade?.type === TradeType.SPOT_OPTION) ? { text: t('spot_trades'), url: routes.transactions.spotTrades } : { text: t('forward_trades'), url: routes.transactions.forwardTrades },
  ];
  const [beneficiary, setBeneficiary] = useState<BeneficiaryDto>();
  const [tradeCloseOuts, setTradeCloseOuts] = useState<TradeJournal[]>([]);
  const [closeOutsLoading, setCloseOutsLoading] = useState<boolean>(true);
  const [tradeDrawdowns, setTradeDrawdowns] = useState<DrawdownDto[]>([]);
  const [tradePayments, setTradePayments] = useState<PaymentDto[]>([]);
  const [, setShowRecipient] = useState<boolean>(true);
  const [tradeSummary, setTradeSummary] = useState<TStatSummaryData>();
  const [selectedDrawdown, setSelectedDrawdown] = useState<DrawdownDto>();
  const [createDrawdown, setCreateDrawdown] = useState<boolean>(false);
  const [createFirstPartyDrawdown, setCreateFirstPartyDrawdown] = useState<boolean>(false);
  const [createQuickDrawdown, setCreateQuickDrawdown] = useState<boolean>(false);
  const [createSpot, setCreateSpot] = useState<boolean>(false);
  const [openAccountDrawer, setOpenAccountDrawer] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<TCurrencyAccountDrawer>();
  const [selectedAccount, setSelectedAccount] = useState<{
    tabs: TCurrencyTab,
    account: TCurrencyAccount
  }>();
  const [requestDrawdown, setRequestDrawdown] = useState<boolean>(false);
  const [authoriseTradePadOpen, setAuthoriseTradePadOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<string>();
  const [selectedPayment, setSelectedPayment] = useState<PaymentDto>();
  const { authorized: canCancelAnyTrade } = useAuthorization([[UserRole.SPOT]]);
  const { authorized: canCancelOwnTrade } = useAuthorization([[UserRole.SPOT_INPUTTER]]);
  const { authorized: drawdownInputter } = useAuthorization([[UserRole.DRAWDOWN_INPUTTER]]);
  const { authorized: firstPartyDrawdownInputter } = useAuthorization([[UserRole.FIRST_PARTY_DRAWDOWN_INPUTTER]]);
  const [tradeBooked, setTradeBooked] = useState<boolean>(false);

  const [firstPartyFlow, setFirstPartyFlow] = useState(false);

  const [accountConfiguration, setAccountConfiguration] = useState<AccountConfigurationFXDto>();

  const { tradeId } = useParams<{
    tradeId: string;
  }>();
  const classes = useStyles();
  const sb = useAlphaSnackbar();
  const { logError, logEvent } = useLog();
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);

  const loadTradeData = async (queryTradeId: string) => {
    try {
      const tradeData = await FXTradeService.getTradeData(queryTradeId);
      if (tradeData) {
        setTrade(tradeData);
        setLoading(false);
        setQuickDrawdownLoading(false);
      }
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_trade_details'));
      logError({ action: 'Error loading trade details', error });
      setLoading(false);
      setQuickDrawdownLoading(false);
    }
  };

  const sameOrAfterAccessDate = (): boolean => {
    if (trade) {
      const today = moment(new Date().toDateString()).format('YYYY-MM-DD');
      const accessDate = moment(trade?.accessDate).format('YYYY-MM-DD');
      return moment(today).isSameOrAfter(accessDate);
    }
    return true;
  };

  const isAfterValueDate = (): boolean => {
    if (trade) {
      const today = moment(new Date().toDateString()).format('YYYY-MM-DD');
      const valueDate = moment(trade?.valueDate).format('YYYY-MM-DD');
      return moment(today).isAfter(valueDate);
    }
    return true;
  };

  const loadTradeCloseOuts = async (queryTradeId: string) => {
    try {
      if (tradeCloseOuts.length === 0) { setCloseOutsLoading(true); }
      const closeOutsData = await FXTradeService.getTradeClouseOuts(queryTradeId);
      if (closeOutsData.length > 0) {
        setTradeCloseOuts(closeOutsData);
        setCloseOutsLoading(false);
      }
    } catch (error) {
      logError({ action: 'Trade close outs not found for trade', error, detail: { tradeId: queryTradeId } });
      setCloseOutsLoading(false);
    }
  };

  const loadTradePayments = async (queryTradeId: string) => {
    try {
      const paymentsData: PaymentDto[] = await FXTradeService.getTradePaymentsData(queryTradeId);
      if (paymentsData.length > 0) {
        setTradePayments(paymentsData);
      } else {
        setTradePayments([]);
      }
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_payments'));
      logError({ action: 'Error loading payments', error });
    }
  };

  const loadTradeDrawdowns = async (queryTradeId: string, openDrawdownIdDrawer?: string) => {
    try {
      if (tradeDrawdowns.length === 0) { setDrawdownLoading(true); }
      const drawdownsData: DrawdownDto[] = await FXTradeService.getDrawdownsData(queryTradeId);
      if (drawdownsData) {
        const sortedDrawdownsData = drawdownsData.sort(
          // eslint-disable-next-line no-nested-ternary
          (a, b) => (a.contractNumber < b.contractNumber
            ? -1
            : (a.contractNumber > b.contractNumber ? 1 : 0)),
        );
        setTradeDrawdowns(sortedDrawdownsData);

        if (openDrawdownIdDrawer) {
          setSelectedDrawdown(sortedDrawdownsData.find((dd) => (dd.id === openDrawdownIdDrawer)));
        }
      }
      setDrawdownLoading(false);
    } catch (error) {
      sb.trigger(error?.message || t('failed_to_load_drawdowns'));
      logError({ action: 'Error loading drawdowns', error });
      setDrawdownLoading(false);
    }
  };

  const reloadPageData = (delay: number, openDrawdown?: string) => {
    if (tradeId) {
      setTimeout(() => {
        loadTradeData(tradeId);
        loadTradeDrawdowns(tradeId, openDrawdown);
      }, delay);
    }
  };

  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setAccountConfiguration(result);
    } catch (error) {
      sb.trigger(error?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  useEffect(() => {
    reloadPageData(2000);
  }, [browserHistory.location]);

  useEffect(() => {
    if (
      authyState.type?.type === 'TRADE_PAD_APPROVE'
      && authyState.status === 'SUCCESS'
    ) {
      logEvent({ action: 'Successfully authorised PAD for trade' });
      reloadPageData(2000);
    }

    if (
      authyState.type?.type === 'DRAWDOWN_PAD_APPROVE'
      && authyState.status === 'SUCCESS'
    ) {
      sb.trigger(t('pad_authorised_for_drawdown'), 'success');
      logEvent({ action: 'Successfully authorised PAD for drawdown' });
      const currenctSelectedDrawdown = selectedDrawdown;
      setSelectedDrawdown(undefined);
      reloadPageData(2000, currenctSelectedDrawdown?.id);
    }
  }, [authyState.status, authyState.type]);

  const onClose = () => {
    setCancelModalOpen(false);
    if (trade?.type === TradeType.SPOT) {
      browserHistory.push(routes.transactions.spotTrades);
    } else {
      browserHistory.push(routes.transactions.forwardTrades);
    }
  };

  const mapTradeDtoToSummary = (tradeDto: TradeDto): TStatSummaryData => {
    const summaryData: TStatSummaryData = {
      tradeType: (tradeDto.type === TradeType.SPOT
        || tradeDto.type === TradeType.SPOT_OPTION) ? 'spot' : 'forward',
      tradeTypeFriendlyName: t(mapTradeTypeToFriendlyName(tradeDto.type)),
      tradeCurrencySell: tradeDto.soldCurrencyCode,
      tradeCurrencyBuy: tradeDto.buyCurrencyCode,
      buyAmount: tradeDto.buyAmount || 0,
      sellAmount: tradeDto.soldAmount || 0,
      rate: tradeDto.rate || 0,
      valueDate: formatIsoDate(tradeDto.valueDate),
      tradeDate: formatIsoDate(tradeDto.date),
      accessDate: formatIsoDate(tradeDto.accessDate),
      remainingBuyAmount: tradeDto.buyBalance || 0,
      remainingSellAmount: tradeDto.sellBalance || 0,
      tradeDateText: t('trade_date'),
      valueDateText: t('value_date'),
      accessDateText: t('access_date'),
      sellAmountText: t('sell_amount'),
      buyAmountText: t('buy_amount'),
      rateText: t('rate'),
      remainingSellBalanceText: t('remaining_sell_balance'),
      remainingBuyBalanceText: t('remaining_buy_balance'),
      currencyPairText: t('currency_pair'),
      tradeTypeText: t('trade_type'),
    };

    return summaryData;
  };

  const handleDrawdownContractNumberClick = (drawdown: DrawdownDto) => {
    setSelectedDrawdown(drawdown);
  };

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const handleContractDownload = async () => {
    if (trade) {
      await executeReportGeneration(
        FileType.PDF, trade.id, undefined, undefined, DownloadType.ContractNote,
      );
    }
  };

  const handleDrawdownPaymentContractNoteDownload = async (paymentId: string) => {
    if (paymentId) {
      await executeReportGeneration(
        FileType.PDF, paymentId, undefined, undefined, DownloadType.Summary,
      );
    }
  };

  const shouldDisableDrawdown = (): boolean => {
    if (trade) {
      const today = moment(new Date().toDateString()).format('YYYY-MM-DD');
      const accessDate = moment(trade?.accessDate).format('YYYY-MM-DD');
      return (
        moment(today).isBefore(accessDate)
      );
    }
    return true;
  };
  const handlePaymentContractNumberClick = (payment: PaymentDto) => {
    setSelectedPayment(payment);
  };

  const handleBeneficiaryClick = (beneficiaryId: string) => {
    setSelectedBeneficiaryId(beneficiaryId);
  };

  useEffect(() => {
    if (tradeId || tradeBooked) {
      loadTradeData(tradeId);
      loadTradeCloseOuts(tradeId);
    }
  }, [tradeId, tradeBooked]);

  useEffect(() => {
    if (trade) {
      if (trade.type !== TradeType.SPOT && trade.type !== TradeType.SPOT_OPTION) {
        loadTradeDrawdowns(tradeId);
      } else {
        loadTradePayments(tradeId);
      }
      setTradeSummary(mapTradeDtoToSummary(trade));
      setShowRecipient(trade.type !== TradeType.SPOT && trade.type !== TradeType.SPOT_OPTION);
    }
  }, [trade]);

  const getDefaultCurrencyAccount = async (
    currencyCode: string,
    tabs,
  ) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      currencyCode,
    );

    const account = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );
    setSelectedAccount({ tabs: { currencyCode }, account });
  };

  useEffect(() => {
    getDefaultCurrencyAccount(tradeSummary?.tradeCurrencySell, 0);
  }, [tradeSummary, tradeSummary?.tradeCurrencySell]);

  return (
    <APMainLayout
      height="165px"
      title={(
        <>
          <ActionButton
            startIcon={<FontAwesomeIcon icon={faArrowLeft as IconProp} />}
            testId="trade-back-btn"
            size="medium"
            onClick={() => {
              setTradeBooked(false);
              history.go(-1);
            }}
            style={{
              minWidth: '33px', minHeight: '33px', height: '33px', marginTop: '-5px', background: '#fdfdfd', color: '#212529',
            }}
          />
          {trade?.contractNumber || t('trade')}
          {trade?.status
            && (
              <Box className={classes.statusContainer}>
                <Status
                  className={classes.status}
                  variant={mapFxTradeStatusDisplay(trade?.status).variant}
                >
                  {t(mapFxTradeStatusDisplay(trade?.status).text)}
                </Status>
              </Box>
            )}
        </>
      )}
      sticky
      breadCrumbs={breadcrumbs}
      pageTabs={(
        <StyledTabsWrapper
          testId="trade-tabs"
          tabTitles={[t('summary')]}
          tabIndex={0}
          handleChange={() => { }}
        />
      )}
      rightAlignedCta={(
        loading ? <></> : (
          <RightAlignedCta
            handleOpenNewDrawdownDrawer={() => {
              setCreateDrawdown(true);
            }}
            handleOpenNewFirstPartyDrawdownDrawer={() => {
              setCreateFirstPartyDrawdown(true);
            }}
            handleOpenNewQuickDrawdownDrawer={() => {
              setCreateQuickDrawdown(true);
            }}
            handleOpenNewSpotDrawer={() => {
              setCreateSpot(true);
            }}
            handleContractDownload={handleContractDownload}
            handleOpenAccountDrawer={() => {
              setOpenAccountDrawer(true);
            }}
            showNewDrawdownButton={trade?.drawdownAvailable ?? false}
            hasDrawdownInputterRole={drawdownInputter}
            hasFirstPartyDrawdownInputterRole={firstPartyDrawdownInputter}
            eMoneyDisabled={isEMoneyDisabled()}
            shouldDisableDrawdown={!trade?.drawdownAvailable && shouldDisableDrawdown()}
            drawdownDisableReason={trade?.drawdownUnavailableReason}
            handleDrawdownRequest={() => { setRequestDrawdown(true); }}
            showRequestDrawdownButton={trade?.type === TradeType.FORWARD_VARIABLE
              && trade?.status === TradeStatus.ACTIVE
              && drawdownInputter
              && !isAfterValueDate()}
            showBookSpotButton={
              (trade?.status === TradeStatus.SUBMITTED || trade?.status === TradeStatus.VERIFIED) && (!tradeBooked)
            }
            showContractNoteButton={!(
              trade?.status === TradeStatus.SUBMITTED || trade?.status === TradeStatus.VERIFIED)}
            hasLinkedPayments={trade?.hasLinkedPayments}
            handleCancelTrade={() => { setCancelModalOpen(true); }}
            showCancelTradeButton={
              (trade?.status === TradeStatus.SUBMITTED || trade?.status === TradeStatus.VERIFIED)
              && ((trade.submittedBy === userId && canCancelOwnTrade) || canCancelAnyTrade)
            }
            trade={trade}
          />
        )
      )}
    >
      <div className={classes.contentWrapper}>
        {!loading || <BackdropLoader testId="loader-drawdowns" />}
        {!loading && tradeSummary && <StatSummary data={tradeSummary} testId="trade-summary" />}
        {!loading
          && (trade?.type === TradeType.FORWARD_OPEN || trade?.type === TradeType.FORWARD_WINDOW || trade?.type === TradeType.FORWARD_VARIABLE || trade?.type === TradeType.FORWARD_FIXED)
           && !isEMoneyDisabled() && (
           <QuickDrawdown
             tradeItem={trade}
             accountConfig={accountConfiguration}
             drawerStyle={false}
             setBeneficiary={setBeneficiary}
             selectedBeneficiary={beneficiary}
             setTrade={setTrade}
             quickDrawdownLoading={quickDrawdownLoading}
             setQuickDrawdownLoading={setQuickDrawdownLoading}
             isTrade
           />
        )}
        {!loading && trade?.padEligible && (
          <PadEligible
            padEligibleItem={trade}
            setAuthoriseTradePadOpen={setAuthoriseTradePadOpen}
            drawerStyle={false}
            isTrade
          />
        )}
        {!loading && (
          <Box className={classes.mainContainer}>
            {trade?.type !== TradeType.SPOT && trade?.type !== TradeType.SPOT_OPTION && (
              <Drawdowns
                tradeId={tradeId}
                drawdowns={tradeDrawdowns}
                handleDrawdownContractNumberClick={handleDrawdownContractNumberClick}
                loading={drawdownLoading}
              />
            )}
            {(trade?.type === TradeType.SPOT
              || trade?.type === TradeType.SPOT_OPTION) && (
                <Payments
                  tradeId={tradeId}
                  payments={tradePayments}
                  handlePaymentContractNumberClick={handlePaymentContractNumberClick}
                  handleBeneficiaryClick={handleBeneficiaryClick}
                  showExportButton={![TradeStatus.SUBMITTED,
                    TradeStatus.VERIFIED].includes(trade?.status)}
                />
            )}
          </Box>
        )}
        {!closeOutsLoading && accountConfiguration?.closeOutReportingEnabled && tradeCloseOuts?.length > 0 && (
          <Box className={classes.closeOutsContainer}>
            <CloseOuts
              closeouts={tradeCloseOuts}
              contractNumber={trade?.contractNumber || '-'}
              loading={closeOutsLoading}
            />
          </Box>
        )}
      </div>
      <BeneficiaryInfoDrawer
        paymentSummaryBeneficiaryId={selectedBeneficiaryId}
        onClose={() => setSelectedBeneficiaryId(undefined)}
        open={Boolean(selectedBeneficiaryId)}
        setPaymentSummaryBeneficiaryId={setSelectedBeneficiaryId}
      />
      <DrawdownInfoDrawer
        open={Boolean(selectedDrawdown)}
        selectedDrawdown={selectedDrawdown}
        handleClose={() => { setSelectedDrawdown(undefined); }}
        handleDrawdownPaymentContractNoteDownload={handleDrawdownPaymentContractNoteDownload}
      />
      <CreateDrawdownDrawer
        open={createDrawdown}
        onClose={() => setCreateDrawdown(false)}
        trade={trade!}
      />
      <FirstPartyCreateDrawdownDrawer
        open={createFirstPartyDrawdown}
        onClose={() => setCreateFirstPartyDrawdown(false)}
        trade={trade!}
        beneficiary={beneficiary}
      />
      <CreateQuickDrawdownDrawer
        open={createQuickDrawdown}
        onClose={() => setCreateQuickDrawdown(false)}
        trade={trade!}
        beneficiary={beneficiary}
      />
      <CreateSpotDrawer
        firstPartyFlow={firstPartyFlow}
        open={createSpot}
        onClose={() => setCreateSpot(false)}
        tradeDraft={trade}
        heading={firstPartyFlow ? t('first_party_spot') : t('new_spot_trade')}
        setTradeBooked={setTradeBooked}
      />
      <RequestDrawdownModal
        open={requestDrawdown}
        handleClose={() => setRequestDrawdown(false)}
      />
      <PaymentInfoDrawer
        paymentDrawerOpen={Boolean(selectedPayment)}
        selectedPayment={selectedPayment}
        setPaymentDrawerOpen={setSelectedPayment}
        testIds={{
          paymentDrawerBeneficiaryTable: 'trade-payment-info-drawer-bene',
          paymentDrawerPaymentTable: 'trade-payment-info-drawer-payment',
        }}
      />
      <TradePadAuthoriseModal
        open={authoriseTradePadOpen}
        handleClose={() => {
          setAuthoriseTradePadOpen(false);
        }}
        trade={trade}
      />
      {trade && (
        <CancelTradeModal
          open={cancelModalOpen}
          handleClose={() => { onClose(); }}
          trade={trade!}
        />
      )}
      <AccountInfoDrawer
        open={openAccountDrawer}
        selectedAccount={selectedAccount}
        eMoneyDisabled={isEMoneyDisabled()}
        tabIndex={0}
        handleOpenDrawer={() => {
          setOpenAccountDrawer(false);
        }}
      />
    </APMainLayout>
  );
};

export default TradeDetail;
