import React, { ReactNode } from 'react';
import i18n from 'i18n/config';
import t from 'utils/translationHelper';

import { TText } from '@alpha/ui-lib/interfaces';
import { makeStyles } from '@alpha/ui-lib/theme';
import { Chip } from '@alpha/ui-lib/ui/Chip';
import { Box } from '@alpha/ui-lib/ui/external';
import { TInputProps } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';

import SearchInput from '../../Inputs/Search/SearchInput';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '11px',
    paddingBottom: '11px',

    '& .borderBottom': {
      borderBottom: '2px solid rgba(112, 112, 112, 0.1)',
    },
    '& .left': {
      display: 'flex',
      alignItems: 'start',
      flexGrow: 1,
      gap: '10px',
      '& .inputWrapper': {
        width: '340px',
      },
      '& .totalItems': {
        display: 'inline-block',
        width: 'max-content',
        whiteSpace: 'nowrap',
        '& > h6': {
          fontWeight: 'bold',
          lineHeight: '50px',
          verticalAlign: 'center',
        },
      },
      '& .verticalLine': {
        display: 'block',
        height: '100%',
        border: '1px solid #F7F7F7',
        margin: '0 8px',
      },
    },
  },
  chips: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
}), { name: 'Search' });

export type TFilterChipData = {
  label: TText;
  field: string;
  value: string;
}

type TProps = {
  totalItems?: number;
  loading?: boolean;
  filters?: TFilterChipData[];
  handleRemoveFilter?: (item: TFilterChipData) => void;
  quickActions?: ReactNode | ReactNode[];
  hideVerticalDivider?: boolean;
} & TInputProps

const Search: React.FC<TProps> = (
  {
    totalItems, loading, filters, handleRemoveFilter, quickActions, hideVerticalDivider, ...rest
  }: TProps,
) => {
  const styles = useStyles();

  const removeFilterOption = (filterItem: TFilterChipData): void => {
    if (handleRemoveFilter) { handleRemoveFilter(filterItem); }
  };

  const generateChips = (): JSX.Element[] | undefined => filters?.map((item) => (
    <Chip
      className={styles.chips}
      onRemove={() => {
        removeFilterOption(item);
      }}
    >
      {item.label}
    </Chip>
  ));

  return (
    <div className={styles.root}>
      {quickActions ? (
        <Box display="flex" flexDirection="row" gridGap="10px" flexWrap="wrap">
          {quickActions}
        </Box>
      ) : <></>}
      {!hideVerticalDivider ? <div className="verticalLine" /> : <Box height="100%" width="10px" />}
      <div className="left">
        <Box display="flex" flexDirection="row" gridGap="10px" flexWrap="wrap" flexGrow={1} padding="8px">
          {filters && filters.length > 0 && (
            generateChips())}
        </Box>

        {(totalItems !== undefined && !loading) && (
          <>
            <div className="totalItems">
              <Typography variant="subtitle1">
                {() => {
                  switch (totalItems) {
                    case 0:
                      return t('no_results');
                    case 1:
                      return t('showing_1_item');
                    default:
                      return `${t('showing_all')} ${i18n.language === 'it' ? `(${totalItems})` : `${totalItems}`} ${t('items')}`;
                  }
                }}
                {(totalItems === 0) && t('no_results')}
                {(totalItems === 1) && t('showing_1_item')}
                {(totalItems > 1) && (
                  `${t('showing_all')} ${i18n.language === 'it' ? `(${totalItems})` : `${totalItems}`} ${t('items')}`
                )}
              </Typography>
            </div>
            <div className="verticalLine" />
          </>
        )}
        <SearchInput {...rest} className="inputWrapper" loading={loading} />

      </div>
    </div>
  );
};

export default Search;
