import { makeStyles } from '@alpha/ui-lib/theme';

import Authy from '../../../../assets/authyBackground.png';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    transition: '0.5s ease in out',
    opacity: 1,
    '& .assetImage': {
      width: '149px', height: '305px', marginBottom: '16px',
    },
    '& .assetTitle': {
      color: 'white', fontWeight: 100, fontSize: '18px', marginBottom: '16px',
    },
    '& .marginRight': {
      marginRight: '8px',
    },
  },
  phoneNumber: {
    marginTop: '16px',
    color: 'rgba(255, 255, 255, 0.75)',
    '& .MuiInputBase-input': {
      border: '1px solid #FFFFFF',
      borderRadius: '10px',
      color: '#FFFFFF',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22.4px',
    },
  },
  imgContainer: {
  },
  authySetup: {
    backgroundImage: `url(${Authy})`,
    width: '700px',
    height: 'calc(100vh - 64px)',
    minHeight: '715px',
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    borderRadius: '24px',
    maxHeight: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  authyContainer: {
    margin: '152px 74px 152px 74px',
    borderRadius: '16px',
    width: '552px',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    padding: '40px 40px 40px 40px',
    minHeight: '628px',
  },
  '@media (min-width: 1920px)': {
    imgContainer: {
    },
    authySetup: {
      width: '700px',
      maxHeight: 'calc(100vh - 80px)',
    },
  },
  '@media (max-width: 1024px) and (min-width: 768px)': {
    authySetup: {
      width: '100%',
      height: 'auto',
    },
  },
  fullOpacity: {
    opacity: 1,
  },
  title: {
    fontWeight: 200,
    fontSize: '22px',
    paddingBottom: '40px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '26.5px 65px 184.203px 24px',
    gap: '33.8px',
    width: '463.79px',
    height: '328.5px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  authyHelperTitles: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22.4px',
    color: 'rgba(255, 255, 255, 1)',
    margin: '2px 0px 8px 0px',
  },
  authyHelperSubTitles: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18.2px',
    color: 'rgba(255, 255, 255, 1)',
    width: '443px',
  },
  content: {
    fontWeight: 400,
    fontSize: '14px',
    width: '343px',
    height: '48px',
  },
}), { name: 'VerifyHelperScreen' });

export default useStyles;
