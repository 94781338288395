import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  ({ palette }) => ({
    container: (props: any) => ({
      width: '100%',
      boxSizing: 'content-box',
      padding: '0px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '5px',
      outline: '1px solid rgba(255, 255, 255, 0.25)',
      '& .MuiButton-label': {
        height: 'inherit',
        display: 'unset',
        width: '100%',
        padding: '16px 10px 10px 25px',
      },
      '&.MuiButtonBase-root:hover': {
        backgroundColor: 'rgba(0, 184, 163, 0.1)',
        outline: '1px solid rgba(9, 131, 117, 1)',
      },
    }),
    contentWrapper: {
      width: 'calc(100% - 86px)',
      padding: '0px 0px 0px 10px',
      marginBottom: '10px',
    },
    title: {
      color: '#FFFFFF',
      textAlign: 'left',
      fontSize: '18px',
      marginBottom: '8px',
      textTransform: 'capitalize',
      marginLeft: '8px',
    },
    body: {
      '& p': {
        textTransform: 'none',
        textAlign: 'left',
        color: 'rgba(255, 255, 255, 0.5)',
        fontSize: '11px',
        fontWeight: 400,
        marginBottom: '8px',
        lineHeight: '24px',
      },
    },
    badge: {
      zIndex: 2,
      height: '29px',
      display: 'flex',
      flexDirection: 'row',
      textTransform: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: palette.primary.main,
      borderRadius: '6px',
      padding: '8px',
      color: 'white',
      position: 'absolute',
      marginRight: '16px',
      top: '34px',
      right: '0px',
      transform: 'translateY(-50%)',
      '& h6': {
        marginLeft: '8px',
        fontWeight: 600,
        fontSize: '14px',
      },
    },
    cursor: {
      cursor: 'default',
    },
    loadingState: {
      zIndex: 1,
      position: 'absolute',
      width: 558,
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }),
  { name: 'AuthBackupMethodCard' },
);

export default useStyles;
