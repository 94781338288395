import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import t from 'utils/translationHelper';

import { TradeDto } from '@alpha/fx-dtos';
import { PaymentDto } from '@alpha/payments-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table';

import useReportsPolling, { DownloadType, FileType, PageType } from '../../../../../../../hooks/useReportsPolling';
import { getIndividualBeneficiary } from '../../../../../../../services/Beneficiaries/beneficiaries.service';
import FXTradeService from '../../../../../../../services/FXTrade/fxTrade.service';
import formatIsoDate from '../../../../../../../utils/formatIsoDate';
import mapPaymentStatusDisplay from '../../../../../../../utils/payments/mapPaymentStatusDisplay';
import AssociatedTrade from '../../../../../PaymentInfoDrawer/AssociatedTrade';

import Header from './Header/Index';

interface IPaymentInfoDrawer {
  sharedStyles?: any;
  testIds: {
    paymentDrawerBeneficiaryTable: string;
    paymentDrawerPaymentTable: string;
  };
  paymentDrawerOpen: boolean;
  setPaymentDrawerOpen: Function;
  selectedPayment?: PaymentDto;
}

export const PaymentInfoDrawer: React.FC<IPaymentInfoDrawer> = ({
  sharedStyles: classes, testIds, paymentDrawerOpen, setPaymentDrawerOpen, selectedPayment,
}: IPaymentInfoDrawer) => {
  const getBeneficiary = useMutation('getBeneficiary', getIndividualBeneficiary);
  const [paymentTrade, setPaymentTrade] = useState<TradeDto>();
  const [tradeLoading, setTradeLoading] = useState<boolean>(false);

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const handleContractDownload = async (tradeId: string) => {
    await executeReportGeneration(
      FileType.PDF, tradeId, undefined, undefined, DownloadType.ContractNote,
    );
  };

  const loadTradeData = async (queryTradeId: string) => {
    try {
      setTradeLoading(true);
      const tradeData = await FXTradeService.getTradeData(queryTradeId);
      if (tradeData) {
        setPaymentTrade(tradeData);
        setTradeLoading(false);
      }
    } catch (e) {
      setTradeLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPayment?.tradeId) {
      loadTradeData(selectedPayment?.tradeId);
    }
  }, [selectedPayment?.tradeId]);

  useEffect(() => {
    if (selectedPayment?.beneficiaryId) {
      getBeneficiary.mutate(selectedPayment.beneficiaryId);
    }
  }, [selectedPayment?.beneficiaryId]);

  const paymentDrawerPaymentData = [
    {
      key: t('payment_status'),
      value: selectedPayment && t(mapPaymentStatusDisplay(selectedPayment.status).text),
      statusValue: true,
      statusVariant: selectedPayment
        && mapPaymentStatusDisplay(selectedPayment?.status).variant,
    },
    { key: t('beneficiary_name'), value: selectedPayment && selectedPayment.beneficiaryName, boldValue: true },
    { key: t('beneficiary_bank'), value: getBeneficiary.data && (getBeneficiary.data.bankName || '-') },
    { key: t('swift/bic'), value: getBeneficiary.data && (getBeneficiary.data.swift || '-') },
    { key: t('iban'), value: getBeneficiary.data && (getBeneficiary.data.iban || '-') },
    { key: t('payment_date~'), value: selectedPayment && formatIsoDate(selectedPayment.valueDate) },
    { key: t('payment_reference'), value: selectedPayment && selectedPayment.reference },
    {
      key: t('payment_amount'),
      value: selectedPayment && selectedPayment.amount,
      boldKey: true,
      boldValue: true,
      currency: selectedPayment && selectedPayment.debitingCurrency,
    },
  ];

  return (
    <BaseDrawer.Drawer
      open={paymentDrawerOpen}
      onClose={() => {
        setPaymentDrawerOpen(false);
      }}
      anchor="right"
      closeIdentifier='trade-detail-payment-info-drawer'
    >
      <Header
        selectedPayment={selectedPayment}
        viewOnly={false}
      />
      {selectedPayment
        && (
          <BaseDrawer.Body>
            <>
              <StyledKeyValueTable
                testId={testIds.paymentDrawerPaymentTable}
                data={paymentDrawerPaymentData}
                title={t('payment_details')}
              />
              {selectedPayment.tradeId
                && (
                  <>
                    <h4 style={{ marginTop: '60px' }}>
                      {t('associated_trade')}
                    </h4>
                    <AssociatedTrade
                      loading={tradeLoading}
                      trade={paymentTrade}
                      handlePaymentTradeContractNoteDownload={
                        handleContractDownload
                      }
                    />
                  </>
                )}
            </>
          </BaseDrawer.Body>
        )}
    </BaseDrawer.Drawer>
  );
};

export default PaymentInfoDrawer;
