import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import clsx from 'clsx';
import styles from 'components/AuthBackupMethodCard/styles';
import environmentVars from 'env.variables';
import i18n from 'i18n/config';
import { CMSContent } from 'models/cms';
import {
  Route, Switch, useLocation,
} from 'react-router-dom';
import HubCMSService from 'services/HubCMS/HubCMS.service';

import { CarouselWidget } from '@alpha/ui-lib/ui/Carousel/CarouselWidget';
import { datadogLogs } from '@datadog/browser-logs';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlphaGreenLogo from '../../../assets/AlphaGreenLogo.png';
import AuthLayout from '../../../components/Layout/Auth';
import routes from '../../../routes.path';
import LangSelectorGroup from '../LangSelectorGroup';
import Login from '../Login';
import VerifyMfaHelperScreen from '../VerifyMfa/HelperScreen';
import { IAuthProps } from '..';

import useStyles from './index.styles';
import PostOnboardingLogin from './PostOnboardingLogin';

export interface IOnboarding {
  onboarding?: boolean
}

type TLoaderSteps = 0 | 25 | 50 | 75 | 100;

type TOnboardingContext = {
  loaderPercentage?: number,
  handleSetLoaderPercentage?: (loaderPercentage: TLoaderSteps) => void,
}

const OnboardingContext = createContext<TOnboardingContext>({
  loaderPercentage: 0,
});

export const useOnboardingContext = () => {
  const { loaderPercentage, handleSetLoaderPercentage } = useContext(OnboardingContext);
  return {
    loaderPercentage,
    handleSetLoaderPercentage,
  };
};

const Onboarding: React.FC<IAuthProps> = ({ userObject, userInfo }: IAuthProps) => {
  const classes = useStyles();

  function generateStepAndDescription(
    loaderPercentage: TLoaderSteps,
  ) {
    return {
      0: {
        step: '1/4',
      },
      25: {
        step: '1/4',
      },
      50: {
        step: '2/4',
      },
      75: {
        step: '3/4',
      },
      100: {
        step: '4/4',
      },
    }[loaderPercentage];
  }

  const [loaderPercentage, setLoaderPercentage] = useState<TLoaderSteps>(0);
  const [stepText, setStepText] = useState(generateStepAndDescription(loaderPercentage));
  const [mfaImage, setMfaImage] = useState<'authy' | 'sms' | undefined>();

  const { pathname } = useLocation();
  const mfaOptions = pathname.includes('mfa-options');

  useEffect(() => {
    setStepText(generateStepAndDescription(loaderPercentage));
  }, [loaderPercentage]);

  function handleSetLoaderPercentage(number: TLoaderSteps) {
    setLoaderPercentage(number);
  }
  const onboarded = sessionStorage.getItem(`onboarded-${environmentVars.ENVIRONMENT}`) ?? 'false';
  const location = useLocation();

  const showLangSelector = !(location?.pathname.includes('mfa-options'));

  const [adverts, setAdverts] = useState<CMSContent[]>([]);
  const [data, setData] = useState<CMSContent[]>([]);

  const getAdverts = async () => {
    try {
      const data = await HubCMSService.getCMSItems('ONBOARDING', i18n.language);
      setData(data);
    } catch (error) {
      datadogLogs.logger.error(error);
    }
  };

  useEffect(() => {
    getAdverts();
  }, []);

  useEffect(() => {
    if (data && data?.contents) {
      const cmsItems = data?.contents
        .filter((item) => item?.fileUrl)
        .map((item) => ({
          href: item?.href || null,
          fileUrl: item?.fileUrl,
        })) || [];
      setAdverts(cmsItems);
    }
  }, [data]);

  return (
    <OnboardingContext.Provider value={{ loaderPercentage, handleSetLoaderPercentage }}>
      <div style={{ minWidth: '100%', margin: 'auto', background: '#000000' }}>
        <AuthLayout.Container>
          <AuthLayout.Header className={classes.header} />
          <AuthLayout.LeftContent>
            <div style={{ display: 'flex', marginTop: '85px' }}>
              <img
                className={classes.alphaLogo}
                src={AlphaGreenLogo}
                alt="Alpha Group Logo"
              />
            </div>
            <div>
              {!mfaOptions && (
              <div style={{ fontSize: '13px', color: 'rgba(255, 255, 255, 1)', fontWeight: 400 }}>{stepText.step}</div>)}
              <Switch>
                <Route exact path={routes.auth.onboarding.login}>
                  <Login onboarding={onboarded !== 'true'} />
                </Route>
                <PostOnboardingLogin
                  userObject={userObject}
                  userInfo={userInfo}
                  mfaImage={mfaImage}
                  setMfaImage={setMfaImage}
                  onboarded={onboarded === 'true'}
                />
              </Switch>
            </div>
            {showLangSelector ? (
              <div>
                <div style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: 'auto',
                  alignItems: 'center',
                  marginBottom: '32px',
                }}
                >

                  <LangSelectorGroup />
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <a style={{ fontWeight: 400, fontSize: '13px' }} target="_blank" href="https://www.alphagroup.com" rel="noreferrer">
                      Alpha Group
                    </a>
                    <FontAwesomeIcon icon={faExternalLink} color="#00B8A3" style={{ marginLeft: '10px' }} />
                  </div>
                </div>
              </div>
            ) : <div />}
          </AuthLayout.LeftContent>
          <AuthLayout.RightContent
            className={clsx(pathname === routes.auth.onboarding.verifyMfa && classes.rightContent)}
          >
            <Switch>
              <Route exact path={routes.auth.onboarding.login}>
                <div className={classes.container}>
                  {adverts?.length > 0 ? (
                    <CarouselWidget
                      images={adverts}
                      width="700px"
                      autoPlayInterval={6000}
                    />
                  ) : <div className={classes.alphaLion} /> }
                </div>
              </Route>
              <Route exact path={routes.auth.onboarding.newPassword}>
                <div className={classes.container}>
                  {adverts?.length > 0 ? (
                    <CarouselWidget
                      images={adverts}
                      width="700px"
                      autoPlayInterval={6000}
                    />
                  ) : (
                    <div className={classes.alphaLion} />
                  )}
                </div>
              </Route>
              <Route exact path={routes.auth.onboarding.setupMfa}>
                <div className={classes.container}>
                  <VerifyMfaHelperScreen userObject={userObject} />
                </div>
              </Route>
              <Route exact path={routes.auth.onboarding.mfaOptions}>
                <div className={classes.container}>
                  <VerifyMfaHelperScreen userObject={userObject} />
                </div>
              </Route>
              <Route exact path={routes.auth.onboarding.verifyMfa}>
                <div className={classes.container}>
                  <VerifyMfaHelperScreen userObject={userObject} />
                </div>
              </Route>
            </Switch>
          </AuthLayout.RightContent>
        </AuthLayout.Container>
      </div>
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
