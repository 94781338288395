import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(
  (theme) => ({
    tabContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      '& > div': {
        width: '100%',
      },
    },
    searchBar: {
      display: 'flex',
    },
    underlinedGreenText: {
      textDecoration: 'underline',
      fontSize: '15px',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    emptyTable: {
      fontWeight: 100,
      '& .image': {
        height: '120px',
        width: '120px',
        '& img': {
          height: '60',
          width: '60px',
        },
      },
    },
    spinner: {
      color: '#3E8CC1',
    },
  }),
  { name: 'Body' },
);

export default useStyles;
