import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  container: {
    background: '#000000',
    backgroundSize: 'cover',
    minHeight: '100vh',
    // minWidth: '100%',
    margin: 'auto',
    overflow: 'auto',
    maxWidth: '1524px',
    '& > .wrapper': {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      justifyContent: 'space-between',
    },
  },
  alert: {
    '& .closeButton': {
      color: '#00B8A3 !important',
    },
    borderRadius: '0px',
    padding: '0px 32px 0px 32px !important',
    backgroundColor: '#252525 !important',
    marginBottom: '0px',
    '&.info': {
      '& .text': {
        color: '#FFFFFF',

      },
    },
  },
  header: {
    display: 'flex',
    boxSizing: 'border-box',
    width: '84%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 1,
  },
  leftContent: (props: any) => ({
    position: 'relative',
    display: 'flex',
    backgroundColor: '#000000',
    flexDirection: 'column',
    width: '50%',
    maxWidth: '700px',
    alignItems: 'end',
    '& > .wrapper': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '548px',
      justifyContent: 'space-between',
    },
  }),
  '@media (max-width: 1024px) and (min-width: 768px)': {
    leftContent: {
      alignItems: 'unset',
      width: '100%',
    },
  },
  '@media (max-width: 820px)': {
    container: {
      '& > .wrapper': {
        flexDirection: 'column',
        justifyContent: 'unset',
      },
    },
    leftContent: {
      width: '100% !important',
      minHeight: '100vh',
      alignItems: 'center !important',
      maxWidth: '100% !important',
    },
  },
  rightContent: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    '& > .wrapper': {
    },
  },
}), { name: 'AuthLayoutStylesl' });

export default useStyles;
