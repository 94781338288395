import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountSettingsService from 'services/AccountSettings/accountSettings.service';
import { TStore } from 'store';
import { Userpilot } from 'userpilot';
import t from 'utils/translationHelper';

import { MfaMethod } from '@alpha/profile-dtos';
import { Box, jssPreset } from '@alpha/ui-lib/ui/external';

import Alert from '../../components/Alert';
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';
import useSwitchAccount from '../../hooks/useSwitchAccount';
import { actions } from '../../store/user/user.reducer';

import useStyles from './index.styles';
import QuickActions from './QuickActions';
import useDashboard from './useDashboard';
import WidgetPanel from './WidgetPanel';

const Dashboard: React.FC = () => {
  const classes = useStyles();

  const { dashboardSettings, setDashboardSettings, handleRemoveWidget } = useDashboard();
  const { userProfile, updateUserProfile } = useProfile();
  const [viewablePages, setViewablePages] = useState<string[]>([]);
  const [displayAlert, setDisplayAlert] = useState(false);
  const { loggedIn, userInfo } = useAuth();
  const {
    currentPagesConfiguration,
    getPortalPagesConfiguration,
  } = useSwitchAccount();
  const dispatch = useDispatch();

  const preferredAuthMethod = useSelector<TStore, MfaMethod | undefined>((store) => store.user.preferredAuthMethod);
  const langPreference = localStorage.getItem('i18nextLng');

  const currentMfaMethod = userProfile?.mfaMethod;

  const handleOnClose = () => {
    setDisplayAlert(false);
    dispatch(actions.clearPreferredMfaMethod());
  };

  const getUserPermissions = async (accountId: string) => {
    const allAccPerms = await AccountSettingsService.getUserAccountRoles();

    return {
      roles: allAccPerms.find((acc) => acc.id === accountId)?.roles,
      entityStamp: allAccPerms.find((acc) => acc.id === accountId)?.entityStamp,
    };
  };

  useEffect(() => {
    if (loggedIn) {
      updateUserProfile();
    }
    if (preferredAuthMethod !== undefined
      && currentMfaMethod !== undefined
      && (currentMfaMethod !== preferredAuthMethod)) {
      setDisplayAlert(true);
    }
  }, [currentMfaMethod, preferredAuthMethod]);

  useEffect(() => {
    (async () => {
      await getPortalPagesConfiguration();
      setViewablePages(currentPagesConfiguration()?.viewablePages as string[]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const accInfo = localStorage.getItem('account-info');
      const userId = userProfile?.id;
      if (accInfo && userId) {
        const accId = JSON.parse(accInfo).id;
        const id = `${userId}-${accId}`;
        const { roles, entityStamp } = await getUserPermissions(accId);

        Userpilot.identify(id, {
          company: {
            id: accId,
            name: JSON.parse(accInfo).name,
          },
          name: userInfo?.Name,
          email: userInfo?.Email,
          created_at: new Date().toISOString(),
          spotInputter: roles?.find((perm) => perm === 'Spot Inputter'),
          spot: roles?.find((perm) => perm === 'Spot'),
          forwardInputter: roles?.find((perm) => perm === 'Forward Inputter'),
          beneficiaryInputter: roles?.find((perm) => perm === 'Beneficiary Inputter'),
          beneficiaryApproverOwn: roles?.find((perm) => perm === 'Beneficiary Approver Own'),
          beneficiaryApprover: roles?.find((perm) => perm === 'Beneficiary Approver'),
          drawdownInputter: roles?.find((perm) => perm === 'Drawdown Inputter'),
          paymentsInputter: roles?.find((perm) => perm === 'Payments Inputter'),
          paymentsApproverOwn: roles?.find((perm) => perm === 'Payments Approver Own'),
          paymentsApprover: roles?.find((perm) => perm === 'Payments Approver'),
          paymentFileInputter: roles?.find((perm) => perm === 'Payment File Inputter'),
          readOnlyReporting: roles?.find((perm) => perm === 'Read Only / Reporting'),
          firstPartyDrawdownInputter: roles?.find((perm) => perm === 'First Party Drawdown Inputter'),
          iatInputter: roles?.find((perm) => perm === 'IAT Inputter'),
          iatApprover: roles?.find((perm) => perm === 'IAT Approver'),
          iatApproverOwn: roles?.find((perm) => perm === 'IAT Approver Own'),
          padApprover: roles?.find((perm) => perm === 'PAD Approver'),
          userInputter: roles?.find((perm) => perm === 'User Inputter'),
          userPermissionManager: roles?.find((perm) => perm === 'User Permission Manager'),
          userEmailNotificationManager: roles?.find((perm) => perm === 'User Email Notification Manager'),
          alphaConnectLogin: roles?.find((perm) => perm === 'Alpha Connect Login'),
          creditAdmin: roles?.find((perm) => perm === 'Credit Admin'),
          preferredLanguage: langPreference,
          entityStamp,
        });
      }
    })();
  }, []);

  return (
    <Box className={classes.container}>
      <QuickActions
        dashboardSettings={dashboardSettings}
        setDashboardSettings={setDashboardSettings}
        viewablePages={viewablePages}
      />
      {displayAlert ? (
        <div className={classes.alertContainer}>
          <Alert
            showCloseButton
            onClose={handleOnClose}
            className={classes.alert}
            variant="info"
            text={t('we_noticed_you_used_a_different_method_to_login')}
          />
        </div>
      ) : null}
      <WidgetPanel
        dashboardSettings={dashboardSettings}
        viewablePages={viewablePages}
        handleRemoveWidget={handleRemoveWidget}
      />
    </Box>
  );
};

export default Dashboard;
