import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import t from 'utils/translationHelper';

import {
  PaymentDto, PaymentStatus,
} from '@alpha/payments-dtos';
import { IBreadcrumbItem } from '@alpha/ui-lib/ui/Breadcrumbs';

import useAlphaSnackbar from '../../../hooks/useAlphaSnackbar';
import useReportsPolling, { DownloadType, FileType, PageType } from '../../../hooks/useReportsPolling';
import useSwitchAccount from '../../../hooks/useSwitchAccount';
import { PaymentRouteEnum } from '../../../models/payments';
import routes from '../../../routes.path';
import browserHistory from '../../../services/history/browserHistory';
import PaymentsService from '../../../services/Payments/payments.service';
import { TStore } from '../../../store';
import { TAuthyStatus } from '../../../store/authy/reducer';

const useBatchSummary = () => {
  const snackbar = useAlphaSnackbar();
  const { executeReportGeneration } = useReportsPolling(PageType.Payments);
  const location = useLocation();
  const authyStatus = useSelector<TStore, TAuthyStatus | undefined>(
    (store) => store.authy.status,
  );
  const [batchDetails, setBatchDetails] = useState<PaymentDto>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const handleSetSelectedTabIndex = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };
  const { batchId, state } = useParams<{
        batchId: string;
        state: PaymentRouteEnum;
    }>();
  const { homePageUrl } = useSwitchAccount();

  const handleBreadCrumbs = (): IBreadcrumbItem => {
    const paymentsBreadcrumbs: IBreadcrumbItem = { text: t('transactions'), url: routes.transactions.base };
    if (
      location.pathname
        .includes(routes.transactions.batchSummaryRouteFunc(batchId, PaymentRouteEnum.FX_TRADES))) {
      paymentsBreadcrumbs.text = t('spot_trades');
      paymentsBreadcrumbs.url = routes.transactions.spotTrades;
    }
    return paymentsBreadcrumbs;
  };

  const getBreadcrumbs = (
    batchStatus: PaymentStatus,
  ) => {
    switch (true) {
      case batchStatus === PaymentStatus.SUBMITTED || batchStatus === PaymentStatus.INVALID:
        return [
          { text: 'Transactions', url: routes.transactions.base },
          { text: 'Create Payments', url: routes.transactions.base },
          { text: 'Batch Upload' },
        ];
      default:
        return [
          { text: 'Home', url: homePageUrl },
          { text: 'Transactions', url: routes.transactions.base },
          handleBreadCrumbs(),
          { text: 'summary' },
        ];
    }
  };

  const getBatchDetails = async (
    _batchId: string,
    routeState: PaymentRouteEnum,
  ): Promise< PaymentDto | undefined> => {
    try {
      return await PaymentsService.getBatchDetails(_batchId, routeState);
    } catch (e) {
      snackbar.trigger(`There was an error: (${_batchId}). ${e.message}`);
      return undefined;
    }
  };

  const updateBatchDetails = async (
    _batchId: string,
    routeState: PaymentRouteEnum,
  ) => {
    const newBatchDetails = await getBatchDetails(_batchId, routeState);
    setBatchDetails(newBatchDetails);
  };

  const handleSuccessfulAuthy = async (
    _authyStatus: TAuthyStatus | undefined,
    _batchId: string,
    routeState: PaymentRouteEnum,
  ) => {
    const response = await getBatchDetails(_batchId, routeState);
    if (!response) throw Error();
    try {
      if (
        response.status === PaymentStatus.CLIENT_APPROVED
      ) {
        browserHistory.push(
          routes.transactions.batchSummaryRouteFunc(
            batchId,
            PaymentRouteEnum.BASE,
          ),
        );
        return;
      }
      if (response.status === PaymentStatus.AWAITING_RELEASE) {
        await PaymentsService.postReleasePaymentBatch(batchId);
      }
      browserHistory.push(routes.transactions.base);
      return;
    } catch (e) {
      snackbar.trigger(`There was an error: (${_batchId}). ${e.message}`);
      browserHistory.push(routes.transactions.base);
    }
  };

  const handleReportGeneration = async (fileType: FileType, downloadType: DownloadType) => {
    await executeReportGeneration(fileType, batchId, undefined, undefined, downloadType);
  };

  return {
    batchId,
    authyStatus,
    batchDetails,
    selectedTabIndex,
    routeParameterStats: state,
    handleSetSelectedTabIndex,
    updateBatchDetails,
    getBreadcrumbs,
    handleSuccessfulAuthy,
    handleReportGeneration,
  };
};

export default useBatchSummary;
