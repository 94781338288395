import React, { useEffect, useState } from 'react';
import { startCase } from 'lodash';
import moment from 'moment';
import t from 'utils/translationHelper';

import { TradeStatus, TradeType } from '@alpha/fx-dtos';
import { TDateRangePickerValue } from '@alpha/ui-lib/ui/DatePicker';
import {
  IAutocompleteSelectMultiOption,
} from '@alpha/ui-lib/ui/Select';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import mapFxTradeStatusDisplay from '../../../../utils/fxTrades/mapFxTradeStatusDisplay';
import mapTradeTypeToFriendlyName from '../../../../utils/fxTrades/mapTradeTypeToFriendlyName';

export interface IFxTableFilters {
  buyCurrencies: string[],
  sellCurrencies: string[],
  tradeTypes: TradeType[],
  tradeStatuses: TradeStatus[],
  tradeStartDate: string | undefined,
  tradeEndDate: string | undefined,
  valueStartDate: string | undefined;
  valueEndDate: string | undefined;
  accessDateStartDate?: string | undefined;
  accessDateEndDate?: string | undefined;
  setBuyCurrencies: React.Dispatch<string[]>;
  setSellCurrencies: React.Dispatch<string[]>;
  setTradeTypes: React.Dispatch<TradeType[]>;
  setTradeStatuses: React.Dispatch<TradeStatus[]>;
  setTradeStartDate: React.Dispatch<string | undefined>;
  setTradeEndDate: React.Dispatch<string | undefined>;
  setValueStartDate: React.Dispatch<string | undefined>;
  setValueEndDate: React.Dispatch<string | undefined>;
  setAccessDateStartDate?: React.Dispatch<string | undefined>;
  setAccessDateEndDate?: React.Dispatch<string | undefined>;
  setDisableFilterBackdropClick: React.Dispatch<boolean>;
}

// eslint-disable-next-line max-lines-per-function
const useTradeFilter = (props: IFxTableFilters, tableType?: 'spot' | 'forward') => {
  const {
    buyCurrencies,
    sellCurrencies,
    tradeTypes,
    tradeStatuses,
    tradeStartDate,
    tradeEndDate,
    valueStartDate,
    valueEndDate,
    accessDateStartDate,
    accessDateEndDate,
    setBuyCurrencies,
    setSellCurrencies,
    setTradeTypes,
    setTradeStatuses,
    setTradeStartDate,
    setTradeEndDate,
    setValueStartDate,
    setValueEndDate,
    setAccessDateStartDate,
    setAccessDateEndDate,
    setDisableFilterBackdropClick,
  } = { ...props };

  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [buyCurrenciesFilter,
    setBuyCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [sellCurrenciesFilter,
    setSellCurrenciesFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [tradeTypeFilter,
    setTradeTypeFilter] = useState<IAutocompleteSelectMultiOption[]>();
  const [tradeStatusFilter,
    setTradeStatusFilter] = useState<IAutocompleteSelectMultiOption[]>();

  const [tradeDateFilter, setTradeDateFilter] = useState<TDateRangePickerValue>();
  const [valueDateFilter, setValueDateFilter] = useState<TDateRangePickerValue>();
  const [accessDateFilter, setAccessDateFilter] = useState<TDateRangePickerValue>();
  const [soldCurrenciesFilterOptions, setSoldCurrenciesFilterOptions] = useState<string[]>([]);
  const [buyCurrenciesFilterOptions, setBuyCurrenciesFilterOptions] = useState<string[]>([]);
  const [statusesFilterOptions, setStatusesFilterOptions] = useState<TradeStatus[]>([]);
  const [typesFilterOptions, setTypesFilterOptions] = useState<TradeType[]>([]);

  const sb = useAlphaSnackbar();
  const { logError } = useLog();

  const tradeDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setTradeDateFilter(value);
  };
  const valueDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setValueDateFilter(value);
  };

  const accessDateChanged = (fieldName: string, value: TDateRangePickerValue) => {
    setDisableFilterBackdropClick(false);
    setAccessDateFilter(value);
  };

  useEffect(() => {
    setTradeDateFilter([tradeStartDate ? moment(tradeStartDate) : null,
      tradeEndDate ? moment(tradeEndDate) : null]);
  }, [tradeStartDate, tradeEndDate]);

  const updateFilterOptions = async () => {
    try {
      const filterOptions = await FXTradeService.getTradeFilterOptions({
        tradeTypes: tableType === 'spot' ? [TradeType.SPOT, TradeType.SPOT_OPTION] : [
          TradeType.FORWARD_FIXED, TradeType.FORWARD_OPEN, TradeType.FORWARD_WINDOW, TradeType.FORWARD_VARIABLE],
        statuses: [ // Hide DELETED
          TradeStatus.VALIDATED,
          TradeStatus.SUBMITTED,
          TradeStatus.VERIFIED,
          TradeStatus.REJECTED,
          TradeStatus.ACTIVE,
          TradeStatus.COMPLETED,
          TradeStatus.CANCELLED,
          TradeStatus.AWAITING_SETTLEMENT,
        ],
      });
      if (filterOptions?.soldCurrencies) {
        setSoldCurrenciesFilterOptions(filterOptions.soldCurrencies);
      }
      if (filterOptions?.statuses) {
        const filterOptionsStatuses = filterOptions?.statuses.includes(TradeStatus.SUBMITTED)
          && filterOptions?.statuses.includes(TradeStatus.VERIFIED)
          ? filterOptions?.statuses.filter((ts) => ts !== TradeStatus.VERIFIED)
          : filterOptions?.statuses;
        setStatusesFilterOptions(filterOptionsStatuses);
      }
      if (filterOptions?.types) {
        setTypesFilterOptions(filterOptions.types);
      }
      if (filterOptions?.buyCurrencies) {
        setBuyCurrenciesFilterOptions(filterOptions.buyCurrencies);
      }
    } catch (e) {
      sb.trigger(e?.message || 'Could not load filter options.');
      logError({ action: 'Error loading filter options', error: e });
    }
  };

  useEffect(() => {
    updateFilterOptions();
  }, [tableType]);

  useEffect(() => {
    const startDate = moment(tradeStartDate, 'YYYY-MM-DD');
    const endDate = moment(tradeEndDate, 'YYYY-MM-DD');

    setTradeDateFilter([
      startDate.isValid() ? startDate : null,
      endDate.isValid() ? endDate : null]);
  }, [tradeStartDate,
    tradeEndDate]);

  useEffect(() => {
    const startDate = moment(valueStartDate, 'YYYY-MM-DD');
    const endDate = moment(valueEndDate, 'YYYY-MM-DD');

    setValueDateFilter([
      startDate.isValid() ? startDate : null,
      endDate.isValid() ? endDate : null]);
  }, [valueStartDate,
    valueEndDate]);

  useEffect(() => {
    const startDate = moment(accessDateStartDate, 'YYYY-MM-DD');
    const endDate = moment(accessDateEndDate, 'YYYY-MM-DD');

    setAccessDateFilter([
      startDate.isValid() ? startDate : null,
      endDate.isValid() ? endDate : null]);
  }, [accessDateStartDate,
    accessDateEndDate]);

  useEffect(() => {
    setBuyCurrenciesFilter(mapCurrencyFilterOptions(buyCurrencies));
  }, [
    buyCurrencies,
  ]);

  useEffect(() => {
    setSellCurrenciesFilter(mapCurrencyFilterOptions(sellCurrencies));
  }, [
    sellCurrencies,
  ]);

  useEffect(() => {
    setTradeTypeFilter(mapTradeTypeFilterOptions(tradeTypes));
  }, [
    tradeTypes,
  ]);

  useEffect(() => {
    let filteredTradeStatuses = tradeStatuses;
    if (tradeStatuses.includes(TradeStatus.SUBMITTED)
      && tradeStatuses.includes(TradeStatus.VERIFIED)) {
      filteredTradeStatuses = tradeStatuses.filter((ts) => ts !== TradeStatus.VERIFIED);
    }

    setTradeStatusFilter(mapTradeStatusFilterOptions(filteredTradeStatuses.filter((item) => (
      Object.values(TradeStatus).includes(item)))));
  }, [
    tradeStatuses,
  ]);

  const mapTradeTypeFilterOptions = (options: TradeType[], tradeTypesLimit?: TradeType[]) => options.map((option) => (
    {
      value: option,
      label: t(startCase(mapTradeTypeToFriendlyName(option).toLowerCase())),
    }
  )).filter((option) => {
    if (tradeTypesLimit?.length) {
      return tradeTypesLimit.includes(option.value);
    }
    return true;
  });

  const mapTradeStatusFilterOptions = (options: TradeStatus[]) => options.map((option) => (
    {
      value: option,
      label: t(startCase(mapFxTradeStatusDisplay(option).text.toLowerCase())),
    }
  ));

  const mapCurrencyFilterOptions = (options: string[]) => options.map((option) => (
    { value: option, label: option }
  ));

  const clearAllFilters = () => {
    setBuyCurrenciesFilter([]);
    setSellCurrenciesFilter([]);
    setTradeTypeFilter([]);
    setTradeStatusFilter([]);
    setTradeStartDate('');
    setTradeEndDate('');
    setValueStartDate('');
    setValueEndDate('');
    setTradeDateFilter(undefined);
    setValueDateFilter(undefined);
    setAccessDateFilter(undefined);
    setAccessDateStartDate?.(undefined);
    setAccessDateEndDate?.(undefined);
  };

  const applyChanges = () => {
    setBuyCurrencies(buyCurrenciesFilter?.map((filter) => filter.value as string) || []);
    setSellCurrencies(sellCurrenciesFilter?.map((filter) => filter.value as string) || []);
    setTradeTypes(tradeTypeFilter?.map((filter) => filter.value as TradeType) || []);
    setTradeStatuses(tradeStatusFilter?.map((filter) => filter.value as TradeStatus) || []);

    if (tradeDateFilter) {
      setTradeStartDate(tradeDateFilter[0]?.format('YYYY-MM-DD'));
      setTradeEndDate(tradeDateFilter[1]?.format('YYYY-MM-DD'));
    }
    if (valueDateFilter) {
      setValueStartDate(valueDateFilter[0]?.format('YYYY-MM-DD'));
      setValueEndDate(valueDateFilter[1]?.format('YYYY-MM-DD'));
    }
    if (accessDateFilter) {
      setAccessDateStartDate?.(accessDateFilter[0]?.format('YYYY-MM-DD'));
      setAccessDateEndDate?.(accessDateFilter[1]?.format('YYYY-MM-DD'));
    }
  };

  return {
    open,
    setOpen,
    clearAllFilters,
    applyChanges,
    tradeDateChanged,
    valueDateChanged,
    accessDateChanged,
    mapTradeStatusFilterOptions,
    mapTradeTypeFilterOptions,
    mapCurrencyFilterOptions,
    buyCurrenciesFilterOptions,
    buyCurrenciesFilter,
    setBuyCurrenciesFilter,
    soldCurrenciesFilterOptions,
    sellCurrenciesFilter,
    setSellCurrenciesFilter,
    typesFilterOptions,
    tradeTypeFilter,
    setTradeTypeFilter,
    statusesFilterOptions,
    tradeStatusFilter,
    setTradeStatusFilter,
    tradeDateFilter,
    valueDateFilter,
    accessDateFilter,
    setAccessDateFilter,
  };
};

export default useTradeFilter;
