import React, {
  useState,
} from 'react';
import clsx from 'clsx';
import isFxMarketClosed from 'utils/fxTrades/isFxMarketClosed';
import t from 'utils/translationHelper';

import { TradeType } from '@alpha/fx-dtos/lib/enums/TradeType';
import {
  Collapse,
  IconButton,
  Typography,
} from '@alpha/ui-lib/ui/external';
import { Card } from '@alpha/ui-lib/ui/layout/Card';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from '../Dashboard.styles';
import FxSummaryTable from '../FxTradeTable/FxSummaryTable';
import FxTradeTable, { TFilter } from '../FxTradeTable/FxTradeTable';
import { TSearchFilterTypes } from '../FxTradeTable/useFxTradeTable';

interface IProps {
  tradeFilters: TFilter[];
  filtersByField?: TSearchFilterTypes;
  handleRemoveFilterItem: (filterItem: TFilter) => void;
  fxLastUpdatedTime?: string;
  filterButton?: React.ReactNode,

}

const ForwardTrade: React.FC<IProps> = ({
  tradeFilters,
  filtersByField,
  handleRemoveFilterItem,
  fxLastUpdatedTime,
  filterButton,
}: IProps) => {
  const fxMarketClosed = isFxMarketClosed();

  const classes = useStyles({ fxMarketClosed });
  const [openSummary, setOpenSummary] = useState(false);

  return (
    <>
      <Card>
        <div className={classes.headerRow}>
          <Typography className={classes.tableHeading}>{t('summary')}</Typography>
          <IconButton
            onClick={() => setOpenSummary(!openSummary)}
            style={{ height: '50%', borderRadius: 0 }}
          >
            <FontAwesomeIcon
              className={clsx(classes.icon, { up: openSummary })}
              icon={faChevronDown as IconProp}
              size="lg"
            />
          </IconButton>
        </div>
        <Typography className={classes.tableSubHeading}>
          {t('summary_view_of_open_positions_by_currency_pair')}
        </Typography>
        <Collapse in={openSummary} component="div" className="forward-trade-summary-collapse">
          <FxSummaryTable
            setOpenSummary={setOpenSummary}
            testId="forward-summary-table"
            emptyTitle={t('no_forward_trades')}
            emptySubtitle={t('you_currently_do_not_have_any_fx_trades')}
            tradeTypes={[TradeType.FORWARD_FIXED, TradeType.FORWARD_OPEN, TradeType.FORWARD_WINDOW, TradeType.FORWARD_VARIABLE]}
          />
        </Collapse>
      </Card>
      <Card>
        <FxTradeTable
          testId="forward-trade-table"
          filters={tradeFilters}
          filtersByField={filtersByField}
          handleRemoveFilterItem={handleRemoveFilterItem}
          emptyTitle={t('no_forward_trades')}
          emptySubtitle={t('you_currently_do_not_have_any_matching_forward_trades')}
          lastUpdatedTime={fxLastUpdatedTime}
          tradeTypes={[TradeType.FORWARD_FIXED, TradeType.FORWARD_OPEN, TradeType.FORWARD_WINDOW, TradeType.FORWARD_VARIABLE]}
          filterButton={filterButton}
        />
      </Card>
    </>
  );
};

export default ForwardTrade;
