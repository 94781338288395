import React, { useEffect } from 'react';
import { AuthContext } from 'context/AuthContext';
import useAuth from 'hooks/useAuth';
import { TSignIn } from 'models/auth';
import { TUser } from 'models/user';
import { Route, Switch, useLocation } from 'react-router-dom';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';

import Onboarding from './Onboarding';
import PasswordReset from './PasswordReset';
import Returning from './Returning';

export interface IAuthProps {
  userObject?: TSignIn;
  userInfo?: TUser;
  onboarding?: boolean;
}

const Auth = () => {
  const {
    loggedIn, userInfo, setUserObject, userObject,
  } = useAuth();
  const location = useLocation();

  useEffect(() => {
    shouldRedirectToWelcome();
  }, []);

  function shouldRedirectToWelcome() {
    if (loggedIn && location.pathname.indexOf(routes.auth.welcome) < 0) {
      browserHistory.push(routes.auth.welcome);
    }
  }

  function handleSetUserObject(_userObject: TSignIn) {
    setUserObject(_userObject);
  }

  return (
    <AuthContext.Provider value={{ userObject, handleSetUserObject }}>
      <Switch>
        <Route path={routes.auth.onboarding.base}>
          <Onboarding userObject={userObject} userInfo={userInfo} />
        </Route>
        <Route path={routes.auth.passwordReset.base}>
          <PasswordReset />
        </Route>
        <Route path={routes.auth.base}>
          <Returning userObject={userObject} userInfo={userInfo} />
        </Route>
      </Switch>
    </AuthContext.Provider>
  );
};

export default Auth;
