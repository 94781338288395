import React from 'react';
import t from 'utils/translationHelper';

import { makeStyles } from '@alpha/ui-lib/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import authyIcon from '../../../assets/authyIcon.png';
import AuthBackupMethodCard from '../../AuthBackupMethodCard';

interface IProps {
  handleCardClick: (card: 'authy' | 'sms' | 'call') => void;
}

const useStyles = makeStyles(
  () => ({
    container: {
      '& > button': {
        marginBottom: '24px',
      },
    },
  }),
  { name: 'mfaOptionsCard' },
);
const MFAOptionsCard: React.FC<IProps> = ({
  handleCardClick,
}: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <AuthBackupMethodCard
        icon={{
          backgroundColor: '#EC1C24',
          marginTop: '3px',
          element: <img src={authyIcon} alt="Authy icon" />,
        }}
        content={{
          title: 'Authy',
          body: t('download_the_authy_app'),
        }}
        handleOnClick={() => handleCardClick('authy')}
        displaySecureBadge
      />
      <AuthBackupMethodCard
        icon={{
          marginTop: '3px',
          element: <FontAwesomeIcon icon={faCommentAltDots as IconProp} />,
        }}
        content={{
          title: t('resend_sms'),
          body: t('receive_an_sms_to_your_registered_device'),
        }}
        handleOnClick={() => handleCardClick('sms')}
      />
      <AuthBackupMethodCard
        icon={{
          marginTop: '3px',
          element: <FontAwesomeIcon icon={faPhone as IconProp} />,
        }}
        content={{
          title: t('call'),
          body: t('receive_a_phone_call_to_your_registered_device2'),
        }}
        handleOnClick={() => handleCardClick('call')}
      />
    </div>
  );
};

export default MFAOptionsCard;
