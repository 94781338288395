import React from 'react';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';

import { ModalType } from '../Body';

import Body from './Body/Body';
import Header from './Header/Header';

export interface ISelectedBeneficiary {
  beneficiary: BeneficiaryDto | undefined;
  approved: boolean;
}
interface IBeneInfoDrawer {
  selectedBeneficiary: ISelectedBeneficiary | undefined;
  beneDrawerOpen: boolean;
  canShareBeneficiary: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalType | false>>
  closeDrawer: () => void;
  handleBeneficiaryShareButtonClick: (beneficiary?: BeneficiaryDto) => void;
  setCreateBeneficiaryOpen?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  handleApprovedEditButtonClick?: (clickedBeneficiary: BeneficiaryDto) => void
  setAddressOnly?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export const BeneInfoDrawer: React.FC<IBeneInfoDrawer> = (
  {
    selectedBeneficiary,
    beneDrawerOpen,
    canShareBeneficiary,
    closeDrawer,
    handleBeneficiaryShareButtonClick,
    setModalOpen,
    setCreateBeneficiaryOpen,
    handleApprovedEditButtonClick,
    setAddressOnly,
  }: IBeneInfoDrawer,
) => (
  <BaseDrawer.Drawer
    data-testid="beneficiary-drawer"
    open={beneDrawerOpen}
    onClose={closeDrawer}
    anchor="right"
    closeIdentifier='beneficiary-info-drawer'
  >
    {
      selectedBeneficiary
      && (
        <>
          <Header
            handleBeneficiaryShareButtonClick={handleBeneficiaryShareButtonClick}
            canShareBeneficiary={canShareBeneficiary}
            selectedBeneficiary={selectedBeneficiary}
            setModalOpen={setModalOpen}
          />
          <Body
            setModalOpen={setModalOpen}
            selectedBeneficiary={selectedBeneficiary}
            setCreateBeneficiaryOpen={setCreateBeneficiaryOpen}
            handleApprovedEditButtonClick={handleApprovedEditButtonClick}
            setAddressOnly={setAddressOnly}
          />
        </>
      )
    }
  </BaseDrawer.Drawer>
);
