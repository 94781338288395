/* eslint-disable max-len */
import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { matchPath } from 'react-router';
import AccountSettingsService from 'services/AccountSettings/accountSettings.service';
import t from 'utils/translationHelper';

import { AccountDto, PortalPagesConfigurationDto } from '@alpha/auth-dtos';
import { datadogRum } from '@datadog/browser-rum';

import routes from '../routes.path';
import AuthorizationService from '../services/Authorization/authorization.service';

import useAlphaSnackbar from './useAlphaSnackbar';

// eslint-disable-next-line max-lines-per-function
const useSwitchAccount = () => {
  const sb = useAlphaSnackbar();

  const [isValidAccountId, setIsValidAccountId] = useState<boolean>();
  const homePageUrl = routes.dashboard;

  const getAccountDetailsFromLocalStorage = (): AccountDto => JSON.parse(localStorage.getItem('account-info')!) || '';
  const getUserAccounts = async (): Promise<AccountDto[]> => AuthorizationService.getUserAccounts();

  const getPortalPagesConfiguration = async () => {
    try {
      const pagesConfigurations = await AuthorizationService.getPortalPagesConfigurations();
      const account = getAccountDetailsFromLocalStorage();
      updatePortalPagesConfiguration(account.id, pagesConfigurations);
    } catch (e) {
      const account = getAccountDetailsFromLocalStorage();
      // useLog() will trigger maximum stack call
      datadogRum.addError(
        e, { action: 'Error geting portal pages configuration', accountId: account.id },
      );
    }
  };

  const getPortalPagesConfigurationFromLocalStorage = (
    accountId: string,
  ): PortalPagesConfigurationDto | null => {
    try {
      return JSON.parse(localStorage.getItem(`portal-pages-configuration-${accountId}`) || '') as PortalPagesConfigurationDto;
    } catch {
      return null;
    }
  };

  const isAccountIdExistingInUserAccountsArray = (
    userAccounts: AccountDto[],
    accountId: string | null,
  ) => accountId && userAccounts.some((account) => account.id === accountId);

  const updateCurrentAccount = async (account: AccountDto): Promise<void> => {
    localStorage.setItem('account-info', JSON.stringify(account));
    try {
      await getPortalPagesConfiguration();
    } catch (error) {
      sb.trigger(error?.message || t('there_was_an_error_retrieving_your_accounts_configurations'));
    }
  };

  const updatePortalPagesConfiguration = (
    accountId: string,
    configuration: PortalPagesConfigurationDto,
  ): void => {
    localStorage.setItem(`portal-pages-configuration-${accountId}`, JSON.stringify(configuration));
  };

  const getAccountConfigIsMoneyDisabled = async () => {
    try {
      const lastAccountDetails = getAccountDetailsFromLocalStorage();

      if (!lastAccountDetails || !lastAccountDetails.id) { return undefined; }

      const accountConfig = await AccountSettingsService.getAccountConfigurationForAccount(
        lastAccountDetails.id,
      );
      updateCurrentAccount({ ...lastAccountDetails, eMoneyDisabled: accountConfig.eMoneyDisabled });
      return accountConfig.eMoneyDisabled;
    } catch (error) {
      console.error(error);
      sb.trigger(error?.message || t('there_was_an_error_retrieving_your_accounts_configurations'));
      return false;
    }
  };

  const setUserAccount = async (): Promise<void> => {
    try {
      const newUserAccounts = await getUserAccounts();
      // To revisit following product discussion on account access changes
      // if (!newUserAccounts.length) {
      //   setIsValidAccountId(false);
      // }

      const lastAccountDetails = getAccountDetailsFromLocalStorage();
      const isDisabled = await getAccountConfigIsMoneyDisabled();

      if (isAccountIdExistingInUserAccountsArray(
        newUserAccounts,
        lastAccountDetails.id,
      )) {
        setIsValidAccountId(true);
        updateCurrentAccount({ ...lastAccountDetails, eMoneyDisabled: isDisabled });
      } else if (newUserAccounts.length > 0) {
        updateCurrentAccount({ ...newUserAccounts[0], eMoneyDisabled: isDisabled });
        setIsValidAccountId(true);
      }
    } catch (error) {
      sb.trigger(error?.response?.data?.friendlyMessage ?? error?.response?.data?.message ?? error?.message ?? t('there_was_an_error_retrieving_your_accounts_for_your_user'));
      setIsValidAccountId(false);
      // Redirect to 404 page in upper component
    }
  };

  const mapPageToPath = (pageKey: string): string => {
    let url: string = routes.transactions.base;
    switch (pageKey) {
      case 'TRANSACTIONS':
        url = routes.transactions.spotTrades;
        break;
      case 'BENEFICIARIES':
        url = routes.beneficiaries;
        break;
      case 'ACCOUNTS':
        url = routes.currencyAccounts;
        break;
      case 'STATEMENTS':
        url = routes.noStatements;
        break;
      case 'HEDGING_PROGRAMS':
        url = routes.hedgingPrograms;
        break;
      case 'MARKET_ORDERS':
        url = routes.marketOrders;
        break;
      case 'MARKET_INFORMATION':
        url = routes.marketInformation;
        break;
      case 'DASHBOARD':
        url = routes.dashboard;
        break;
      case 'CREDIT_FACILITY':
        url = routes.credit;
        break;
      default:
        url = routes.dashboard;
    }
    return url;
  };

  const mapPathToPage = (path: string): string => {
    if (matchPath(path, { path: routes.transactions.base })
      || matchPath(path, { path: routes.transactions.trades })) {
      return 'TRANSACTIONS';
    }

    if (matchPath(path, { path: routes.beneficiaries })) {
      return 'BENEFICIARIES';
    }

    if (matchPath(path, { path: routes.currencyAccounts })) {
      return 'ACCOUNTS';
    }

    if (matchPath(path, { path: routes.hedgingSummary })) {
      return 'HEDGING_SUMMARY';
    }

    if (matchPath(path, { path: routes.noStatements })) {
      return 'STATEMENTS';
    }

    if (matchPath(path, { path: routes.marketOrders })) {
      return 'MARKET_ORDERS';
    }
    if (matchPath(path, { path: routes.marketInformation })) {
      return 'MARKET_INFORMATION';
    }
    if (matchPath(path, { path: routes.credit })) {
      return 'CREDIT_FACILITY';
    }

    return 'OTHERS';
  };

  const getCurrentPortalPagesConfigurationFromLocalStorage = () => {
    const account = getAccountDetailsFromLocalStorage();

    if (account) {
      const config = getPortalPagesConfigurationFromLocalStorage(account.id);
      return config;
    }
    return null;
  };

  const isEMoneyDisabled = () => {
    const { eMoneyDisabled } = getAccountDetailsFromLocalStorage() as AccountDto & { eMoneyDisabled: boolean; };
    return eMoneyDisabled ?? false;
  };

  const isPageViewable = (path: string): boolean => {
    const pageName = mapPathToPage(path);

    const config = getCurrentPortalPagesConfigurationFromLocalStorage();
    const { eMoneyDisabled } = getAccountDetailsFromLocalStorage() as AccountDto & { eMoneyDisabled: boolean; };
    if (eMoneyDisabled) {
      const disabledEMoneyPages = ['transfer', 'option-trades'];
      const isNotAllowed = disabledEMoneyPages.find((disabled) => path.includes(disabled));
      if (isNotAllowed) {
        return false;
      }
    }

    if (pageName === 'OTHERS') {
      return true;
    }

    if (config && config?.viewablePages) {
      return (config?.viewablePages as string[]).includes(pageName);
    }
    return false;
  };

  return {
    isValidAccountId,
    currentAccount: getAccountDetailsFromLocalStorage(),
    currentPagesConfiguration: getCurrentPortalPagesConfigurationFromLocalStorage,
    updateCurrentAccount,
    getUserAccounts,
    setUserAccount,
    getPortalPagesConfiguration,
    mapPageToPath,
    isPageViewable,
    homePageUrl,
    getAccountDetailsFromLocalStorage,
    isEMoneyDisabled,
    getAccountConfigIsMoneyDisabled,
  };
};

export default useSwitchAccount;
