import moment, { Moment } from 'moment-timezone';

// adding alias to avoid confusion with payments given this is drawdown related
import { PaymentDateOptionsDto as DrawdownDateOptionsDto } from '@alpha/payments-dtos';

const calculateDisableDrawdownDates = (_date: Moment, valueDate?: string, dateOptions?: DrawdownDateOptionsDto): boolean => {
  if (dateOptions) {
    const defaultTimezone = moment.tz.guess();

    const formattedDate = _date.tz(defaultTimezone)
      .format('YYYY-MM-DD');
    const todayDate = moment.tz('Europe/London').format('YYYY-MM-DD');

    const notHolidays = dateOptions.holidays?.some((holiday) => moment(holiday.date).tz('Europe/London').format('YYYY-MM-DD') === formattedDate);
    _date.tz(defaultTimezone);

    if (
      valueDate && valueDate !== '' && moment(valueDate).isBefore(todayDate)
      && (moment(formattedDate).isBefore(todayDate)
      || moment(formattedDate).isAfter(todayDate))
    ) {
      return true;
    }

    return (
      moment(formattedDate).isBefore(todayDate)
      || moment(formattedDate).isBefore(dateOptions.earliestDate)
      || moment(formattedDate).isAfter(dateOptions.latestDate)
      || notHolidays
    );
  }
  return false;
};

export default calculateDisableDrawdownDates;
