import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(() => ({
  leftContent: {
    backgroundColor: 'unset',
    paddingTop: 0,
    marginTop: '80px',
  },
  alphaLogo: {
    width: '95px',
    height: '120.61px',
    marginBottom: '40px',
  },
  title: {
    fontWeight: 200,
    fontSize: '22px',
    paddingBottom: '40px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  content: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#FFFFFF',
  },
  contactContainer: {
    marginTop: '20px',
    display: 'block',
  },
  errorBoundaryLeftContent: {
    '& > .wrapper': {
      flexGrow: 'unset',
    },
  },
}), { name: 'DefaultErrorBoundary' });

export default useStyles;
