import React, { useEffect, useState } from 'react';
import AlphaWhiteLogo from 'assets/alphaWhite.svg';
import clsx from 'clsx';
import AuthLayout from 'components/Layout/Auth';
import useAuth from 'hooks/useAuth';
import useLog from 'hooks/useLog';
import { useErrorBoundary } from 'react-error-boundary';
import routes from 'routes.path';
import browserHistory from 'services/history/browserHistory';
import t from 'utils/translationHelper';

import { Box } from '@alpha/ui-lib/ui/external';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import useStyles from './index.styles';

type DefaultErrorBoundaryProps = {
  error: Error
}

const DefaultErrorBoundary: React.FC<DefaultErrorBoundaryProps> = ({ error }) => {
  const { logError } = useLog();
  const { resetBoundary } = useErrorBoundary();
  const classes = useStyles();
  const { loggedIn, userInfo } = useAuth();
  const [link, setLink] = useState('');
  const [redirectLocationName, setRedirectLocationName] = useState('Dashboard');

  logError({ action: 'React application error occured', error });

  useEffect(() => {
    const errorLocation = browserHistory.location.pathname;
    const linkValue = (!loggedIn && !userInfo) || errorLocation === routes.dashboard ? routes.auth.login : routes.dashboard;
    setLink(linkValue);
    setRedirectLocationName(linkValue === routes.dashboard ? 'Dashboard' : 'Login');
  }, [loggedIn, userInfo]);

  return (
    <div style={{ minWidth: '100%', margin: 'auto', background: '#000000' }}>
      <AuthLayout.Container>
        <AuthLayout.LeftContent className={clsx(classes.leftContent, { [classes.errorBoundaryLeftContent]: true })}>
          <img
            className={classes.alphaLogo}
            src={AlphaWhiteLogo}
            alt="Alpha Group Logo"
          />
          <Box>
            <Typography className={classes.title}>
              {t('error_something_went_wrong')}
            </Typography>
            <Typography className={classes.content}>
              {t('there_seems_to_have_been_an_error_with_the_platform')}
              <Typography className={classes.content}>{t('please_refersh_the_page_or_click_on_the_link_below_to_log_back_in')}</Typography>
              <Typography className={classes.content}>
                {t('if_the_problem_persists')}
                {' '}
              </Typography>
              <Typography className={clsx(classes.content, classes.contactContainer)}>
                {' '}
                {t('please_contact_us_via')}
                {' '}
                <a href="mailto:clientservices-rm@alphagroup.com">clientservices-rm@alphagroup.com</a>
              </Typography>
            </Typography>
            <Box paddingTop="40px">
              <Typography className={classes.content}>
                {t('return_to')}
                {' '}
                <a
                  onClick={resetBoundary}
                  href={link}
                >
                  {redirectLocationName}
                </a>
              </Typography>
            </Box>
          </Box>
        </AuthLayout.LeftContent>
        <div />
      </AuthLayout.Container>
    </div>

  );
};
export default DefaultErrorBoundary;
