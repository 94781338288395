import { makeStyles as alphaMakeStyles } from '@alpha/ui-lib/theme';

export const useStyles = alphaMakeStyles(
  ({ palette }) => ({
    flagText: {
      fontFamily: 'Source Sans Pro',
      lineHeight: '1px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: 'white',
      fontSize: '14px',
      verticalAlign: 'middle',
      marginLeft: '10px',

    },
    flagCombine: {
      width: '94px',
      flexShrink: 0,
      padding: '16px',
    },
    flag: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '15px',
    },
    button: {
      width: '83px',
      marginLeft: '6px',
      marginRight: '6px',
      height: '47px',
      textTransform: 'none',
      border: '1px solid',
      borderRadius: '10px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.12)',
        outline: '1px solid rgba(255, 255, 255, 0.25)',
      },
    },
    selectedBtn: {
      width: '86px',
      padding: '16px',
      height: '44px',
    },
    langSelector: {
      width: '612px',
      borderRadius: '10px',
      marginTop: '8px',
    },

  }),
  { name: 'LangSelectorGroup' },
);
