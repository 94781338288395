import React, { useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import { PaymentDto } from '@alpha/payments-dtos';
import { ActionButton } from '@alpha/ui-lib/ui/button/ActionButton';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { StyledTabsWrapper } from '@alpha/ui-lib/ui/Tabs/StyledTabsWrapper';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';

import usePaymentApproval from '../../../../hooks/usePaymentApproval';
import InvalidApprovalModal from '../InvalidApprovalModal';
import ValueDateChangedModal from '../ValueDateChangedModal';

import ApprovalSummary from './ApprovalSummary';
import useStyles from './index.styles';

type Props = {
  open: boolean,
  selectedPayments: PaymentDto[],
  onClose: () => void,
}

const ApprovalDrawer: React.FC<Props> = ({ open, selectedPayments, onClose }) => {
  const styles = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const {
    nonFinalApprovers,
    finalApprovers,
    loading,
    failedPayments,
    expiredPayments,
    setFailedPayments,
    setExpiredPayments,
    setLoading,
    handleApprovals,
    handleValueDateChangeAcknowlegement,
    onDrawerClose,
  } = usePaymentApproval(selectedPayments, onClose);

  const tabTitles: string[] = [`${t('further_approval_required')} (${nonFinalApprovers.length})`, `${t('fully_approved')} (${finalApprovers.length})`];

  useEffect(() => {
    if (!nonFinalApprovers.length) {
      setTabIndex(1);
    }
  }, [selectedPayments]);

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        className={styles.container}
        closeIdentifier='approve-payment-drawer'
      >
        <BaseDrawer.Header
          headerTitle={t('payment_approval')}
          headerSubtitle={`${selectedPayments.length} ${selectedPayments.length === 1 ? t('payment') : t('payments')}`}
        >
          <div style={{ margin: '30px 0' }}>
            <BaseDrawer.LineBreak />
          </div>
        </BaseDrawer.Header>
        <BaseDrawer.Body>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 'calc(100vh - 239px)',
          }}
          >
            <div>
              {selectedPayments.length > 1 && (
                <div style={{ marginBottom: '16px' }}>
                  <Typography>
                    <span style={{ fontWeight: '800' }}>
                      {t('note')}
                      {' '}
                      -
                      {' '}
                    </span>
                    {t('some_of_the_payments_you_are_about_to_approve_wil_have_different_statuses')}
                  </Typography>
                </div>
              )}
              <StyledTabsWrapper
                testId="payment-tabs-wrapper"
                tabTitles={tabTitles}
                tabIndex={tabIndex}
                handleChange={(selectedTabIndex: number) => setTabIndex(selectedTabIndex)}
              />
              <div style={{ marginTop: '24px' }}>
                {tabIndex === 0 && (<ApprovalSummary values={nonFinalApprovers} />)}
                {tabIndex === 1 && (<ApprovalSummary values={finalApprovers} />)}
              </div>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', padding: '92px 0 42px 0', justifyContent: 'flex-end',
            }}
            >
              <div style={{
                position: 'absolute', transform: 'translateY(-42px)', width: '100%', left: 0,
              }}
              >
                <BaseDrawer.LineBreak />
              </div>
              <ActionButton size="medium" style={{ background: '#F7F7F7', color: '#212529', marginRight: '16px' }} onClick={onDrawerClose}>
                {t('cancel')}
              </ActionButton>
              <ActionButton size="medium" onClick={handleApprovals} disabled={loading} loading={loading}>
                {selectedPayments.length > 1 ? `${t('confirm_NUMBER_approvals1')} ${selectedPayments.length} ${t('confirm_NUMBER_approvals2')}` : t('confirm_approval')}
              </ActionButton>
            </div>
          </div>
        </BaseDrawer.Body>
      </BaseDrawer.Drawer>
      <InvalidApprovalModal
        handleClose={() => {
          setFailedPayments([]);
          setLoading(false);
        }}
        handleAcknowledge={() => {
          setFailedPayments([]);
          setLoading(false);
          onClose();
        }}
        open={Boolean(failedPayments.length)}
        payments={failedPayments}
      />
      <ValueDateChangedModal
        handleAcknowledge={handleValueDateChangeAcknowlegement}
        handleClose={() => {
          setExpiredPayments([]);
          setLoading(false);
        }}
        open={Boolean(expiredPayments.length)}
        expiredPayments={expiredPayments}
      />
    </>
  );
};

export default ApprovalDrawer;
