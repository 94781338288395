import React, { useEffect, useState } from 'react';
import clipboardCross from 'assets/clipboardCross.svg';
import BackdropLoader from 'components/Molecules/Loaders/BackdropLoader/BackdropLoader';
import Search, { TFilterChipData } from 'components/Organisms/Search';
import SearchTable from 'components/Organisms/SearchTable';
import EmptyTable from 'components/Table/EmptyTable';
import useAuthorization from 'hooks/useAuthorization';
import i18n from 'i18n/config';
import { startCase } from 'lodash';
import { UserRole } from 'models/user';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { formatNumber } from 'utils/currency.helpers';
import formatIsoDate from 'utils/formatIsoDate';
import mapFxTradeStatusDisplay from 'utils/fxTrades/mapFxTradeStatusDisplay';
import mapTradeTypeToFriendlyName from 'utils/fxTrades/mapTradeTypeToFriendlyName';
import t from 'utils/translationHelper';

import { TradeDto, TradeStatus, TradeType } from '@alpha/fx-dtos';
import { Box, Button, Typography } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Status } from '@alpha/ui-lib/ui/Status';

import ActionDropDown from './DisplayTable/ActionDropDown/ActionDropDown';
import PadColumnValue from './TradeDetail/PadColumnValue/PadColumnValue';
import useStyles from './FxTradeTable.styles';
import useFxTradeTable, { TSearchFilterTypes } from './useFxTradeTable';

export type TFilter = {
  value: string;
  field: string;
  displayValue?: string;
};

interface IFxTradeTable {
  emptyTitle?: string;
  emptySubtitle?: string;
  testId?: string;
  filters?: TFilter[];
  filtersByField?: TSearchFilterTypes;
  handleRemoveFilterItem?(item: TFilter): void;
  lastUpdatedTime?: string;
  tradeTypes?: TradeType[];
  filterButton?: React.ReactNode;
  quickFilterOpenTrades?: () => void;
  quickFilterDrawdownEligible?: () => void;
}

// eslint-disable-next-line max-lines-per-function
const FxTradeTable: React.FC<IFxTradeTable> = (props: IFxTradeTable) => {
  const {
    emptyTitle,
    emptySubtitle,
    testId,
    filters,
    filtersByField,
    handleRemoveFilterItem,
    lastUpdatedTime,
    tradeTypes,
    filterButton,

  } = props;

  const isForwardTable = tradeTypes?.includes(TradeType.FORWARD_FIXED);

  const {
    table,
    handleInputChange,
    searchParams,
    handleTableSortClick,
    reloadTableData,
    columns,
    handleTradeNumberClick,
    accountConfiguration,
  } = useFxTradeTable(filtersByField);

  const [filterChips, setFilterChips] = useState<TFilterChipData[]>([]);
  const [displayMultiSelect, setDisplayMultiSelect] = useState(false);
  const { authorized: canCancelOwnTrade } = useAuthorization([
    [UserRole.SPOT_INPUTTER],
  ]);
  const { authorized: canCancelAnyTrade } = useAuthorization([[UserRole.SPOT]]);
  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.profileDetails?.id,
  );

  const classes = useStyles();

  const showCancelTrade = (trade: TradeDto): boolean => {
    if (
      (trade.submittedBy === userId && canCancelOwnTrade)
      || canCancelAnyTrade
    ) {
      return true;
    }
    return false;
  };

  const generateTableData = () => {
    const records = table?.items?.items?.records as TradeDto[];

    return records?.map((trade) => ({
      ...trade,
      contractNumber: (
        <Button
          disableRipple
          className={classes.tradeTableButton}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (handleTradeNumberClick) {
              handleTradeNumberClick(trade.id);
            }
          }}
        >
          {trade.contractNumber || '-'}
        </Button>
      ),
      tradeType: t(mapTradeTypeToFriendlyName(trade.type)) || '-',
      tradeDate: formatIsoDate(trade.date),
      accessDate: formatIsoDate(trade.accessDate),
      soldAmount: (
        <div className={classes.amount}>
          <div>{formatNumber(trade.soldAmount || 0, 2)}</div>
          <div className={classes.currencyFlag}>
            <Flag code={trade.soldCurrencyCode} size="sm" showCodeLabel />
          </div>
        </div>
      ),
      rate:
  <span className={classes.tableLink}>{trade.rate?.toFixed(4)}</span>
        || '-',
      buyAmount: (
        <div className={classes.amount}>
          <div>{formatNumber(trade.buyAmount || 0, 2)}</div>
          <div className={classes.currencyFlag}>
            <Flag code={trade.buyCurrencyCode} size="sm" showCodeLabel />
          </div>
        </div>
      ),
      buyBalance: (
        <div className={classes.amount}>
          {[formatNumber(trade.buyBalance || 0, 2), trade.buyCurrencyCode].join(
            ' ',
          )}
        </div>
      ),
      valueDate: formatIsoDate(trade.valueDate),
      tradeStatus: (
        <Status
          className={classes.status}
          variant={mapFxTradeStatusDisplay(trade.status).variant}
        >
          {t(mapFxTradeStatusDisplay(trade.status).text) || '-'}
        </Status>
      ),
      padEligibility: (
        <PadColumnValue
          padEligibleItem={trade}
          handleTradeAuthorisation={handleTradeNumberClick}
        />
      ),
      action: (
        <ActionDropDown
          trade={trade}
          setDisplayMultiSelect={setDisplayMultiSelect}
          displayMultiSelect={displayMultiSelect}
          handleReloadTradesTable={reloadTableData}
          showCancelTrade={showCancelTrade(trade)}
          eMoneyDisabled={accountConfiguration?.eMoneyDisabled}
        />
      ),
    }));
  };

  const mapFiltersToDisplay = (filtersToMap: TFilter[]): TFilterChipData[] => filtersToMap.map((filter) => {
    let filterValue = filter.value as string;
    switch (filter.field) {
      case 'tradeStatus':
      case 'forwardTradeStatus':
        filterValue = startCase(
          mapFxTradeStatusDisplay(
              filter.value as TradeStatus,
          ).text.toLowerCase(),
        );
        break;
      case 'tradeType':
      case 'forwardTradeType':
        filterValue = startCase(
          mapTradeTypeToFriendlyName(filter.value as TradeType).toLowerCase(),
        );
        break;
      case 'tradeStartDate':
      case 'tradeEndDate':
      case 'forwardTradeStartDate':
      case 'forwardTradeEndDate':
      case 'valueStartDate':
      case 'valueEndDate':
      case 'forwardValueStartDate':
      case 'forwardValueEndDate':
      case 'accessDateStartDate':
      case 'accessDateEndDate':
      case 'forwardAccessDateStartDate':
      case 'forwardAccessDateEndDate':
        filterValue = formatIsoDate(filter.value);
        break;
      default:
        filterValue = filter.value;
        break;
    }

    return {
      label: (
        <span>
          <b>
            {t(startCase(filter.displayValue ?? filter.field))}
            :
          </b>
          {' '}
          {t(filterValue)}
        </span>
      ),
      value: filter.value,
      field: filter.field,
    };
  });

  useEffect(() => {
    setFilterChips(mapFiltersToDisplay(filters || []));
  }, [filters]);

  useEffect(() => {
    if (lastUpdatedTime) {
      reloadTableData();
    }
  }, [lastUpdatedTime]);

  const handleRemoveFilter = (item: TFilterChipData): void => {
    const { field, value } = item;
    if (handleRemoveFilterItem) {
      handleRemoveFilterItem({ field, value });
    }
  };

  const forwardConfig = {
    filters: filterChips,
    totalItems: table.items?.total,
    quickActions: (<></>), // For forwards date picker later
  };

  const spotConfig = {
    filters: filterChips,
    totalItems: table.items?.total,
  };

  const searchConfig = isForwardTable ? forwardConfig : spotConfig;

  const cta = (
    <Box
      display="flex"
      flexDirection="row"
      gridGap="10px"
      flexWrap="wrap"
      alignItems="center"
    >
      <div className={classes.filterMenu}>{filterButton}</div>
    </Box>
  );
  const data = generateTableData();
  return (
    <div data-testid="trades-loader-component" className={classes.tableWrapper}>
      <div style={{
        display: 'flex', flexGrow: '1', alignItems: 'start', gap: '10px',
      }}
      >
        <Search
          testId="search-trades"
          value={table.searchText}
          placeholder={t('search_by_trade_id_or_reference')}
          onChange={handleInputChange}
          handleRemoveFilter={handleRemoveFilter}
          style={i18n.language === 'es' ? { width: '370px' } : undefined}
          {...searchConfig}
          loading={table.loading}
        />
        {cta}
      </div>

      <SearchTable
        emptyTable={{
          title: emptyTitle || t('no_fx_trades'),
          subtitle: emptySubtitle || t('you_currently_do_not_have_any_fx_trades'),
          icon: clipboardCross,
        }}
        table={{
          columns,
          data,
          activeColumn: table.sortBy,
          sortOrder: table.sortOrder,
          dataTestId: testId || '',
          keyColumn: 'id',
          totalRowNumber: table.items?.total || 0,
          handleTableSortClick,
          skip: table.skip,
        }}
        loading={table.loading}
        pagination={{
          handleNext: () => table.handleNextPage(searchParams),
          handlePrevious: () => table.handlePreviousPage(searchParams),
          hasNext: Boolean(table.items?.hasNext),
          hasPrevious: Boolean(table.items?.hasPrevious),
        }}
      />

    </div>
  );
};

export default FxTradeTable;
