import React from 'react';
import useLog from 'hooks/useLog';
import i18n from 'i18n/config';
import { Redirect, Route } from 'react-router-dom';
import AuthService from 'services/Auth/auth.service';

import routes from '../../../../routes.path';
import { IAuthProps } from '../..';
import NewPassword from '../../NewPassword';
import SetupMFA from '../../SetupMFA';
import VerifyMfa from '../../VerifyMfa';
import Welcome from '../../Welcome/Welcome';

interface IPostOnboardingLogin extends IAuthProps {
  mfaImage?: 'authy' | 'sms',
  setMfaImage: React.Dispatch<React.SetStateAction<'authy' | 'sms' | undefined>>,
  onboarded?: boolean,
}

const PostOnboardingLogin: React.FC<IPostOnboardingLogin> = (
  {
    userObject, userInfo, mfaImage, setMfaImage, onboarded,
  }: IPostOnboardingLogin,
) => {
  const { logError } = useLog();

  if (!userObject && !onboarded) return <Redirect to={routes.auth.onboarding.login} />;
  if (onboarded && !userObject) return <Redirect exact path={routes.auth.onboarding.setupMfa} />;

  const updateLangProfile = async () => {
    try {
      if (i18n?.language) {
        await AuthService.updateLangPreference(i18n.language);
      }
    } catch (e) {
      logError({ action: 'Error updating language preference to ', detail: { language: i18n?.language }, error: e });
    }
  };
  updateLangProfile();

  return (
    <>
      <Route exact path={routes.auth.onboarding.newPassword}>
        <NewPassword onboarding userObject={userObject} onboarded={onboarded} />
      </Route>
      <Route exact path={routes.auth.onboarding.setupMfa}>
        <SetupMFA userObject={userObject} onboarding setup setMfaImage={setMfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.mfaOptions}>
        <SetupMFA userObject={userObject} onboarding setMfaImage={setMfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.verifyMfa}>
        <VerifyMfa userObject={userObject} userInfo={userInfo} onboarding={onboarded} mfaImage={mfaImage} />
      </Route>
      <Route exact path={routes.auth.onboarding.welcome}>
        <Welcome name={userInfo?.Name || ''} />
      </Route>
    </>
  );
};

export default PostOnboardingLogin;
