import { useState } from 'react';
import environmentVars from 'env.variables';
import { useFormik } from 'formik';
import useLog from 'hooks/useLog';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { TSignIn } from 'models/auth';
import { useDispatch } from 'react-redux';
import AuthService from 'services/Auth/auth.service';
import history from 'services/history/browserHistory';

import { MfaMethod } from '@alpha/profile-dtos';
import { originalUseSnackbar } from '@alpha/ui-lib/ui/Snackbar';

import routes from '../routes.path';
import { actions } from '../store/user/user.reducer';

const initialValue = {
  mfa: '',
};

const useCustomMultifactorAuth = (
  newUserObject: TSignIn,
  onboarding?: boolean,
  mfaMethod?: 'sms' | 'authy',
  fromAlphaConnect = false,
) => {
  const snackbar = originalUseSnackbar();
  const { setUserAccount } = useSwitchAccount();
  const dispatch = useDispatch();
  const { logError } = useLog();
  const [loading, setLoading] = useState<boolean>(false);

  const getPreferredMethod = (method: 'sms' | 'authy') => {
    switch (method) {
      case 'sms':
        return MfaMethod.METHOD_SMS;
      case 'authy':
        return MfaMethod.METHOD_AUTHY;
      default:
        return MfaMethod.METHOD_AUTHY;
    }
  };

  const handleMfa = async (userObject: TSignIn, code: string) => {
    try {
      setLoading(true);
      await AuthService.sendCustomChallengeAnswer(userObject, code);
      const response = await AuthService.currentUserInfo();
      await AuthService.saveSessionIdToLocalStorage();
      if (response) dispatch(actions.updateUserDetails(response));
      if (fromAlphaConnect) {
        window.location.href = environmentVars.ALPHA_CONNECT_URL_HOME;
      } else {
        history.push(routes.auth.welcome);
      }
    } catch (e: any) {
      const snackbarMessage = e?.message === 'Invalid session for the user.'
        ? 'Your session has expired, please sign in again'
        : 'There was an error confirming your token, please try again.';

      snackbar.enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        preventDuplicate: true,
      });
      logError({ action: snackbarMessage, error: e });
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      try {
        await handleMfa(newUserObject, values.mfa);
        if (onboarding && mfaMethod) {
          // Important to load account ID before hitting API
          await setUserAccount();
          const method = getPreferredMethod(mfaMethod);
          await AuthService.updateMfaMethod(method);
          dispatch(actions.updatePreferredMfaMethod(method));
        }
      } catch (e) {
        logError({ action: e.message || 'Error updating user mfa method', error: e });
        snackbar.enqueueSnackbar(e.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
    loading,
  };
};

export default useCustomMultifactorAuth;
