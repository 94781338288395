import React, { useEffect, useState } from 'react';
import CreateDrawdownDrawer from 'components/Molecules/Drawdowns/CreateDrawdownDrawer';
import CreateQuickDrawdownDrawer from 'components/Molecules/Drawdowns/CreateQuickDrawdownDrawer';
import FirstPartyCreateDrawdownDrawer from 'components/Molecules/Drawdowns/FirstPartyCreateDrawdownDrawer';
import RequestDrawdownModal from 'components/Molecules/Drawdowns/RequestDrawdownModal';
import CreatePaymentDrawer from 'components/Molecules/Payments/CreatePaymentDrawer';
import DeleteDraftPaymentModal from 'components/Molecules/Payments/DeleteDraftPaymentModal';
import CancelTradeModal from 'components/Molecules/Trade/CancelDraftTradeModal';
import CreateSpotDrawer from 'components/Molecules/Trade/CreateSpotDrawer/index';
import useActionDropdown from 'hooks/useActionDropDown';
import useAuthorization from 'hooks/useAuthorization';
import useReportsPolling, {
  DownloadType,
  FileType,
  PageType,
} from 'hooks/useReportsPolling';
import useSwitchAccount from 'hooks/useSwitchAccount';
import { UserRole } from 'models/user';
import moment from 'moment';
import { useSelector } from 'react-redux';
import browserHistory from 'services/history/browserHistory';
import { TStore } from 'store';
import { TAuthyStatus } from 'store/authy/reducer';
import isFxMarketClosed from 'utils/fxTrades/isFxMarketClosed';
import { shouldShowApprovalButtons } from 'utils/payments/shouldShowApprovalButtons';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { TradeDto, TradeStatus, TradeType } from '@alpha/fx-dtos';
import { PaymentDto, PaymentStatus } from '@alpha/payments-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { Box, ClickAwayListener, Typography } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import MultiSelectSection from '../../../../../Beneficiaries/Body/BeneficiaryActionDropdown/MultiSelectSection/MultiSelectSection';

import useStyles from './ActionDropDown.styles';

interface IProps {
  payment?: PaymentDto;
  trade?: TradeDto;
  displayMultiSelect: boolean;
  setDisplayMultiSelect: (val: boolean) => void;
  setSelectedPayment?: React.Dispatch<
    React.SetStateAction<PaymentDto | undefined>
  >;
  handleApprovePayments?: (payments: PaymentDto[]) => void;
  handleRejectPayments?: (payments: PaymentDto[]) => void;
  handleReloadPaymentTable?: () => void;
  handleReloadTradesTable?: () => void;
  currentOptions?: Approver[];
  setCurrentOptions?: React.Dispatch<React.SetStateAction<Approver[]>>;
  setTextValue?: React.Dispatch<React.SetStateAction<string>>;
  showCancelTrade?: boolean;
  eMoneyDisabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ActionDropDown: React.FC<IProps> = ({
  displayMultiSelect,
  setDisplayMultiSelect,
  payment,
  trade,
  setSelectedPayment,
  handleReloadPaymentTable,
  handleReloadTradesTable,
  handleApprovePayments,
  handleRejectPayments,
  currentOptions,
  setCurrentOptions,
  setTextValue,
  showCancelTrade = false,
  eMoneyDisabled,
}: IProps) => {
  const classes = useStyles();

  const {
    open,
    loading,
    menuCloseCount,
    setMenuCloseCount,
    displayIcon,
    setOpen,
    handleRenderDropdown,
    approvers,
    submitApproverEmails,
  } = useActionDropdown({
    currentOptions,
  });
  const [requestDrawdown, setRequestDrawdown] = useState<boolean>(false);
  const [createDrawdown, setCreateDrawdown] = useState<boolean>(false);
  const [createFirstPartyDrawdown, setCreateFirstPartyDrawdown] = useState<boolean>(false);
  const [quickDrawdown, setQuickDrawdown] = useState<boolean>(false);
  const [openDeletePayment, setOpenDeletePayment] = useState<boolean>(false);
  const [createPaymentsDrawerOpen, setCreatePaymentsDrawerOpen] = useState<boolean>(false);
  const [createSpotDrawerOpen, setCreateSpotDrawerOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [tradeToCancel, setTradeToCancel] = useState<TradeDto>();
  const { isEMoneyDisabled } = useSwitchAccount();
  const [firstPartyFlow, setFirstPartyFlow] = useState(false);

  const authyStatus = useSelector<TStore, TAuthyStatus | undefined>(
    (store) => store.authy.status,
  );
  const { authorized: canApproveOwn } = useAuthorization([
    [UserRole.PAYMENTS_APPROVER_OWN],
  ]);
  const { authorized: canBookSpot } = useAuthorization([[UserRole.SPOT]]);

  const userId = useSelector<TStore, string | undefined>(
    (store) => store.user.profileDetails?.id,
  );
  const canApproveOthers = useAuthorization([[UserRole.PAYMENTS_APPROVER]]);
  const authorizedPaymentInputter = useAuthorization([
    [UserRole.PAYMENTS_INPUTTER],
  ]);
  const drawdownInputter = useAuthorization([[UserRole.DRAWDOWN_INPUTTER]]);
  const firstPartyDrawdownInputter = useAuthorization([
    [UserRole.FIRST_PARTY_DRAWDOWN_INPUTTER],
  ]);

  useEffect(() => {
    if (authyStatus === 'SUCCESS') {
      setOpenDeletePayment(false);
    }
  }, [authyStatus]);

  const handleClickAway = (): void => {
    if (menuCloseCount === 1) {
      setMenuCloseCount(menuCloseCount + 1);
    }
  };

  const handleClose = (): void => {
    setDisplayMultiSelect(false);
    setMenuCloseCount(0);
  };

  const { executeReportGeneration } = useReportsPolling(PageType.Payments);

  const handleReportGeneration = async (
    type: FileType,
    downloadType: DownloadType,
    isPayment: boolean,
    isTrade: boolean,
  ) => {
    if (isPayment && payment) {
      await executeReportGeneration(
        type,
        payment.id,
        undefined,
        undefined,
        downloadType,
      );
    } else if (isTrade && trade) {
      await executeReportGeneration(
        type,
        trade.id,
        undefined,
        undefined,
        downloadType,
      );
    }
  };

  const handleCancelDraft = () => {
    setTradeToCancel(trade);
    setCancelModalOpen(true);
  };

  const handleCloseModal = () => {
    setCancelModalOpen(false);
    window.location.reload();
  };

  const sameOrAfterAccessDate = (): boolean => {
    if (trade) {
      const today = moment(new Date().toDateString()).format('YYYY-MM-DD');
      const accessDate = moment(trade?.accessDate).format('YYYY-MM-DD');
      return moment(today).isSameOrAfter(accessDate);
    }
    return true;
  };

  const isAfterValueDate = (): boolean => {
    if (trade) {
      const today = moment(new Date().toDateString()).format('YYYY-MM-DD');
      const valueDate = moment(trade?.valueDate).format('YYYY-MM-DD');
      return moment(today).isAfter(valueDate);
    }
    return true;
  };

  const menuItems = [];
  if (payment) {
    const isDraftPayment = [
      PaymentStatus.VALIDATED,
      PaymentStatus.UPLOADED,
    ].includes(payment.status);

    if (payment.status === PaymentStatus.SUBMITTED) {
      if (
        shouldShowApprovalButtons(
          canApproveOthers.authorized,
          canApproveOwn,
          [payment],
          userId!,
          true,
        )
      ) {
        menuItems.push({
          content: (
            <Typography
              data-testid={`approve-payment-info-action-${payment.id}`}
            >
              {t('approve_payment')}
            </Typography>
          ),
          icon: displayIcon('Approve'),
          onClick: () => {
            if (handleApprovePayments) handleApprovePayments([payment]);
          },
          underline: true,
        });
      }
      if (
        shouldShowApprovalButtons(
          canApproveOthers.authorized,
          canApproveOwn,
          [payment],
          userId!,
          false,
        )
      ) {
        menuItems.push({
          content: (
            <Typography
              data-testid={`reject-payment-info-action-${payment.id}`}
            >
              {t('reject_payment')}
            </Typography>
          ),
          icon: displayIcon('Reject'),
          onClick: () => {
            if (handleRejectPayments) handleRejectPayments([payment]);
          },
          underline: true,
        });
      }
      if (
        authorizedPaymentInputter
        && currentOptions
        && setCurrentOptions
        && setTextValue
      ) {
        menuItems.push({
          content: (
            <MultiSelectSection
              setMenuCloseCount={setMenuCloseCount}
              menuCloseCount={menuCloseCount}
              setOpen={setOpen}
              displayMultiSelect={displayMultiSelect}
              loading={loading}
              id={payment.id}
              approvers={approvers}
              currentOptions={currentOptions}
              setCurrentOptions={setCurrentOptions}
              setTextValue={setTextValue}
              setDisplayMultiSelect={setDisplayMultiSelect}
              submitApproverEmails={submitApproverEmails}
              type="Payment"
            />
          ),
          onClick: () => handleRenderDropdown(
            displayMultiSelect,
            payment.id,
            'Payment',
            setDisplayMultiSelect,
          ),
          disableClick: true,
          icon: !displayMultiSelect ? faEnvelope : undefined,
          disableHoverEffect: displayMultiSelect,
          underline: true,
        });
      }
    }

    menuItems.push({
      content: (
        <Typography data-testid={`view-payment-info-action-${payment.id}`}>
          {t('view_payment_information')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => {
        if (setSelectedPayment) setSelectedPayment(payment);
      },
      underline: true,
    });

    if (!isDraftPayment) {
      menuItems.push({
        content: (
          <Typography data-testid={`download-payment-action-${payment.id}`}>
            {t('download_payment_summary')}
          </Typography>
        ),
        icon: displayIcon('Download'),
        onClick: () => handleReportGeneration(
          FileType.PDF,
          DownloadType.Summary,
          true,
          false,
        ),
        underline: false,
      });
    }

    if (
      (payment.status === PaymentStatus.VALIDATED
        || payment.status === PaymentStatus.UPLOADED)
      && authorizedPaymentInputter
    ) {
      menuItems.push(
        {
          content: (
            <Typography data-testid={`continue-draft-action-${payment.id}`}>
              {t('continue_draft_payment')}
            </Typography>
          ),
          icon: displayIcon('Continue'),
          onClick: () => setCreatePaymentsDrawerOpen(true),
          underline: true,
        },
        {
          content: (
            <Typography data-testid={`delete-draft-action-${payment.id}`}>
              {t('delete_draft_payment')}
            </Typography>
          ),
          icon: displayIcon('Delete'),
          onClick: () => {
            setOpenDeletePayment(true);
          },
        },
      );
    }
  } else if (trade) {
    const isExternalTrade = trade?.origin === 'EXTERNAL';
    const isDraftTrade = [TradeStatus.SUBMITTED, TradeStatus.VERIFIED].includes(
      trade?.status,
    );
    menuItems.push({
      content: (
        <Typography data-testid={`view-trade-info-action-${trade.id}`} className="view-trade-info-action">
          {t('view_trade')}
        </Typography>
      ),
      icon: displayIcon('View'),
      onClick: () => browserHistory.push(`/app/trades/${trade.id}`),
      underline: true,
    });
    if (
      trade?.type === TradeType.SPOT
      && isDraftTrade
      && canBookSpot
      && !isExternalTrade
      && !isEMoneyDisabled()
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`book-trade-action-${trade.id}`} className="book-trade-action">
            {t('book_spot_trade')}
          </Typography>
        ),
        icon: displayIcon('Approve'),
        onClick: () => {
          setCreateSpotDrawerOpen(true);
        },
        underline: false,
        disabled: isFxMarketClosed(),
      });
    }
    if (
      showCancelTrade
      && !trade.hasLinkedPayments
      && trade?.type === TradeType.SPOT
      && isDraftTrade
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`cancel-trade-action-${trade.id}`} className="cancel-trade-action">
            {t('cancel_spot_trade')}
          </Typography>
        ),
        icon: displayIcon('Reject'),
        onClick: () => handleCancelDraft(),
        underline: !isDraftTrade,
        disabled: isFxMarketClosed(),
      });
    }
    if (
      trade?.drawdownAvailable
      && drawdownInputter.authorized
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`new-drawdown-action-${trade.id}`} className="new-drawdown-action">
            {t('new_drawdown')}
          </Typography>
        ),
        icon: displayIcon('Drawdown'),
        onClick: () => setCreateDrawdown(true),
        underline: !isDraftTrade,
        disabled: !trade?.drawdownAvailable,
      });
    } else if (
      trade.type === TradeType.FORWARD_VARIABLE
      && trade.status === TradeStatus.ACTIVE
      && !isAfterValueDate()
      && drawdownInputter
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`request-drawdown-action-${trade.id}`} className="request-drawdown-action">
            {t('request_new_drawdown')}
          </Typography>
        ),
        icon: displayIcon('Request'),
        onClick: () => setRequestDrawdown(true),
        underline: !isDraftTrade,
      });
    }
    if (
      (trade?.drawdownAvailable
        && firstPartyDrawdownInputter.authorized)
      || (isEMoneyDisabled() && trade?.drawdownAvailable)
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`new-drawdown-action-${trade.id}`} className="new-drawdown-action">
            {t('new_first_party_drawdown')}
          </Typography>
        ),
        icon: displayIcon('Drawdown'),
        onClick: () => setCreateFirstPartyDrawdown(true),
        underline: !isDraftTrade,
        disabled: !trade?.drawdownAvailable,
      });
    }
    if (firstPartyDrawdownInputter.authorized
      && trade?.drawdownAvailable
      && trade?.defaultSSI !== ''
      && !isEMoneyDisabled()
    ) {
      menuItems.push({
        content: (
          <Typography data-testid={`new-drawdown-action-${trade.id}`} className="new-drawdown-action">
            {t('quick_drawdown')}
          </Typography>
        ),
        icon: displayIcon('Drawdown'),
        onClick: () => setQuickDrawdown(true),
        underline: !isDraftTrade,
        disabled: !trade?.drawdownAvailable,
      });
    }
    if (!isDraftTrade && !isExternalTrade) {
      menuItems.push({
        content: (
          <Typography data-testid={`download-trade-action-${trade.id}`} className="download-trade-action">
            {t('download_contract_note')}
          </Typography>
        ),
        icon: displayIcon('Download'),
        onClick: () => handleReportGeneration(
          FileType.PDF,
          DownloadType.ContractNote,
          false,
          true,
        ),
      });
    }
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box data-testid="action-dropdown" className={classes.wrapper}>
          <APMenu
            actions
            open={Boolean(open)}
            setOpen={setOpen}
            anchorEl={open}
            disableBackdropClick={menuCloseCount === 1}
            disableHoverEffect={displayMultiSelect}
            onClose={handleClose}
            buttonProps={{ style: { height: 30, width: 30, minWidth: 0 } }}
            buttonTitle=""
            // @ts-ignore
            icon={faEllipsisH}
          >
            {menuItems.map((item) => (
              <MenuDropdownItem setOpen={setOpen}>{item}</MenuDropdownItem>
            ))}
          </APMenu>
        </Box>
      </ClickAwayListener>
      <RequestDrawdownModal
        open={requestDrawdown}
        handleClose={() => setRequestDrawdown(false)}
      />

      <CreateSpotDrawer
        firstPartyFlow={firstPartyFlow}
        open={createSpotDrawerOpen}
        onClose={() => {
          setCreateSpotDrawerOpen(false);
        }}
        tradeDraft={trade}
        heading={firstPartyFlow ? t('first_party_spot') : t('new_spot_trade')}
      />

      {trade && (
        <>
          <CreateDrawdownDrawer
            open={createDrawdown}
            onClose={() => setCreateDrawdown(false)}
            trade={trade}
          />
        </>
      )}
      {trade && (
        <>
          <FirstPartyCreateDrawdownDrawer
            open={createFirstPartyDrawdown}
            onClose={() => setCreateFirstPartyDrawdown(false)}
            trade={trade}
          />
        </>
      )}
      {trade && (
        <>
          <CreateQuickDrawdownDrawer
            open={quickDrawdown}
            onClose={() => setQuickDrawdown(false)}
            trade={trade}
          />
        </>
      )}
      {trade && (
        <CancelTradeModal
          open={cancelModalOpen}
          handleClose={() => {
            handleCloseModal();
          }}
          trade={tradeToCancel || trade}
          handleReloadTradesTable={handleReloadTradesTable}
        />
      )}
      {payment && (
        <CreatePaymentDrawer
          open={createPaymentsDrawerOpen}
          onClose={() => {
            setCreatePaymentsDrawerOpen(false);
          }}
          currencyAccountId={payment.fundingAccountId}
          paymentDraft={payment}
        />
      )}
      {payment && (
        <DeleteDraftPaymentModal
          paymentIds={[payment?.id]}
          open={openDeletePayment}
          handleClose={() => setOpenDeletePayment(false)}
          handleReloadPaymentTable={handleReloadPaymentTable}
        />
      )}
    </>
  );
};

export default ActionDropDown;
