import React, { ChangeEvent, useEffect, useState } from 'react';
import t from 'utils/translationHelper';

import {
  AccountConfigurationFXDto, TradeStatus, TradeType,
} from '@alpha/fx-dtos';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useLog from '../../../../hooks/useLog';
import useSearch from '../../../../hooks/useSearch';
import { ITableColumn } from '../../../../services/DatabaseServices/marker.interface';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import browserHistory from '../../../../services/history/browserHistory';

import PadInfoTooltip from './PadInfoTooltip/PadInfoTooltip';

export type TSearchFilterTypes = {
  buyCurrencies: string[],
  sellCurrencies: string[],
  tradeTypes?: TradeType[],
  tradeStatuses: TradeStatus[],
  tradeStartDate: string | undefined,
  tradeEndDate: string | undefined,
  valueStartDate: string | undefined;
  valueEndDate: string | undefined;
  accessDateStartDate?: string | undefined;
  accessDateEndDate?: string | undefined;
}

type TFxSearchParams = {
  baseUrl: string,
  queryParams: {
    searchtext?: string,
    take: number,
    skip: number,
    sortby?: string,
    sortorder?: 'asc' | 'desc',
    buycurrency?: string,
    soldcurrency?: string,
    type?: string,
    status?: string,
    tradedatefrom?: string,
    tradedateto?: string,
    valuedatefrom?: string,
    valuedateto?: string,
    accessdatefrom?: string,
    accessdateto?: string,
  } & Record<string, string | number>,
  body?: Record<string, unknown>,
}

export const createSearchParams = (
  skip: number,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  searchText = '',
  filters?: TSearchFilterTypes,
): TFxSearchParams => {
  const searchParams: TFxSearchParams = {
    baseUrl: '/fx/trades/search',
    queryParams: {
      skip,
      take: 10,
      sortby: sortBy || '',
      sortorder: sortOrder,
      excludeexternaltrades: 'true',
    },
  };
  if (searchText) {
    searchParams.queryParams.searchtext = searchText;
  }
  if (filters) {
    const selectedTradeStatuses = [...filters?.tradeStatuses];
    if (selectedTradeStatuses.includes(TradeStatus.SUBMITTED)) {
      selectedTradeStatuses.push(TradeStatus.VERIFIED);
    } else if (selectedTradeStatuses.includes(TradeStatus.VERIFIED)) {
      selectedTradeStatuses.push(TradeStatus.SUBMITTED);
    }
    const uniqueTradeStatutses = Array.from(new Set<TradeStatus>(selectedTradeStatuses));

    searchParams.queryParams.buycurrency = filters?.buyCurrencies.join(',');
    searchParams.queryParams.soldcurrency = filters?.sellCurrencies.join(',');
    searchParams.queryParams.type = filters?.tradeTypes?.join(',');
    searchParams.queryParams.status = uniqueTradeStatutses.join(',');
    if (filters?.tradeStartDate) {
      searchParams.queryParams.tradedatefrom = filters?.tradeStartDate;
    }
    if (filters?.tradeEndDate) {
      searchParams.queryParams.tradedateto = filters?.tradeEndDate;
    }
    if (filters?.valueStartDate) {
      searchParams.queryParams.valuedatefrom = filters?.valueStartDate;
    }
    if (filters?.valueEndDate) {
      searchParams.queryParams.valuedateto = filters?.valueEndDate;
    }
    if (filters?.accessDateStartDate) {
      searchParams.queryParams.accessdatefrom = filters?.accessDateStartDate;
    }
    if (filters?.accessDateEndDate) {
      searchParams.queryParams.accessdateto = filters?.accessDateEndDate;
    }
  }
  return searchParams;
};

const useFxTradeTable = (
  filters?: TSearchFilterTypes,
) => {
  const [accountConfiguration, setAccountConfiguration] = useState<AccountConfigurationFXDto>();
  const [columns, setColumns] = useState<ITableColumn[]>([]);
  const sb = useAlphaSnackbar();
  const table = useSearch();
  const { logError } = useLog();

  const searchParams = createSearchParams(
    table.skip,
    'tradeDate',
    'desc',
    table.searchText,
    filters,
  );

  const handleTableSortClick = (column: string) => {
    table.handleTableSortClick(
      searchParams, column,
    );
  };

  const reloadTableData = (): void => {
    table.handleNewSearch(searchParams);
  };

  useEffect(() => {
    if (filters) {
      const uniqueTradeStatutses = Array.from(new Set<TradeStatus>(filters?.tradeStatuses));
      searchParams.queryParams.buycurrency = filters?.buyCurrencies.join(',');
      searchParams.queryParams.soldcurrency = filters?.sellCurrencies.join(',');
      searchParams.queryParams.status = uniqueTradeStatutses.join(',');
      searchParams.queryParams.type = filters?.tradeTypes?.join(',');
      if (filters?.tradeStartDate) {
        searchParams.queryParams.tradedatefrom = filters?.tradeStartDate;
      }
      if (filters?.tradeEndDate) {
        searchParams.queryParams.tradedateto = filters?.tradeEndDate;
      }
      if (filters?.valueStartDate) {
        searchParams.queryParams.valuedatefrom = filters?.valueStartDate;
      }
      if (filters?.valueEndDate) {
        searchParams.queryParams.valuedateto = filters?.valueEndDate;
      }
      if (filters?.accessDateStartDate) {
        searchParams.queryParams.accessdatefrom = filters?.accessDateStartDate;
      }
      if (filters?.accessDateEndDate) {
        searchParams.queryParams.accessdateto = filters?.accessDateEndDate;
      }
    }
    table.handleInitialSearch(searchParams);
  }, [filters]);

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement>) => {
    table.setSearchText(e.target.value);
    if (e.target.value) {
      searchParams.queryParams.skip = 0;
      searchParams.queryParams.searchtext = e.target.value;
    } else {
      delete searchParams.queryParams.searchtext;
    }
    await table.handleNewSearch(searchParams);
  };

  const handleTradeNumberClick = (tradeId: string) => {
    browserHistory.push(`/app/trades/${tradeId}`);
  };

  const getAccountConfiguration = async () => {
    try {
      const result = await FXTradeService.getAccountConfiguration();
      setAccountConfiguration(result);
    } catch (e) {
      sb.trigger(e?.message || t('could_not_load_account_configuration_data'));
      logError({ action: 'Error loading account configuration', error: e });
    }
  };

  useEffect(() => {
    getAccountConfiguration();
  }, []);

  useEffect(() => {
    generateTableColumns();
  }, [accountConfiguration]);

  const generateTableColumns = () => {
    const tableColumns: ITableColumn[] = [
      {
        header: t('trade_number'), accessor: 'contractNumber', sortable: true, width: 170,
      },
      {
        header: t('trade_type'), accessor: 'tradeType', sortable: true, width: 170,
      },
      {
        header: t('trade_date'), accessor: 'tradeDate', sortable: true, width: 170,
      },
      {
        header: t('access_date'), accessor: 'accessDate', sortable: true, width: 170,
      },
      {
        header: t('sold_amount'), accessor: 'soldAmount', sortable: true, align: 'right', width: 170,
      },
      {
        header: t('rate'), accessor: 'rate', sortable: true, align: 'right',
      },
      {
        header: t('buy_amount'), accessor: 'buyAmount', sortable: true, align: 'right', width: 170,
      },
      {
        header: t('remaining_balance'), accessor: 'buyBalance', width: 200, sortable: true, align: 'right',
      },
      {
        header: t('value_date'), accessor: 'valueDate', sortable: true, width: 170,
      },
      {
        header: t('status'), accessor: 'tradeStatus', sortable: true, width: 170,
      },
      { header: t('action'), accessor: 'action' },
    ];

    if (accountConfiguration?.padAllowed) {
      tableColumns.splice(9, 0, {
        header: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {t('pad_eligibilty')}
            {' '}
            <PadInfoTooltip />
          </div>
        ),
        accessor: 'padEligibility',
        width: 130,
      });
    }
    setColumns(tableColumns);
  };

  return {
    table,
    searchParams,
    handleInputChange,
    handleTableSortClick,
    reloadTableData,
    getAccountConfiguration,
    accountConfiguration,
    columns,
    generateTableColumns,
    handleTradeNumberClick,
  };
};

export default useFxTradeTable;
