import axios, { AxiosResponse } from 'axios';
import environmentVars from 'env.variables';
import { CMSContent } from 'models/cms';

export default class HubCMSService {
  public static async getCMSItems(location?: string, language?: string): Promise<CMSContent> {
    const response: AxiosResponse<CMSContent> = await axios.get(`${environmentVars.ALPHA_HUB}/cms/contents?location=${location}&language=${language}`);
    return response.data;
  }
}
