import { Guid } from 'guid-typescript';
import { t } from 'i18next';
import {
  call,
  delay,
  put, select, takeLatest,
} from 'redux-saga/effects';

import { BeneficiaryBatchStatus } from '@alpha/bene-dtos';

import env from '../../env.variables';
import { actions as notificationsActions } from '../notifications/notifications.reducer';
import { apiRequest } from '../requestSaga';
import { TStore } from '..';

import { actions } from './beneficiaries.reducer';

export function* pollBatchStatus(): any {
  try {
    const batchIdFromState = yield select(
      (state: TStore): string | undefined => state.beneficiaries.batchDetails?.batchId,
    );

    yield put(actions.updateBatchDetails([]));

    if (batchIdFromState) {
      let tryCount = 0;
      while (tryCount < 60) {
        const response = yield call(apiRequest, {
          config: {
            method: 'GET',
            url: `${env.REACT_APP_API_URL}bene/beneficiaries/batches/${batchIdFromState}`,
          },
        });
        const { status: batchStatus, error } = response.data;
        const batchDetails = { ...response.data };
        const shouldContinueToPoll = (batchDetails.processedRecords === 0
          && batchDetails.noOfRecords === 0) || (batchDetails.processedRecords < batchDetails.noOfRecords);

        yield delay(3000);
        yield put(actions.updateBatchDetails(batchDetails));
        if (!shouldContinueToPoll) yield delay(3000);
        if (shouldContinueToPoll) {
          tryCount += 1;
          yield delay(1000);
        } else {
          // yield put(actions.clear());
          if (batchStatus === BeneficiaryBatchStatus.INVALID) {
            throw new Error(t(error) || 'The file is invalid, please check again.');
          }
          return;
        }
      }
    }
  } catch (e: any) {
    yield put(
      notificationsActions.enqueueSnackbar({
        variant: 'error',
        key: Guid.create().toString(),
        message: e && e.message ? e.message.toString() : 'An error occurred uploading your file',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      }),
    );
    yield put(actions.clear());
  }
}

export function* beneficariesSaga() {
  yield takeLatest(actions.triggerBatchStatus, pollBatchStatus);
}
