import React, { useState } from 'react';
import clsx from 'clsx';
import i18n from 'i18next';

import { Button } from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useStyles } from './LangSelectorGroup.styles';

const LangSelectorGroup: React.FC = () => {
  const classes = useStyles();
  const supportLangs = ['EN', 'FR', 'ES', 'IT', 'DE'];
  const [loading, setLoading] = useState(false);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang.toLowerCase());
    localStorage.setItem('authLanguagePreference', lang.toLowerCase());
    setLoading(!loading);
    window.location.reload();
  };

  let selector = null;

  const currentLangFormat = i18n.language.toUpperCase().includes('EN-') ? 'EN' : i18n.language.toUpperCase();

  delete supportLangs[supportLangs.indexOf(currentLangFormat)];
  selector = (

    <div style={{ display: 'contents' }}>
      <Button
        hidden
        key={currentLangFormat}
        id={currentLangFormat}
        className={clsx(classes.button, classes.selectedBtn)}
        type="submit"
        onClick={() => setLoading(!loading)}
      >
        <div className={classes.flagCombine}>
          <div className={classes.flag}>
            <Flag code={currentLangFormat === 'EN' ? 'GBP' : currentLangFormat} size="md" />
          </div>
          <span className={classes.flagText}>
            {currentLangFormat}
            <FontAwesomeIcon style={{ marginLeft: '4px', marginBottom: '-1px' }} className="icon" icon={loading ? faChevronLeft : faChevronRight as IconProp} />
          </span>
        </div>
      </Button>

      {loading ? supportLangs.map((lang: string) => (

        <Button
          key={lang}
          id={lang}
          className={classes.button}
          type="submit"
          onClick={() => changeLanguage(lang)}
        >
          <div className={classes.flagCombine}>
            <div className={classes.flag}>
              <Flag code={lang === 'EN' ? 'GBP' : lang} size="md" />
            </div>
            <span className={classes.flagText}>{lang}</span>
          </div>
        </Button>
      )) : null}
    </div>
  );

  return (
    selector
  );
};
export default LangSelectorGroup;
