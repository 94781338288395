import React from 'react';
import t from 'utils/translationHelper';

import { Typography } from '@alpha/ui-lib/ui/Typography';

import useStyles from './index.styles';

interface IProps {
  number?: number,
  title: string,
  description?: string | JSX.Element,
  marginTop?: string;
}
const FormTitle: React.FC<IProps> = ({
  number, title, description, marginTop,
}: IProps) => {
  const classes = useStyles({ marginTop });

  return (
    <div className={classes.container}>
      <div className="wrapper">
        <div className="title">
          <Typography variant="h2">
            {
              number
              && (
                <span className="green">
                  {`${t('step')} ${number} `}
                </span>
              )
            }
            <span className="white">
              {title}
            </span>
          </Typography>
        </div>
        {
          description
          && (
            <div className="description white">
              <Typography variant="subtitle1">
                {description}
              </Typography>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default FormTitle;
