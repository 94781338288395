import React, { Dispatch, SetStateAction } from 'react';

import { TText } from '@alpha/ui-lib/interfaces';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import APFilterMenu from '@alpha/ui-lib/ui/molecules/Menu/FilterMenu';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons';

interface IFilterProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  setOpen: Dispatch<SetStateAction<HTMLElement | null>>;
  disableBackdropClick: boolean;
  header: JSX.Element | JSX.Element[];
  buttonTitle: TText;
  menuClasses: any;
  filterMenuItems: () => any[];
  className?: string;
}

const TableFilter: React.FC<IFilterProps> = ({
  open,
  anchorEl,
  setOpen,
  disableBackdropClick,
  header,
  buttonTitle,
  menuClasses,
  filterMenuItems,
  className,
}) => (
  <APFilterMenu
    open={open}
    anchorEl={anchorEl}
    setOpen={setOpen}
    disableBackdropClick={disableBackdropClick}
    header={header}
    icon={faSlidersH}
    buttonTitle={buttonTitle}
    key="filter-button"
    menuProps={{
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      className: [menuClasses.menuCustom, className].join(' '),
    }}
  >
    {filterMenuItems().map((item) => (
      <MenuDropdownItem
        key={item.key}
        setOpen={setOpen}
      >
        {item}
      </MenuDropdownItem>
    ))}
  </APFilterMenu>
);

export default TableFilter;
