import React from 'react';

import { IATDraftResponse } from '@alpha/iat-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { TabContent } from '@alpha/ui-lib/ui/Tabs';

import { TCurrencyAccount, TCurrencyAccountDrawer, TCurrencyTab } from '../../../models/currencyAccounts';

import useStyles from './AccountInfoDrawer.styles';
import CurrencyAccountInfo from './CurrencyAccountInfo';
import Header from './Header';
import Transfers from './Transfers';
import useAccountInfoDrawer from './useAccountInfoDrawer';

interface IAccountInfoDrawerProps {
  tabIndex?: number,
  selectedAccount: ISelectedAccount | undefined;
  open: boolean;
  selectedDraftIAT?: IATDraftResponse;
  viewOnly?: boolean;
  handleOpenDrawer: (drawer: TCurrencyAccountDrawer) => void
  setSelectedDraftIAT?:
  React.Dispatch<React.SetStateAction<IATDraftResponse | undefined>>;
  eMoneyDisabled?: boolean;
}

export interface ISelectedAccount {
  account: TCurrencyAccount;
  tabs: TCurrencyTab;
}

const AccountInfoDrawer: React.FC<IAccountInfoDrawerProps> = (
  {
    open,
    selectedAccount,
    selectedDraftIAT,
    viewOnly,
    tabIndex,
    setSelectedDraftIAT,
    handleOpenDrawer,
    eMoneyDisabled,
  }: IAccountInfoDrawerProps,
) => {
  const styles = useStyles();

  const {
    selectedTabIndex,
    pendingTransfers,
    IATRejectModalOpen,
    loading,
    handleIATRejectModalClose,
    handleOpenIATDrawer,
    handleOpenIATRejectModal,
    getAllPendingTransfers,
  } = useAccountInfoDrawer(
    open,
    selectedAccount,
    handleOpenDrawer,
    setSelectedDraftIAT,
    tabIndex,
  );

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={() => handleOpenDrawer(undefined)}
        anchor="right"
        closeIdentifier='account-info-drawer'
      >
        <Header
          selectedAccount={selectedAccount}
          viewOnly={viewOnly}
          eMoneyDisabled={eMoneyDisabled}
        />
        {selectedAccount
          && (
            <div className={styles.drawerBody}>
              <TabContent index={0} value={selectedTabIndex}>
                {selectedAccount && <CurrencyAccountInfo selectedAccount={selectedAccount} eMoneyDisabled={eMoneyDisabled} />}
              </TabContent>
              <TabContent index={1} value={selectedTabIndex}>
                <BaseDrawer.Body className={styles.drawerBody}>
                  <Transfers
                    handleOpenIATRejectModal={handleOpenIATRejectModal}
                    handleOpenIATDrawer={handleOpenIATDrawer}
                    pendingTransfers={pendingTransfers}
                    loading={loading}
                  />
                </BaseDrawer.Body>
              </TabContent>
            </div>
          )}
      </BaseDrawer.Drawer>
    </>
  );
};

export default AccountInfoDrawer;
