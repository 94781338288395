import React, { useState } from 'react';
import i18n from 'i18next';
import t from 'utils/translationHelper';

import { AccountDto } from '@alpha/auth-dtos';
import { MfaMethod, User } from '@alpha/profile-dtos';
import {
  Avatar, Box, Button, Typography,
} from '@alpha/ui-lib/ui/external';
import { Flag } from '@alpha/ui-lib/ui/Flag';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { Status } from '@alpha/ui-lib/ui/Status';
import { StyledDivider } from '@alpha/ui-lib/ui/StyledDivider';
import { StyledKeyValueTable } from '@alpha/ui-lib/ui/table';

import authyIcon from '../../../../assets/authyIcon.svg';
import smsIcon from '../../../../assets/smsIcon.png';
import useAccountSettings from '../../../../hooks/useAccountSettings';
import useLog from '../../../../hooks/useLog';
import useProfile from '../../../../hooks/useProfile';
import { UserRole } from '../../../../models/user';
import AuthMethodModal from '../AuthMethodModal/AuthMethodModal';
import LanguagePreferenceModal from '../LanguagePreferenceModal/LanguagePreferenceModal';
import useStyles from '../PersonalSettings.styles';

interface IProps {
  user?: User,
  loading?: boolean,
  selectedAccount?: AccountDto;
}

const PersonalInfo: React.FC<IProps> = ({ user, loading, selectedAccount }: IProps) => {
  const classes = useStyles();

  const { logEvent } = useLog();
  const { hasPermission } = useAccountSettings();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openLangModal, setOpenLanguagePreferenceModal] = useState(false);
  const { userProfile, updateUserProfile } = useProfile();
  const mfaMethod = userProfile?.mfaMethod;

  const languageConversion = {
    en: 'English (EN)',
    fr: 'Français (FR)',
    es: 'Español (ES)',
    it: 'Italiano (IT)',
    de: 'Deutsch (DE)',
    nl: 'Nederlands (NL)',
  };

  const currentLanguage: string = i18n.language.toString();
  const newCurrent: string = languageConversion[currentLanguage as keyof typeof languageConversion];

  const isAdmin = hasPermission(UserRole.USER_PERMISSION_MANAGER, selectedAccount?.id)
    || hasPermission(UserRole.USER_INPUTTER, selectedAccount?.id)
    || hasPermission(UserRole.USER_EMAIL_NOTIFICATION_MANAGER, selectedAccount?.id);

  const getNameAbbreviation = (inputUser?: User): string | undefined => {
    if (!inputUser) return undefined;
    return inputUser.firstName.substring(0, 1).concat(
      inputUser.lastName.substring(0, 1),
    );
  };

  const getMfaMethodIcon = (method?: string): JSX.Element | undefined => {
    switch (method) {
      case MfaMethod.METHOD_AUTHY:
        return <img src={authyIcon} alt="Authy Icon" width="30px" height="30px" />;
      case MfaMethod.METHOD_SMS:
        return <img src={smsIcon} alt="Sms Icon" width="30px" height="30px" />;
      default:
        return <></>;
    }
  };

  const setLabel = (method?: string): string => {
    switch (method) {
      case MfaMethod.METHOD_AUTHY:
        return 'Authy';
      case MfaMethod.METHOD_SMS:
        return 'SMS';
      default:
        return '';
    }
  };

  const data = [
    { key: t('email'), value: user?.email || '-' },
    { key: t('mobile'), value: user?.phoneNumber || '-', boldValue: true },
    {
      key: <div>
        <Typography>{t('authentication_preference')}</Typography>
        <Button
          className={classes.preferencesBtn}
          onClick={() => {
            setOpenAuthModal(true);
            logEvent({ action: 'Open modal - change authentication preference' });
          }}
        >
          {t('change_preference')}

        </Button>
      </div>,
      value: <Box display="flex">
        {getMfaMethodIcon(mfaMethod)}
        {' '}

        <Typography className={classes.labels}>{setLabel(mfaMethod)}</Typography>
      </Box> || '-',
      boldValue: true,
    },
    {
      key: (
        <div>
          <Typography>{t('preferred_language')}</Typography>
          <Button
            className={classes.preferencesBtn}
            onClick={() => {
              setOpenLanguagePreferenceModal(true);
              logEvent({ action: 'Open modal - change authentication preference' });
            }}
          >
            {t('change_preference')}
          </Button>
        </div>),
      value: (
        <div className={classes.flagCombine}>
          <div className={classes.flag}>
            <Flag
              code={(i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)) === 'En' || i18n.language.toUpperCase().includes('EN-') ? 'GBP'
                : (i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1))}
              size="md"
            />
          </div>
          <span className={classes.flagText}>{newCurrent}</span>
        </div>
      ),
      boldValue: true,
    },
  ];

  if (loading) {
    return (<Loader testId="loader" size="50px" style={{ margin: '20%' }} />);
  }

  return (
    <Box display="flex" flexDirection="column" gridGap="10px">
      <Box display="flex" flexDirection="row" gridGap="10px" marginBottom="20px">
        <Avatar variant="circle" className={classes.avatar}>
          {getNameAbbreviation(user)}
        </Avatar>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <div className={classes.userName}>{`${user?.firstName || ''} ${user?.lastName || ''} `}</div>
          {isAdmin && (
            <div>
              <Status variant="success" className={classes.smallStatus}>{t('account_admin')}</Status>
            </div>
          )}
        </Box>
      </Box>
      <StyledDivider />
      <StyledKeyValueTable testId="keyValueTable" data={data} />
      <AuthMethodModal
        handleClose={() => setOpenAuthModal(false)}
        open={openAuthModal}
        mfaMethod={mfaMethod}
        loadProfile={
          () => {
            updateUserProfile();
          }
        }
      />
      <LanguagePreferenceModal
        handleClose={() => setOpenLanguagePreferenceModal(false)}
        open={openLangModal}
      />
    </Box>
  );
};

export default PersonalInfo;
